import { Injectable } from '@angular/core';
import { throttle } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
/**
 * This class handles closing select component's options panel when the user scrolls anywhere on the page,
 * except inside the panel itself (for scrolling options)
 *
 * This service is a singleton, and as such, the event handler doesn't fire for each select box. Had we handled this
 * at the component level, there we would be one event listener per mounted component.
 */
 @Injectable({
    providedIn: 'root',
  })
  export class PopoverScrollFixService {
    private popovers: any[] = [];
  
    constructor() {
      window.addEventListener('scroll', this.handleScroll, true);
    }
  
    public registerPopover(popoverComponent: any) {
      this.popovers.push(popoverComponent);
    }
  
    public unregisterPopover(popoverComponent: any) {
      this.popovers = this.popovers.filter(popover => popover !== popoverComponent);
    }
  
    // This is a lambda to avoid having to bind(this) and store the resulting function for removing the
    // event listener.
    private handleScroll = (event) => {
        this.popovers
        .filter(popover => popover.isOpen())
        .forEach(popover => {
          const popoverRef = document.getElementById(popover._ngbPopoverWindowId)
          const isScrollingInScrollHost = popoverRef ? popoverRef.contains(event.target) || event.target.className.includes('ng-dropdown-panel-items') : false;
          if (isScrollingInScrollHost) return;
          this.unregisterPopover(popover)
          popover.close();
        });
    };
  }