import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable({
  providedIn: 'root'
})
export class ApiSmsLogService {
  api_name = "crm/communication"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {
  }
  // filter(filter={}):Observable<any> {
  //   return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`,filter, this.utils.getAuthHeader())
  // }

  filter(filter = {}): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/sms/filter`, JSON.stringify(filter), this.utils.getAuthHeader());
  }

  getOptions(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/sms/options`, this.utils.getAuthHeader());
  }

  updateOptions(data): Observable<any>{
    return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/sms/options`, data,this.utils.getAuthHeader());
  }

  getPageOption(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/sms/page-options`, this.utils.getAuthHeader());
  }
}