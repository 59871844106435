import { ApiOrganizationsService } from './../../../core/services/crm_api/api-organizations.service';

import { TranslateService } from '@ngx-translate/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ApiTaskService } from './../../../core/services/crm_api/api-task.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ApiNotificationService } from './../../../core/services/crm_api/api-notification.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Pusher from 'pusher-js';
import { UserModel } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { SessionStorageService } from 'src/app/core/services/session.storage.service';
import checkCircleSolid from '@iconify-icons/la/check-circle-solid'
import plusIcon from '@iconify-icons/la/plus-solid'
import moment from 'moment';
import { ApiTicketService } from 'src/app/core/services/crm_api/api-tickets.service';
import { ApiContactsService } from 'src/app/core/services/crm_api/api-contacts.service';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-right-page-sidebar',
  templateUrl: './right-page-sidebar.component.html',
  styleUrls: ['./right-page-sidebar.component.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({width: 0}),
            animate('0.5s ease', style({width:576}))
          ]
        ),
        transition(
          ':leave',
          [
            style({width: 576}),
            animate('0.5s ease', style({width:0}))
          ]
        )
      ]
    )
  ]
})
export class RightPageSidebarComponent implements OnInit, OnDestroy {
  currentTab = 'all';
  currentTabTask="by_me"
  currentPage = 1
  currentTaskPage = 1
  user:UserModel
  organization:any
  showRightSidebar = false;
  logged
  bgColor
  subSection: string = null;
  pusher:Pusher
  showLoadMore=true
  checkCircleIcon = checkCircleSolid
  plusIcon = plusIcon
  notifications=[]
  group_notifications={}
  task_name_input_new=""
  group_notifications_count={}
  tasks=[]
  users=[]
  assigned_type = 'by_me'
  notificationMenuHeaders = [
    {uniqueName: 'all', displayName: 'All'},
    {uniqueName: 'mentioned', displayName: 'Mentioned'},
    {uniqueName: 'task', displayName: 'Task'},
    {uniqueName: 'communication', displayName: 'Communication'},
  ];

  taskMenuHeaders = [
    {uniqueName: 'by_me', displayName: 'Assigned by me'},
    {uniqueName: 'to_me', displayName: 'Assigned to me'}
  ];
  unread_count=0
  task_unread_count = 0
  unread_count_group={}
  taskCount={}
  createTaskSection = false;

  constructor(
    private authService:AuthService,
    private apiNotificationService:ApiNotificationService,
    private sessionStorageService:SessionStorageService,
    public router: Router,
    private utils:UtilsService,
    private translate:TranslateService,
    private apiTaskService:ApiTaskService,
    private apiTicketService:ApiTicketService,
    private apiContactService:ApiContactsService,
    private apiOrganizationService:ApiOrganizationsService,
    private toastrService: ToastrService
  ) {  }


  pipeplines=[]
  stages=[]
  tickets=[]

  ticket_select =""
  ticket_view = "pipeline"
  selectedAssoc = ""
  
  ngOnInit(): void {
    this.pusher =new Pusher("eb26f38d9c6c914e2b74", {
      cluster: 'us2',
      forceTLS: true
    });
    let logged = this.sessionStorageService.getLoggedUser()
    this.logged = logged
    this.users = this.sessionStorageService.get('users').slice().map( x => {
      x.name = `${x.first_name} ${x.last_name}`;
      return x
    });

    this.selectedAssoc = this.translate.instant("No association")

    this.ticket_select = this.translate.instant("Select ticket")

    this.apiTicketService.getPipelineList().subscribe(res=>{
      if(res.status === "OK"){
        this.pipeplines= res.data
      }else{
        // this.utils.showNotify("error", this.translate.instant(res.message))
      }
    })
    
    // console.log(this.users)
    if(logged === undefined){
      this.router.navigateByUrl('/')
    }else{
      this.user = logged.user
      this.logged = logged
      this.organization = logged.organization
      this.bgColor = logged.organization.color_primary

      let name = `notification_${this.user.id}`
      const channel = this.pusher.subscribe(name);
      channel.bind('notify',(data,metadata)=>{
        // if(logged.organization.id===data.organization_id){
        //   this.utils.playAudio('notify')
        //   this.unread_count ++
        //   this.notifications.unshift(data)
        //   this.notify(data)
        // }
        //   channel.bind('notify',(data,metadata)=>{
        if(logged.organization.id===data.organization_id){
          this.notify(data)
          this.utils.playAudio('notify')
          
          // let groups = this.group_notifications['today'] ? this.group_notifications['today'] : []
          // const group_index = groups.find(x=>x==='now')
          // if(group_index === undefined){
          //   groups.unshift('now')
          // }
          // this.group_notifications['today'] = groups
          // this.groups_class['now']='not_read'
          let groupItems=this.group_notifications['today']?this.group_notifications['today']:{}
          groupItems['now'] = (groupItems['now'])?groupItems['now']:[]
          groupItems['now'].unshift(data)
          this.group_notifications['today'] = groupItems
          let group_notifications_count = this.group_notifications_count['today'] ? this.group_notifications_count['today'] : {}
          group_notifications_count['now'] = (group_notifications_count['now'])?group_notifications_count['now']:{}
          group_notifications_count['now'][data.type] = group_notifications_count['now'][data.type] ? group_notifications_count['now'][data.type]: 0
          group_notifications_count['now'][data.type] = group_notifications_count['now'][data.type] + 1
          
          this.updateUnreadCount({
            count:1,
            type:data.type
          })

          this.group_notifications = JSON.parse(JSON.stringify(this.group_notifications))
          this.group_notifications_count = JSON.parse(JSON.stringify(this.group_notifications_count))

          // console.log(this.group_notifications)
          // console.log(this.group_notifications_count)
        }
    //   })
      })

      this.loadNotification(1)
      this.loadTask(1)

    }
  }
  notify_item=null

  loadNotificationMore(page){
    // this.notify_item = 
  }

  selectedPipeline=""
  selectedStage=""
  selectedTicket=""

  selectPipeline(item){
    this.ticket_view='stage'
    this.stages = item.stages
    this.selectedPipeline = item.name
  }
  selectStage(item){
    this.ticket_view ='ticket'
    this.tickets = item.tickets
    this.selectedStage = item.name
  }
  selectTicket(item){
    this.taskModel.parent_id = item.id
    this.taskModel.parent_type = "ticket"
    this.selectedTicket = item.name
  }

  backToTicket(){
    if(this.ticket_view==="stage"){
      this.ticket_view = "pipepline"
      this.selectedStage=""
    }else if(this.ticket_view==="ticket"){
      this.ticket_view = "stage"
      this.selectedTicket=""
    }else{
      this.selectedPipeline = ""
    }
  }

  notify(item) {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
        console.log(`granted`,Notification)
      const n = new Notification(item.message_alert, {
      });
      n.onclick = () => {
        this.handleClick(item);
      }
    } else if (Notification.permission !== "denied") {
      console.log(`denied`,Notification)
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          const n = new Notification(item.message_alert, {
          });
          n.onclick = () => {
            this.handleClick(item);
          }
        }
      });
    }

    console.log('notify item',item) 

    this.toastrService.warning(`${item.message_alert} <br/> <a href="${item.action_link}" target="_blank" class="btn btn-primary">${item.type=='export'?this.translate.instant("Download"):this.translate.instant('ticket ouvert')}</a> `,this.translate.instant("Reminder"),{
      closeButton:true,
      enableHtml:true,
      disableTimeOut:true
    })


  }

  handleClick(item) {
    const host = window.location.protocol + "//" + window.location.host;
    window.open(`${host}${item.action_link}`, '_blank');
  }


  addNewTask(){
    this.createTaskSection = true;
  }

  taskModel:any={}

  saveNewTask(){
    this.taskModel.id=-1
    this.taskModel.is_done=0
    this.taskModel.allow_user = this.logged.user.id + ""
    this.taskModel.due_date = (this.taskModel.due_date)?moment(this.taskModel.due_date).format('YYYY-MM-DD hh:mm:ss'):"";
    this.apiTaskService.addTask(this.taskModel).subscribe(res=>{
      if(res.status === "OK"){
        this.taskModel={}
        this.createTaskSection = false
        this.currentTabTask="by_me";
        this.selectedAssoc = this.translate.instant("No association")
        this.loadTask(1)
      }else{
        this.utils.showNotify("error", this.translate.instant(res.message))
      }
    })
  }

  loadNotification(page){
    if(page===1){
      this.notifications = []
      this.group_notifications = {}
      this.group_notifications_count={}
    }
    this.showLoadMore = false
    this.currentPage = page
    this.apiNotificationService.get(this.currentTab,page).subscribe(res=>{
      if(res.status === "OK"){
        this.unread_count = res.data.unread_count
        this.unread_count_group = res.data.unread_count_group
        if(res.data.notifications.length > 0){
          // if(res.data.notifications.length === 15){
          //   this.showLoadMore = true
          // }else{
          //   this.showLoadMore = false
          // }
          // this.notifications = this.notifications.concat(res.data.notifications)
          this.group_notifications = res.data.group_notifications
          this.group_notifications_count = res.data.group_notifications_count

          
          // console.log(this.group_notifications)
          // console.log(this.group_notifications_count)

        }else{
          this.showLoadMore = false
        }
      }
    })
  }


  readAllNotify(){
    this.apiNotificationService.readAll().subscribe(res=>{
      if(res.status === "OK"){
        this.unread_count = 0
        this.unread_count_group = 0
        this.notifications = []
        this.group_notifications={}
        this.group_notifications_count ={}
        this.loadNotification(1)
      }
    })
  }
  

  updateUnreadCount($event){
    this.unread_count += $event.count
    let type = $event.type
    if($event.type==="ticket"){
      type = "task"
    }

    if(this.unread_count_group[type]===undefined){
      this.unread_count_group[type]=0
    }
    
    this.unread_count_group[type] =this.unread_count_group[type] +  $event.count
  }

  toggleSidebarClass(){
    this.showRightSidebar = !this.showRightSidebar
    if(this.showRightSidebar) {
      document.getElementById("admin-container").classList.add("show_right_side_bar");
    } else {
      this.subSection = null;
      document.getElementById("admin-container").classList.remove("show_right_side_bar");
    }
  }

  openSideExtented(section: any) {
    this.subSection = section;
    document.getElementsByTagName('body')[0].classList.add("extended-right");
  }

  hideExtendedSection() {
    this.subSection = null;
    document.getElementsByTagName('body')[0].classList.remove("extended-right");
  }

  onLogout() {
    this.authService.logout();
    /* this.authService.logout();
    this.sessionStorageService.clear() */
  }
  showLoadMoreTask=false
  loadTask(page){
    if(page===1){
      this.tasks = []
    }
    this.showLoadMoreTask = false
    this.currentTaskPage = page
    this.apiTaskService.filter({
      assigned_type:this.currentTabTask,
      limit:10,
      ticket_pipeline_id:-1,
      start:(page-1)*10,
      sort:"id asc",
      filter:{
        not_is_done:{
          icon: '',
          key:"not_is_done",
          name:"(Not) Is Done",
          type:"checkbox",
          value:1,
          is_checkbox:true
        }
      }
    }).subscribe(res=>{
      if(res.status === "OK"){

        this.taskCount['by_me']=res.data.by_me_count
        this.taskCount['to_me']=res.data.to_me_count
        this.task_unread_count =  res.data.by_me_count + res.data.to_me_count
        if(res.data.data.length > 0){
          if(res.data.data.length === 10){
            this.showLoadMoreTask = true
          }else{
            this.showLoadMoreTask = false
          }
          this.tasks = this.tasks.concat(res.data.data)
        }else{
          this.showLoadMoreTask = false
        }
      }
    })
    
  }

  ngOnDestroy(): void {
    document.getElementsByTagName('body')[0].classList.remove("extended-right");
  }
  showAssociate=false
  selectAssociate(){
    this.showAssociate=true
  }
  
  pickAssoc(type,id,name){
    this.taskModel.parent_type = type
    this.taskModel.parent_id = id
    this.showAssociate=false
    this.selectedAssoc = this.translate.instant(type)+" : "+name
  }
  contacts:any=[]
  organizations:any=[]
  crm_tickets:any=[]
  searchAssociate($event){
    const value = $event.target.value
    this.apiContactService.filter({
      "filter": [
          {
              "id": 0,
              "key": "full_name",
              "data": {},
              "name": "Nom Complet",
              "icon": {
                  "body": "<g fill=\"currentColor\"><path d=\"M3 14s-1 0-1-1s1-4 6-4s6 3 6 4s-1 1-1 1H3zm5-6a3 3 0 1 0 0-6a3 3 0 0 0 0 6z\"/></g>",
                  "width": 16,
                  "height": 16
              },
              "op": "like",
              "value": value,
              "between": "",
              "value_name": value,
              "names": null,
              "type": "text"
          }
      ],
      "query": "",
      "limit": 10,
      "sort": "created_date desc",
      "start": 0,
      "ticket_pipeline_id": -1,
      "assigned_type": "all"
  }).subscribe(res=>{
      if(res.status === "OK"){
        this.contacts = res.data.data
      }
    })
    this.apiOrganizationService.filter({
      "filter": [
          {
              "id": 0,
              "key": "name",
              "data": {},
              "name": "Name",
              "icon": {
                  "body": "<path class=\"clr-i-solid clr-i-solid-path-1\" d=\"M12 16.14h-.87a8.67 8.67 0 0 0-6.43 2.52l-.24.28v8.28h4.08v-4.7l.55-.62l.25-.29a11 11 0 0 1 4.71-2.86A6.59 6.59 0 0 1 12 16.14z\" fill=\"currentColor\"/><path class=\"clr-i-solid clr-i-solid-path-2\" d=\"M31.34 18.63a8.67 8.67 0 0 0-6.43-2.52a10.47 10.47 0 0 0-1.09.06a6.59 6.59 0 0 1-2 2.45a10.91 10.91 0 0 1 5 3l.25.28l.54.62v4.71h3.94v-8.32z\" fill=\"currentColor\"/><path class=\"clr-i-solid clr-i-solid-path-3\" d=\"M11.1 14.19h.31a6.45 6.45 0 0 1 3.11-6.29a4.09 4.09 0 1 0-3.42 6.33z\" fill=\"currentColor\"/><path class=\"clr-i-solid clr-i-solid-path-4\" d=\"M24.43 13.44a6.54 6.54 0 0 1 0 .69a4.09 4.09 0 0 0 .58.05h.19A4.09 4.09 0 1 0 21.47 8a6.53 6.53 0 0 1 2.96 5.44z\" fill=\"currentColor\"/><circle class=\"clr-i-solid clr-i-solid-path-5\" cx=\"17.87\" cy=\"13.45\" r=\"4.47\" fill=\"currentColor\"/><path class=\"clr-i-solid clr-i-solid-path-6\" d=\"M18.11 20.3A9.69 9.69 0 0 0 11 23l-.25.28v6.33a1.57 1.57 0 0 0 1.6 1.54h11.49a1.57 1.57 0 0 0 1.6-1.54V23.3l-.24-.3a9.58 9.58 0 0 0-7.09-2.7z\" fill=\"currentColor\"/>",
                  "width": 36,
                  "height": 36
              },
              "op": "like",
              "value": value,
              "between": "",
              "value_name": value,
              "names": null,
              "type": "text"
          }
      ],
      "query": "",
      "limit": 10,
      "sort": "created_date desc",
      "start": 0,
      "ticket_pipeline_id": -1,
      "assigned_type": "all"
  }).subscribe(res=>{
      if(res.status === "OK"){
        this.organizations = res.data.data
      }
    })
  this.apiTicketService.filter({
      "filter": [
          {
              "id": 0,
              "key": "name",
              "data": {},
              "name": "Nom",
              "icon": {
                  "body": "<path d=\"M180.008 72h-144a20.022 20.022 0 0 0-20 20v112a20.022 20.022 0 0 0 20 20h144a20.022 20.022 0 0 0 20-20V92a20.022 20.022 0 0 0-20-20zm-4 128h-136V96h136zm64-148v124a12 12 0 0 1-24 0V56h-152a12 12 0 0 1 0-24h156a20.022 20.022 0 0 1 20 20z\" fill=\"currentColor\"/>",
                  "width": 256,
                  "height": 256
              },
              "op": "like",
              "value": value,
              "between": "",
              "value_name": value,
              "names": null,
              "type": "text"
          }
      ],
      "query": "",
      "limit": 10,
      "sort": "created_date desc",
      "start": 0,
      "ticket_pipeline_id": 1,
      "archived": "hide",
      "assigned_type": "all"
  }).subscribe(res=>{
      if(res.status === "OK"){
        this.crm_tickets = res.data.data
      }
    })
  }
}
