import { ApiEmailContactService } from 'src/app/core/services/crm_api/api-email-contact.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private apiEmailContactService:ApiEmailContactService
  ) { }

  token

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.token = params.token
      }
    );
  }
  sendUnsubscribe(){
    console.log(this.token)
  }

}
