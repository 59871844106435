<mat-expansion-panel   *ngIf="item!==null"  [ngClass]="{'apply-top-border-radius': applyTopBorderRadius, 
                                 'apply-bottom-border-radius': applyBottomBorderRadius}">
  <mat-expansion-panel-header class="task-item-header">
    <div class="task-item" [ngClass]="{'complete':item.is_done===1}">
      <div class="task-name">
          <a class="task-logo" (click)="$event.stopPropagation();completeTask()" >
            <ic-icon *ngIf="item.is_done===0" [ngClass]="{'icon-disabled': false}" [color]="'#D8D8D8'" width="25px" height="25px" style="cursor: pointer;" [icon]="checkCircleIcon"></ic-icon>
            <ic-icon *ngIf="item.is_done===1" [ngClass]="{'icon-disabled': false}" [color]="'#44ec8c'" width="25px" height="25px" style="cursor: pointer;" [icon]="checkCircleIcon"></ic-icon>
          </a>
          <span class="task-name-content">
            <s ngbTooltip="{{item['name'] || ''}}" container="body" *ngIf="item.is_done" [id]="'task-name-'+item.id" (click)="toggleBrokeText(); $event.stopPropagation()">{{ item['name'] || '' }}</s>
            <span ngbTooltip="{{item['name'] || ''}}" container="body" *ngIf="!item.is_done" [id]="'task-name-'+item.id" (click)="toggleBrokeText(); $event.stopPropagation()">{{ item['name'] || '' }}</span>
            <input 
            ngbTooltip="{{item['name'] || ''}}" container="body"
            [id]="'task-name-input-'+item.id"
            (click)="$event.stopPropagation();" 
            (keydown)="$event.stopPropagation()"
            (blur)="toggleBrokeText()"
            type="text" class="task-input-name d-none" [(ngModel)]="item['name']"
            (keyup.enter)="updateTaskName()">
          </span> 
          <!-- (blur)="toggleBrokeText()" -->
      </div>

      <div class="m-r-10 task-due-date">
        <span class="task-date m-r-0" *ngIf="item.due_date" mat-raised-button [ngClass]="getDueDateClass(item.due_date)" (click)="$event.stopPropagation();picker.open()">{{item.due_date | date: 'dd LLL yyyy'}}</span>
        <input [(ngModel)]="item.due_date" (ngModelChange)="updateDueDate()" style="visibility: hidden; width: 5px;" matInput [matDatepicker]="picker">
        <mat-datepicker-toggle *ngIf="!item.due_date || item.due_date===''"  style="color: #8181A5" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
      <!-- <div>
          <span class="task-date">
              {{item.due_date | date: 'dd LLL yyyy'}}
          </span>
          <a class="fas fa-caret-down caret-down" (click)="isExpanded=true" ></a>
      </div> -->
    </div>
  </mat-expansion-panel-header>
  <div class="task-expand-body">
    <div class="parent-info" *ngIf="ticket">
      <div class="name">
        <img src="/assets/img/demo-parent-logo.svg" alt="" srcset="">
        <span>{{ticket?.name}}</span>
      </div>
      <div class="mute-text">
        <span *ngIf="ticket?.allow_user && ticket?.allow_user[0]">{{ticket?.allow_user[0].first_name}} {{ticket?.allow_user[0].last_name}}</span>
      </div>
      <div>
        <!-- <app-menu></app-menu> -->
      </div>
      <div class="parent-progress">
        <ngb-progressbar class="progress-stat-bar" height="2px" [type]="item.is_done===1 ? 'success' : 'warning'" [value]="ticket?(ticket?.completed_task_counter/ticket?.tasks_count)*100:0"></ngb-progressbar>
      </div>
    </div>
    <div class="task-primary-details">
      <div class="count-info">
        <div *ngIf="ticket?.allow_user">
          <span class="assingnee" *ngFor="let us of ticket.allow_user" >
            <span *ngIf="us && us.first_name">{{us.first_name[0].toUpperCase()}}{{us.last_name[0].toUpperCase()}}</span>
          </span>
        </div>
        <div>
          <span *ngIf="ticket?.attachments_count > 0" class="d-flex align-items-center count">
            <i class="text-gray fas fa-paperclip thick-icon inline position-relative fs-18"></i>
            <span>{{ticket?.attachments_count}}</span>
          </span>
          <span class="d-flex align-items-center count">
            <ic-icon class="text-green position-relative" width="15px" height="15px" [icon]="chatsIcon"></ic-icon>
            <span>{{ticket?.communication_count}}</span>
         </span>
        </div>
      </div>
      <div>
        <button mat-button class="see-task" (click)="openTicket()" >
          {{"See full task"|translate}}
          <i class="pl-2 fas fa-angle-right"></i>
        </button>
      </div>
    </div>
    <!-- <div class="action my-2 d-flex align-items-center w-100">
      <div class="mark-done">
        <i class="fas fa-check m-r-5"></i>
        Mark Done
      </div>
      <div class="task-name">
        <a class="task-logo m-r-10" (click)="completeTask()" >
          <ic-icon [ngClass]="{'icon-disabled': false}" [color]="'#D8D8D8'" width="25px" height="25px" style="cursor: pointer;" [icon]="checkCircleIcon"></ic-icon>
        </a>
        <span class="task-name-content">
          <s ngbTooltip="{{item['name'] || ''}}" container="body" *ngIf="item.is_done" [id]="'task-name-'+item.id" (click)="toggleBrokeText(); $event.stopPropagation()">{{ item['name'] || '' }}</s>
          <span ngbTooltip="{{item['name'] || ''}}" container="body" *ngIf="!item.is_done" [id]="'task-name-'+item.id" (click)="toggleBrokeText(); $event.stopPropagation()">{{ item['name'] || '' }}</span>
          <input 
          ngbTooltip="{{item['name'] || ''}}" container="body"
          [id]="'task-name-input-'+item.id"
          (click)="$event.stopPropagation();" 
          (blur)="toggleBrokeText()"
          type="text" class="task-input-name d-none" [(ngModel)]="item['name']"
          (keyup.enter)="updateTaskName()">
        </span> 
      </div>
    </div> -->
    <!-- <div class="update-date d-flex align-items-center w-100">
      <div class="task-name">
        <a class="task-logo m-r-10" (click)="completeTask()" >
          <ic-icon [ngClass]="{'icon-disabled': false}" [color]="'#D8D8D8'" width="25px" height="25px" style="cursor: pointer;" [icon]="checkCircleIcon"></ic-icon>
        </a>
        <span class="task-name-content">
          <s ngbTooltip="{{item['name'] || ''}}" container="body" *ngIf="item.is_done" [id]="'task-name-'+item.id" (click)="toggleBrokeText(); $event.stopPropagation()">{{ item['name'] || '' }}</s>
          <span ngbTooltip="{{item['name'] || ''}}" container="body" *ngIf="!item.is_done" [id]="'task-name-'+item.id" (click)="toggleBrokeText(); $event.stopPropagation()">{{ item['name'] || '' }}</span>
         
        </span> 
      </div>
      <div class="m-r-10">
        <span class="task-date m-r-5">
          {{item.due_date | date: 'dd LLL yyyy'}}
        </span>
        <input [(ngModel)]="item.due_date" (ngModelChange)="updateDueDate()" style="visibility: hidden; width: 5px;" matInput [matDatepicker]="picker">
        <mat-datepicker-toggle style="color: #8181A5" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
      <div class="postpone">
        <i class="m-r-10 fas fa-exclamation-triangle"></i>
        {{"Postpone"|translate}}
      </div>
    </div> -->
  </div>
</mat-expansion-panel>