import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {retry, catchError, finalize} from 'rxjs/operators';
import {AuthService} from "./services/auth.service";
import {SessionStorageService} from "./services/session.storage.service";
import {UtilsService} from "./services/utils.service";
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService:AuthService,
    private utils:UtilsService,
    private sessionStorageService:SessionStorageService,
    private router:Router,
    private translate: TranslateService
  ) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!request.url.includes("last-seen-check") && !request.url.includes("crm") && !request.url.includes("super-admin") && !request.url.includes('check-token')){
      this.utils.showLoading()
   }
  //  console.log('check request')
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          
          // console.log(errorMessage)
          
          let is_show_error=1

          console.log(error)
          if(error.name==="HttpErrorResponse" && error.status===401){
            // console.log('11111')
            try{
              this.authService.logout();
              /* this.authService.logout();
              this.sessionStorageService.clear()
              this.router.navigateByUrl("/") */
            }catch(e){
              this.router.navigateByUrl("/")
            }
            is_show_error=0
          }
          // if(error.error.status==="ERROR" && error.error.message==="Invalid token"){
          //   this.authService.logout();
          //   this.sessionStorageService.clear()
          //   is_show_error=0
          // }

          // // if(error.error.status==="ERROR" && error.error.message==="Token expired"){
          // //   this.authService.logout();
          // //   this.sessionStorageService.clear()
          // // }

          if(is_show_error===1){
            this.utils.showNotify("error", this.translate.instant("Error , Please try again"))
          }

          return throwError(errorMessage);
        }),
        finalize(()=>{
          this.utils.hideLoading()
        })
      )
  }
}
