<div class="header-container">
  <div class="container">
    <div class="home-header header">
      <div class="logo">
        <img src="/assets/img/demo-main-logo.svg"/>
      </div>
      <div class="home-r-menu action">
         <a *ngIf="current_url=='/signin'"   class="btn btn-signup" routerLink="/signin/signup" translate>Créer un compte</a>
        <a *ngIf="current_url=='/signin/signup'" class="btn btn-signup" routerLink="/signin" translate>Se connecter</a>
        <a *ngIf="isAuthenticated" class="btn btn-danger" (click)="logOut()" translate>Logout</a>
        <button class="globe-button" [matMenuTriggerFor]="languageChange">
          <span class="iconify" data-icon="ph:globe"></span>
        </button>
        <mat-menu #languageChange="matMenu">
          <button mat-menu-item class="mat-button-menu-item" (click)="switchLang('en')" >
            <!-- <span class="canada-lo">
              <span class="iconify" data-icon="twemoji:flag-canada"></span>
            </span> -->
            <span class="m-l-10">{{'English' | translate}}</span>
          </button>
          <button mat-menu-item class="mat-button-menu-item" (click)="switchLang('fr')" >
            <!-- <span class="canada-lo">
              <span class="iconify" data-icon="twemoji:flag-french-southern-territories"></span>
            </span> -->
            <span class="m-l-10">{{'French' | translate}}</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
