export class UserModel {
  id:number;
  first_name: string;
  last_name: string;
  email:string;
  password:string;
  language:string;
  topic_arn:string;
  time_zone:string;
  cellphone:string;
  picture_url:string;
  enable_2fa:boolean;
  setting_2fa:any;
  disabled_autofill_ticket:number;
  enable_2nd_verification:number;
  enable_app_verification:number;
  enable_cellphone_verification:number;
}
