import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import checkCircleSolid from '@iconify-icons/la/check-circle-solid'
import { SessionStorageService } from 'src/app/core/services/session.storage.service';

@Component({
  selector: 'app-right-sidebar-menu-header',
  templateUrl: './right-sidebar-menu-header.component.html',
  styleUrls: ['./right-sidebar-menu-header.component.css']
})
export class RightSidebarMenuHeaderComponent implements OnInit {
  @Input() title: string = '';
  @Output() read_all = new EventEmitter()
  bgColor

  checkCircleIcon = checkCircleSolid;
  
  constructor(
    private sessionStorageService:SessionStorageService
  ) { }

  ngOnInit(): void {
    let logged = this.sessionStorageService.getLoggedUser()
    this.bgColor = logged.organization.color_primary
  }
  readAll(){
    this.read_all.emit(true)
  }

}
