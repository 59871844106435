import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiEmailTemplateService {
  api_name = "email_template"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {
  }
  // filter(filter={}):Observable<any> {
  //   return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`,filter, this.utils.getAuthHeader())
  // }

  filter(filter = {}): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`, JSON.stringify(filter), this.utils.getAuthHeader());
  }
  getOptions(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/options`, this.utils.getAuthHeader());
  }
  updateOptions(data): Observable<any>{
    return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/options`, data,this.utils.getAuthHeader());
  }
  getPageOption():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/page-option`, this.utils.getAuthHeader())
  }
  get():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}`, this.utils.getAuthHeader())
  }
  delete(ids): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/${ids}`, this.utils.getAuthHeader())
  }
  getItem(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/${id}`, this.utils.getAuthHeader())
  }
  getItemDial(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/dial/${id}`, this.utils.getAuthHeader())
  }
  add(obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}`,obj, this.utils.getAuthHeader())
  }
  edit(id,obj): Observable<any>{
    return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/${id}`,obj, this.utils.getAuthHeader())
  }
  clone(id,name): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/${id}/clone`,{name}, this.utils.getAuthHeader())
  }

  getDomainAuthentication():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/domain-authentication`, this.utils.getAuthHeader())
  }
  addDomainAuthentication(obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/domain-authentication`,obj, this.utils.getAuthHeader())
  }
  getDomainAuthenticationItem(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/domain-authentication/${id}`, this.utils.getAuthHeader())
  }
  verifyDomain(obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/verify-domain`,obj, this.utils.getAuthHeader())
  }

}



