import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiPublicNewsletterService {
  api_name = "newsletters";

  constructor( 
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {}
    

  getUnsubscribe(token):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/unsubscribe`, this.utils.getTokenHeader(token))
  }

  updateUnsubscribe(token,data):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/unsubscribe`,data, this.utils.getTokenHeader(token))
  }



}



