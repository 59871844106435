<div class="content-wrapper m-t-10" *ngFor="let group of groups;let i=index">
    <ng-container *ngIf="groupItems[group] && groupItems[group].length > 0  " >
        <div class="today-label" *ngIf="['today','yesterday','last_week'].includes(groupName) && i===0">
            <div class="h-line"></div>
            <div class="today" style="text-transform:uppercase" >
                {{groupName.replace("_"," ")|translate}}
            </div>
            <div class="h-line"></div>
        </div>
        <div class="today-label" *ngIf="!['today','yesterday','last_week'].includes(groupName)">
            <div class="h-line"></div>
            <div class="today">
                {{group|translate}}
            </div>
            <div class="h-line"></div>
        </div>
        <ng-container *ngIf="filterNotification(groupItems[group],0).length > 0">

            <div class="summary-content unread" *ngFor="let group_name of getGroupName(groupItems[group],0)" >
                <div class="recent-title-header">
                    <div class="title">
                        <div class="description">
                            <div class="description-title">
                                <a>
                                    <ic-icon *ngIf="group_name.split('**')[0]==='task'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="checkCircleIcon"></ic-icon>
                                    <ic-icon *ngIf="group_name.split('**')[0]==='ticket'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="listIcon"></ic-icon>
                                    <ic-icon *ngIf="group_name.split('**')[0]==='mention'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="commentIcon"></ic-icon>
                                    <span>{{group_name.split('**')[1]}}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="options">
                        <div class="active-icon"   ></div>
                        <div class="time">
                            {{filterNotificationByGroup(groupItems[group],group_name)[0].created_date}}
                        </div>
                        <div class="position-relative">
                            <app-options-dropdown 
                            class="d-block ml-auto position-relative"
                            id="dd"
                            [isVertical] = true
                            [index]="0"
                            (selected)="onOptionSelected($event, group,group_name)"
                            [size]="'small'"
                            [options]="usersOptions"
                            ></app-options-dropdown>
                        </div>
                    </div>
                </div>
                <div class="summary-list">
                    <div class="summary-item unread" *ngFor="let item of filterNotificationByGroup(groupItems[group],group_name)[0]">
                        <div>
                            <span class="actor strong-text">{{item.user_name}}</span>
                            <span class="action gray-text" style="padding-left: 5px;">{{item.message|translate}}</span>
                        </div>
                        <div class="description">
                            <div class="description-title">
                                <a (click)="executeAction(item)">
                                    <ic-icon *ngIf="item.type==='task'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="checkCircleIcon"></ic-icon>
                                    <ic-icon *ngIf="item.type==='ticket'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="listIcon"></ic-icon>
                                    <ic-icon *ngIf="item.type==='mention'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="commentIcon"></ic-icon>
                                    <span [matTooltipPosition]="'above'"
                                        [matTooltip]="item.object_name"
                                        [innerHtml]="item.object_name"
                                        >
                                    </span>
                                </a>
                            </div>
                        </div>
        
                        <div class="time" style="white-space: nowrap;">
                            {{item.created_date}}
                        </div>
                    </div>
                    <div class="summary-item unread" *ngIf="filterNotificationByGroup(groupItems[group],group_name)[1].length > 0">
                        <div>
                            <span class="actor strong-text">{{filterNotificationByGroup(groupItems[group],group_name)[1][0].user_name}}</span>
                            <span class="action gray-text" style="padding-left: 2px;">
                                {{"modified"|translate}} 
                            </span>
                        </div>
                        <div class="description">

                            <div class="description-title">
                                <a (click)="executeAction(filterNotificationByGroup(groupItems[group],group_name)[1][0])">
                                    <span [matTooltipPosition]="'above'"
                                        [matTooltip]="filterNotificationByGroup(groupItems[group],group_name)[1][0].object_name"
                                        [innerHtml]="filterNotificationByGroup(groupItems[group],group_name)[1][0].object_name"
                                        >
                                    </span>
                                </a>
                            </div>
                            <div class="events" *ngFor="let it of filterNotificationByGroup(groupItems[group],group_name)[1]">
                                <div>
                                    <span class="vertical-line"></span>
                                </div>
                                <div class="event" [ngClass]="{'added': it.description.type==='add','edited': it.description.type==='edit','deleted': it.description.type==='delete'}" >
                                    <div>
                                        <span *ngIf="it.description.type==='add'" ><i class="fas fa-plus"></i></span>
                                        <span *ngIf="it.description.type==='edit'" ><i class="fas fa-pencil-alt"></i></span>
                                        <span *ngIf="it.description.type==='delete'" ><i class="fas fa-remove"></i></span>
                                    </div>
                                    <div class="event-content" 
                                        [matTooltipPosition]="'above'"
                                        [matTooltip]="translate.instant(it.description.field.replace('_',' '))+' '+it.description.change">
                                        <span *ngIf="it.description.type==='add'" class="gray-text">{{"Added"|translate}} </span>
                                        <span *ngIf="it.description.type==='edit'" class="gray-text">{{"Edited"|translate}} </span>
                                        <span *ngIf="it.description.type==='delete'" class="gray-text">{{"Deleted"|translate}} </span>
                                        <span style="padding-left: 3px;" class="strong-text">{{it.description.field.replace('_',' ')|translate}} : {{it.description.change}}</span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <!-- <div class="time" style="white-space: nowrap;">
                            {{filterNotificationByGroup(groupItems[group],group_name)[1][0].created_date}}
                        </div> -->
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="filterNotification(groupItems[group],1).length > 0">

            <div class="summary-content" *ngFor="let group_name of getGroupName(groupItems[group],1)" >
                <div class="recent-title-header">
                    <div class="title">
                        <div class="description">
                            <div class="description-title">
                                <a>
                                    <ic-icon *ngIf="group_name.split('**')[0]==='task'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="checkCircleIcon"></ic-icon>
                                    <ic-icon *ngIf="group_name.split('**')[0]==='ticket'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="listIcon"></ic-icon>
                                    <ic-icon *ngIf="group_name.split('**')[0]==='mention'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="commentIcon"></ic-icon>
                                    <span>{{group_name.split('**')[1]}}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="options">
                        <div class="active-icon"   ></div>
                        <div class="time">
                            {{filterNotificationByGroup(groupItems[group],group_name,1)[0].created_date}}
                        </div>
                        <div class="position-relative">
                            <app-options-dropdown 
                            class="d-block ml-auto position-relative"
                            id="dd"
                            [isVertical] = true
                            [index]="0"
                            (selected)="onOptionSelected($event, group,group_name)"
                            [size]="'small'"
                            [options]="usersOptions"
                            ></app-options-dropdown>
                        </div>
                    </div>
                </div>
                <div class="summary-list">

                    <div class="summary-item " *ngFor="let item of filterNotificationByGroup(groupItems[group],group_name,1)[0]">
                        <div>
                            <span class="actor strong-text">{{item.user_name}}</span>
                            <span class="action gray-text" style="padding-left: 5px;">{{item.message|translate}}</span>
                        </div>
                        <div class="description">
                            <div class="description-title">
                                <a (click)="executeAction(item)">
                                    <ic-icon *ngIf="item.type==='task'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="checkCircleIcon"></ic-icon>
                                    <ic-icon *ngIf="item.type==='ticket'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="listIcon"></ic-icon>
                                    <ic-icon *ngIf="item.type==='mention'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="commentIcon"></ic-icon>
                                    <span [matTooltipPosition]="'above'"
                                        [matTooltip]="item.object_name"
                                        [innerHtml]="item.object_name"
                                        >
                                    </span>
                                </a>
                            </div>
                        </div>
        
                        <div class="time" style="white-space: nowrap;">
                            {{item.created_date}}
                        </div>
                    </div>
                    <div class="summary-item " *ngIf="filterNotificationByGroup(groupItems[group],group_name,1)[1].length > 0">
                        <div>
                            <span class="actor strong-text">{{filterNotificationByGroup(groupItems[group],group_name,1)[1][0].user_name}}</span>
                            <span class="action gray-text" style="padding-left: 2px;">
                                {{"modified"|translate}} 
                            </span>
                        </div>
                        <div class="description">

                            <div class="description-title">
                                <a (click)="executeAction(filterNotificationByGroup(groupItems[group],group_name,1)[1][0])">
                                    <span [matTooltipPosition]="'above'"
                                        [matTooltip]="filterNotificationByGroup(groupItems[group],group_name,1)[1][0].object_name"
                                        [innerHtml]="filterNotificationByGroup(groupItems[group],group_name,1)[1][0].object_name"
                                        >
                                    </span>
                                </a>
                            </div>
                            <div class="events" *ngFor="let it of filterNotificationByGroup(groupItems[group],group_name,1)[1]">
                                <div>
                                    <span class="vertical-line"></span>
                                </div>
                                <div class="event" [ngClass]="{'added': it.description.type==='add','edited': it.description.type==='edit','deleted': it.description.type==='delete'}" >
                                    <div>
                                        <span *ngIf="it.description.type==='add'" ><i class="fas fa-plus"></i></span>
                                        <span *ngIf="it.description.type==='edit'" ><i class="fas fa-pencil-alt"></i></span>
                                        <span *ngIf="it.description.type==='delete'" ><i class="fas fa-remove"></i></span>
                                    </div>
                                    <div class="event-content" 
                                        [matTooltipPosition]="'above'"
                                        [matTooltip]="translate.instant(it.description.field.replace('_',' '))+' '+it.description.change">
                                        <span *ngIf="it.description.type==='add'" class="gray-text">{{"Added"|translate}} </span>
                                        <span *ngIf="it.description.type==='edit'" class="gray-text">{{"Edited"|translate}} </span>
                                        <span *ngIf="it.description.type==='delete'" class="gray-text">{{"Deleted"|translate}} </span>
                                        <span style="padding-left: 3px;" class="strong-text">{{it.description.field.replace("_"," ")|translate}} : {{it.description.change}}</span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <!-- <div class="time" style="white-space: nowrap;">
                            {{filterNotificationByGroup(groupItems[group],group_name,1)[1][0].created_date}}
                        </div> -->
                    </div>
                    <!-- <div class="summary-item" *ngFor="let item of filterNotificationByGroup(groupItems[group],group_name,1)">
                        <div>
                            <span class="actor strong-text">{{item.user_name}}</span>
                            <span class="action gray-text" style="padding-left: 5px;" [innerHtml]="item.message"></span>
                        </div>
                        <div class="description">
                            <div class="description-title">
                                <a (click)="executeAction(item)">
                                    <ic-icon *ngIf="item.type==='task'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="checkCircleIcon"></ic-icon>
                                    <ic-icon *ngIf="item.type==='ticket'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="listIcon"></ic-icon>
                                    <ic-icon *ngIf="item.type==='mention'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="commentIcon"></ic-icon>
                                    <span [matTooltipPosition]="'above'"
                                        [matTooltip]="item.object_name"
                                        [innerHtml]="item.object_name"
                                        >
                                    </span>
                                </a>
                            </div>
                        </div>
        
                        <div class="time" style="white-space: nowrap;">
                            {{item.created_date}}
                        </div>
                    </div> -->
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
<!-- <div class="content-wrapper m-t-10" *ngFor="let group of group_reads;let i=index">
    <ng-container *ngIf="groupItemsReads[group] && groupItemsReads[group].length > 0  " >
        <div class="summary-content " *ngIf="filterNotification(groupItemsReads[group],1).length > 0">
            <div class="recent-title-header">
                <div class="title" *ngIf="group_notifications_count_read[group]?.task > 0 || group_notifications_count_read[group]?.ticket > 0">
                    <span class="actor strong-text">{{"You"}} </span> 
                    <span class="action gray-text" style="padding-left: 2px;"> {{"were assigned to"|translate}} {{ group_notifications_count_read[group]?.task > 0 ? group_notifications_count_read[group]?.task+' task':'' }}  {{ group_notifications_count_read[group]?.ticket > 0 ? group_notifications_count_read[group]?.ticket+' ticket':'' }} </span>
                </div>
                <div class="title" *ngIf="group_notifications_count_read[group]?.mention > 0 || group_notifications_count_read[group]?.mention > 0">
                    <span class="actor strong-text">{{"You"}} </span> 
                    <span class="action gray-text" style="padding-left: 2px;"> {{"were assigned to"|translate}} {{ group_notifications_count_read[group]?.mention > 0 ? group_notifications_count_read[group]?.mention+' mention':'' }} </span>
                </div>
                <div class="options">
                    <div class="time">
                        {{groupItemsReads[group][0].created_date}}
                    </div>
                    <div class="position-relative">
                        <app-options-dropdown 
                        class="d-block ml-auto position-relative"
                        id="dd"
                        [isVertical] = true
                        [index]="0"
                        (selected)="onOptionSelected($event, group)"
                        [size]="'small'"
                        [options]="usersOptions"
                        ></app-options-dropdown>
                    </div>
                </div>
            </div>
            <div class="summary-list">
                <div class="summary-item" *ngFor="let item of filterNotification(groupItemsReads[group],1)">
                    <div>
                        <span class="actor strong-text">{{item.user_name}}</span>
                        <span class="action gray-text" style="padding-left: 5px;" [innerHtml]="item.message"></span>
                    </div>
                    <div class="description">
                        <div class="description-title">
                            <a (click)="executeAction(item)">
                                <ic-icon *ngIf="item.type==='task'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="checkCircleIcon"></ic-icon>
                                <ic-icon *ngIf="item.type==='ticket'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="listIcon"></ic-icon>
                                <ic-icon *ngIf="item.type==='mention'" [hidden]="false" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="commentIcon"></ic-icon>
                                <span [matTooltipPosition]="'above'"
                                    [matTooltip]="item.object_name"
                                    [innerHtml]="item.object_name"
                                    >
                                </span>
                            </a>
                        </div>
                    </div>

                    <div class="time" style="white-space: nowrap;">
                        {{item.created_date}}
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div> -->



<!-- <div class="summery-item">
    <div>
        <span class="actor strong-text">Denis Bilteyski</span>
        <span class="action gray-text" style="padding-left: 2px;">
            assigned 
        </span>
    </div>
    <div class="description">
        <div class="description-title">
            <div>
                <ic-icon [hidden]="true" [ngClass]="{'icon-disabled': false}" width="25px" height="25px" style="cursor: pointer;" [icon]="checkCircleIcon"></ic-icon>
                <i [hidden]="false" class="fas fa-clipboard-list" style="font-size: 22px;"></i>
                <span [matTooltipPosition]="'above'"
                    [matTooltip]="'Create a delusion and evade the police'">
                    Create a delusion and evade the police dhfgshgfysd fsy fysdtfdy
                </span>
            </div>
            <div class="time" style="white-space: nowrap;">
                1h ago
            </div>
        </div>
        <div class="events">
            <div>
                <span class="vertical-line"></span>
            </div>
            <div class="event added">
                <div>
                    <span><i class="fas fa-plus"></i></span>
                </div>
                <div class="event-content" 
                    [matTooltipPosition]="'above'"
                    [matTooltip]="'Landline : +1 234 567 8901'">
                    <span class="gray-text">Added </span>
                    <span style="padding-left: 3px;" class="strong-text">Landline : +1 234 567 8901</span>
                </div>
            </div>
        </div>
        <div class="events">
            <div>
                <span class="vertical-line"></span>
            </div>
            <div class="event edited">
                <div>
                    <span><i class="fas fa-pencil-alt"></i></span>
                </div>
                <div class="event-content" 
                    [matTooltipPosition]="'above'"
                    [matTooltip]="'Landline : +1 234 567 8901'">
                    <span class="gray-text">Edited </span>
                    <span style="padding-left: 3px;" class="strong-text">Landline : +1 234 567 8901</span>
                </div>
            </div>
        </div>
        <div class="events">
            <div>
                <span class="vertical-line"></span>
            </div>
            <div class="event assinged">
                <div>
                    <span><i class="fas fa-pencil-alt"></i></span>
                </div>
                <div class="event-content" 
                    [matTooltipPosition]="'above'"
                    [matTooltip]="'Patrick Thauvette'">
                    <span class="gray-text">Assigned :</span>
                    <span style="padding-left: 3px;" class="assingee">Patrick Thauvette</span>
                </div>
            </div>
        </div>
    </div>
</div> -->