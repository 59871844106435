// import { Injectable } from '@angular/core';
// import {SessionStorageModel} from '../models/session.storage.model'
// @Injectable({
//   providedIn: 'root'
// })
// export class SessionStorageService {
//   sessionStorgaeModel:SessionStorageModel=new SessionStorageModel();
//   constructor() { }
//   public set(key:string,value:string){
//     this.sessionStorgaeModel[key]=value;
//   }
//   get(key:string):string{
//     return this.sessionStorgaeModel[key]
//   }
//   remove(key:string){
//     this.sessionStorgaeModel[key]=null;
//   }
//   clear(){
//     this.sessionStorgaeModel=new SessionStorageModel();
//   }
//   getLoggedUser(){
//     return JSON.parse(this.get('logged'));
//   }
//
//
// }

import { Inject,Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {
  }
  public set(key:string,value:any){
    this.storage.set(key,value);
  }
  public get(key:string):any{
    return this.storage.get(key)
  }
  public remove(key:string){
    this.storage.remove(key);
  }
  public clear(){
    // console.log("HERE", this.storage)
    const storage = this.storage as any;

    setTimeout(() => {
      Object.keys(storage.storage).forEach((key) =>{
        if(!key.includes('persistent'))
          this.storage.remove(key)
      })
    }, 10000)
    
    //this.storage.clear();
  }
  public getLoggedUser(){
     return this.get('logged')
  }
}
