import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiSmsListService {
  api_name = "crm/sms_list"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {
  }
  // filter(filter={}):Observable<any> {
  //   return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`,filter, this.utils.getAuthHeader())
  // }

  filter(filter = {}): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`, JSON.stringify(filter), this.utils.getAuthHeader());
  }

  getOptions(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/options`, this.utils.getAuthHeader());
  }

  updateOptions(data): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/options`, data,this.utils.getAuthHeader());
  }
  get(campaign_id=''):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}?campaign_id=${campaign_id}`, this.utils.getAuthHeader())
  }
  delete(ids): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/${ids}`, this.utils.getAuthHeader())
  }
  getItem(id,type='all'):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/${id}?type=${type}`, this.utils.getAuthHeader())
  }
  getItemDetail(ids,type='all'):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/detail/${ids}?type=${type}`, this.utils.getAuthHeader())
  }
  add(obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}`,obj, this.utils.getAuthHeader())
  }
  preImport(obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/import-preview`,obj, this.utils.getAuthHeader())
  }
  edit(id,obj): Observable<any>{
    return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/${id}`,obj, this.utils.getAuthHeader())
  }
  toggleList(id): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/toggle/${id}`, this.utils.getAuthHeader())
  }
}



