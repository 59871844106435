import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/core/services/utils.service';
import bigClose from '@iconify-icons/ci/close-big'
import save from '@iconify-icons/bx/bx-save'
import trash from '@iconify-icons/bx/bxs-trash-alt'
import xCircle from '@iconify-icons/feather/x-circle';

@Component({
  selector: 'app-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.css']
})
export class ShortcutsComponent implements OnInit, OnDestroy {
  bigCloseIcon = bigClose
  saveIcon = save
  trashIcon = trash
  xCircleIcon = xCircle
  constructor(private utils: UtilsService, private translate: TranslateService) { }

  @Input() availableShortCuts
  @Input() mainService

  @Output() close = new EventEmitter();
  @Output() reloadOptions = new EventEmitter();

  @ViewChildren("editContainers") editContainers: QueryList<ElementRef>

  shortcuts = []

  keyCodes = [
    {name: "backspace",	code: 8},
    {name: "tab",	code: 9},
    {name: "enter",	code:	13},
    {name: "shift",	code:	16},
    {name: "ctrl",	code:	17},
    {name: "alt",	code:	18},
    {name: "pause / break",	code:	19},
    {name: "caps lock",	code:	20},
    {name: "escape",	code:	27},
    {name: "page up",	code:	33},
    {name: "space",	code:	32},
    {name: "page down",	code:	34},
    {name: "end",	code:	35},
    {name: "home",	code:	36},
    {name: "&#8592;",	code:	37},
    {name: "&#8593;",	code:	38},
    {name: "&#8594;",	code:	39},
    {name: "&#8595;",	code:	40},
    {name: "print screen",	code:	44},
    {name: "insert",	code:	45},
    {name: "delete",	code:	46},
    {name: "0",	code:	48},
    {name: "1",	code:	49},
    {name: "2",	code:	50},
    {name: "3",	code:	51},
    {name: "4",	code:	52},
    {name: "5",	code:	53},
    {name: "6",	code:	54},
    {name: "7",	code:	55},
    {name: "8",	code:	56},
    {name: "9",	code:	57},
    {name: "a",	code:	65},
    {name: "b",	code:	66},
    {name: "c",	code:	67},
    {name: "d",	code:	68},
    {name: "e",	code:	69},
    {name: "f",	code:	70},
    {name: "g",	code:	71},
    {name: "h",	code:	72},
    {name: "i",	code:	73},
    {name: "j",	code:	74},
    {name: "k",	code:	75},
    {name: "l",	code:	76},
    {name: "m",	code:	77},
    {name: "n",	code:	78},
    {name: "o",	code:	79},
    {name: "p",	code:	80},
    {name: "q",	code:	81},
    {name: "r",	code:	82},
    {name: "s",	code:	83},
    {name: "t",	code:	84},
    {name: "u",	code:	85},
    {name: "v",	code:	86},
    {name: "w",	code:	87},
    {name: "x",	code:	88},
    {name: "y",	code:	89},
    {name: "z",	code:	90},
    {name: "left window key", code:	91},
    {name: "right window key", code:	92},
    {name: "select",	code:	93},
    {name: "numpad 0",	code:	96},
    {name: "numpad 1",	code:	97},
    {name: "numpad 2",	code:	98},
    {name: "numpad 3",	code:	99},
    {name: "numpad 4",	code:	100},
    {name: "numpad 5",	code:	101},
    {name: "numpad 6",	code:	102},
    {name: "numpad 7",	code:	103},
    {name: "numpad 8",	code:	104},
    {name: "numpad 9",	code:	105},
    {name: "*",	code:	106},
    {name: "+",	code:	107},
    {name: "-",	code:	109},
    {name: ".",	code:	110},
    {name: "/",	code:	111},
    {name: "f1",	code:	112},
    {name: "f2",	code:	113},
    {name: "f3",	code:	114},
    {name: "f4",	code:	115},
    {name: "f5",	code:	116},
    {name: "f6",	code:	117},
    {name: "f7",	code:	118},
    {name: "f8",	code:	119},
    {name: "f9",	code:	120},
    {name: "f10",	code:	121},
    {name: "f11",	code:	122},
    {name: "f12",	code:	123},
    {name: "num lock",	code:	144},
    {name: "scroll lock",	code:	145},
    {name: "My Computer",	code:	182},
    {name: "My Calculator",	code:	183},
    {name: ";",	code:	186},
    {name: "=",	code:	187},
    {name: ",",	code:	188},
    {name: "-",	code:	189},
    {name: ".",	code:	190},
    {name: "/",	code:	191},
    {name: "{",	code:	219},
    {name: "\\",	code:	220},
    {name: "}",	code:	221},
    {name: "'",	code:	222}
  ]

  keysPressed = []

  listeningIndex

  keyDownFct
  keyUpFct

  editIndex

  previousKeys

  isModified = false;

  ngOnInit(): void {
    this.getShortcuts();
  }

  ngOnDestroy(): void {
    document.removeEventListener('keyup', this.keyUpFct)
    document.removeEventListener('keydown', this.keyDownFct)
    this.keyUpFct = null
    this.keyDownFct = null
    if(this.isModified)
      this.reloadOptions.emit();
  }

  @HostListener('document:click', ['$event'])
  onClick(event){
    if(this.editContainers){
      const containers = this.editContainers.toArray()
      //If is currently listening to keys
      if(this.editIndex != null && !containers[this.editIndex].nativeElement.contains(event.target) && document.contains(event.target)){
        if(this.previousKeys){
          this.shortcuts[this.listeningIndex].keys = this.previousKeys
          this.previousKeys = null
        }
        this.listeningIndex = null
        this.editIndex = null
      }
    }
   
  }

  getShortcuts(){
    this.mainService.getOptions().subscribe((res) => {
      if(res.status === "OK"){
        res.data.shortcuts ? this.shortcuts = res.data.shortcuts : this.shortcuts = []
        this.addShortcutNames()
        this.addKeyNames()
        console.log(this.shortcuts)
      }
    })
  }

  addShortcutNames(){
    this.shortcuts.forEach((shortcut, i) => {
      this.shortcuts[i] = Object.assign(this.shortcuts[i], {name: this.availableShortCuts.find((short) => short.id == shortcut.shortcutId).name})
    })
  }

  addKeyNames(){
    this.shortcuts.forEach((shortcut, i) => {
      this.shortcuts[i].keys.forEach((key, j) => {
        this.shortcuts[i].keys[j] = {name: this.keyCodes.find((code) => code.code == key).name, code: key}
      })
    })
  }

  //Check if key combinations match between user input and shortcuts
  keysMatch(array1, array2){
    // Check if the arrays are the same length
    if (array1.length !== array2.length) return false;

    // Check if all items exist and are in the same order
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) return false;
    }

    // Otherwise, return true
    return true;
  }

  listenInput(index){
    //Remove old listeners
    if(this.listeningIndex != null){
      document.removeEventListener('keyup', this.keyUpFct)
      document.removeEventListener('keydown', this.keyDownFct)
    }
    this.listeningIndex = index;
    // subscribe
    this.keyUpFct = ((event) => {
      this.handleKeyUp(event, index)
    })
    
    this.keyDownFct = ((event) => {
      if(!this.keysPressed.includes(event.which)){
        this.keysPressed.push(event.which)
      } 
      
    })
    document.addEventListener('keydown', this.keyDownFct)
    document.addEventListener('keyup', this.keyUpFct)
  }

  handleKeyUp(event, index){
    this.shortcuts[index].keys = this.keysPressed.map((key) => {return { name: this.keyCodes.find((item) => item.code == key).name, code: this.keyCodes.find((item) => item.code == key).code }})
    this.keysPressed = []
    this.updateShortcut(index)
    this.clearListeners()
  }

  clearListeners(){
    // unsubscribe from keypress listener
    document.removeEventListener('keyup', this.keyUpFct)
    document.removeEventListener('keydown', this.keyDownFct)
    this.keyUpFct = null
    this.keyDownFct = null
    this.listeningIndex = null;
    this.previousKeys = null
  }

  isKeysUnique(shortcut, index){
    //Check if the same key combination exists in another shortcut
    for(let  i = 0; i < this.shortcuts.length; i++){
      if(i != index && this.keysMatch(shortcut.keys.map((key) => key.code), this.shortcuts[i].keys.map((key) => key.code))){
        return false
      }
    }
    return true
  }

  updateShortcut(index){
    if(!this.isKeysUnique(this.shortcuts[index], index)){
      console.log(this.previousKeys)
      this.utils.showNotify('error', this.translate.instant('The key combination is already in use')+". "+this.translate.instant("Please try another one")+".")
      this.shortcuts[index].keys = this.previousKeys || []
      return
    }
    //If the shortcut id was just assigned
    if(this.shortcuts[index].shortcutId != null && !this.shortcuts[index].name){
      this.shortcuts[index] = Object.assign(this.shortcuts[index], {name: this.availableShortCuts.find((short) => short.id == this.shortcuts[index].shortcutId).name})
    }
    //If it is a new valid shortcut
    if(this.shortcuts[index].id == -1 && this.shortcuts[index].keys.length > 0 && this.shortcuts[index].shortcutId != null){
      const activeShortcuts = this.shortcuts.filter((s) => s.id != -1)
      this.shortcuts[index].id = activeShortcuts.length
    }

    //If the shortcut is valid
    if(this.shortcuts[index].keys.length > 0 && this.shortcuts[index].shortcutId != null){
      this.saveShortcuts()
    }
    
  }

  saveShortcuts(){
    this.mainService.updateOptions({
      shortcuts: this.shortcuts.map((short) => {return {
        id: short.id,
        shortcutId: short.shortcutId,
        keys: short.keys.map((key) => key.code)
      }})
    }).subscribe((res) => {
      if(res.status == "OK"){
        this.utils.showNotify('success', this.translate.instant('Shortcut saved successfully'))
        this.isModified = true
      } else{
        this.utils.showNotify('error', this.translate.instant('An error occurred while saving the shortcut'))
      }
    })
  }

  addShortcutField(id){
    this.shortcuts.push({
      id: -1,
      shortcutId: null,
      keys: [],
      name: ""
    })
    setTimeout(() => {
      this.utils.scrollToBottom(document.getElementById('shortcut-list'))
    })
  }

  editShortcut(index){
    this.editIndex = index
  }

  deleteShortcut(index){
    //Remove old listeners
    if(this.listeningIndex != null){
      document.removeEventListener('keyup', this.keyUpFct)
      document.removeEventListener('keydown', this.keyDownFct)
    }
    if(this.listeningIndex == index){
      this.listeningIndex = null
      this.previousKeys = null
      
    }
    this.editIndex = null
    if(this.shortcuts[index].id != -1){
      const higherIndexShortcuts = this.shortcuts.filter((s) => s.id > this.shortcuts[index].id)
      higherIndexShortcuts.forEach((s) => {
        s.id--
      })
      this.shortcuts.splice(index, 1)
      this.saveShortcuts();
    } else{
      this.shortcuts.splice(index, 1)
    }
    
    
  }

  clearInput(index){
    if(this.previousKeys){
      this.shortcuts[this.listeningIndex].keys = this.previousKeys
    }
    this.previousKeys = this.shortcuts[index].keys
    this.shortcuts[index].keys = []
    this.listenInput(index)
  }

}
