import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiElectedService {
  api_name = "crm/elected";

  constructor( 
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {}

  filter(filter = {}): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`, JSON.stringify(filter), this.utils.getAuthHeader());
  }

  streets = ((query, limit=1000): Observable<any> => {
    return this.httpClient.get<any>(`${environment.api_url}/api/crm/elected/street/searchasyoutype/?search=${encodeURI(query)}&limit=${limit}`, this.utils.getAuthHeader());
  })

  zones(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/zonelist`, this.utils.getAuthHeader());
  }

  getOptions(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/crm/elected/options`, this.utils.getAuthHeader());
  }

  updateOptions(data): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/crm/elected/options`, data,this.utils.getAuthHeader());
  }

  getImport(): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/crm/elected/import`, null,this.utils.getAuthHeader());
  }
  getImport2(): Observable<any>{
    return this.httpClient.get<any>(`https://crmapi.monelu.org/debug/bellefeuille`,this.utils.getAuthHeader());
  }

  getItem(id): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/crm/elected/${id}`, this.utils.getAuthHeader());
  }


}