import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../core/services/auth.service";
import {Router} from "@angular/router";
import {SessionStorageService} from "../../../core/services/session.storage.service";
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-home-header',
  templateUrl: './home.header.html'
})

export class HomeHeaderComponent implements OnInit {
  isAuthenticated = false
  current_url: string
  constructor(private authService: AuthService,private router:Router,private sessionStorageService:SessionStorageService, private utils: UtilsService) {
  }
  ngOnInit() {
    this.authService.authStatusChanged.subscribe(
      (authenticated) => {
        this.isAuthenticated = authenticated;
      }
    );
    this.authService.initAuth();
    setTimeout(()=>{
      let url = this.router["url"].split('?')[0];

      this.current_url = url
    })
  }
  switchLang(lang){
    this.sessionStorageService.set('language',lang)
    window.location.reload()
  }
  logOut(){
    this.authService.logout();
   /*  this.authService.logout();
    this.sessionStorageService.clear() */
  }
}
