import { ApiTicketService } from 'src/app/core/services/crm_api/api-tickets.service';
import { ApiTaskService } from './core/services/crm_api/api-task.service';
import { ApiQuickBookService } from './core/services/api/api-quickbook.service';
import { ApiGeneralService } from './core/services/api/api-general.service';
import { ApiSuperAdminService } from './core/services/api/api-super-admin.service';
import { ApiSmsTemplateService } from './core/services/api/api-sms-template.service';
import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import { FormsModule } from '@angular/forms';
import { HttpClient,HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgbDateParserFormatter, NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './core/services/auth.service';
import { ApiUserService } from "./core/services/api/api-user.service";

import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {CoreModule} from "./core/core.module";
import {SharedModule} from "./shared/shared.module";
import {FeatureModule} from "./feature/feature.module";
import { NgSelect2Module } from 'ng-select2';
import {UtilsService} from "./core/services/utils.service";
import {ApiOrganizationService} from "./core/services/api/api-organization.service";
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';
import {ApiAudioFilesService} from "./core/services/api/api-audio-files.service";
import {ApiCallCampaignService} from "./core/services/api/api-call-campaign.service";
import {ApiListService} from "./core/services/api/api-list.service";
import {ApiContactService} from "./core/services/api/api-contact.service";
import { ToastrModule } from 'ngx-toastr';
import {CallCenterToast} from "./shared/call-center.toast";
import {AuthInterceptor} from "./core/auth.interceptor";
import {ApiCallStatusService} from "./core/services/api/api-call-status.service";
import {ApiOrganizationPhoneService} from "./core/services/api/api-organization-phone.service";
import {ApiScriptService} from "./core/services/api/api-script.service";
import {ApiPlivoService} from "./core/services/api/api-plivo.service";
import {ApiCustomColumnService} from "./core/services/api/api-custom-column.service";
import {ApiTagService} from "./core/services/api/api-tag.service";
import {ApiDialService} from "./core/services/api/api-dial.service";
import {ApiEmailTemplateService} from "./core/services/api/api-email-template.service";
import { EmailEditorModule } from 'angular-email-editor';
import { NgSelectModule } from '@ng-select/ng-select';
import { IntercomModule } from 'ng-intercom';

import { SortablejsModule } from 'ngx-sortablejs';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

import { NgxFileDropModule } from 'ngx-file-drop';

import {ApiContactsService} from "./core/services/crm_api/api-contacts.service";

import { NgxMaskModule, IConfig } from 'ngx-mask'
import {QuillModule} from "ngx-quill";
import {ApiOrganizationEmailSenderService} from "./core/services/api/api-organization-email-sender.service";
import { ApiDemocratikService } from './core/services/integration_api/api-democratik.service';
import { ApiDialMpcService } from './core/services/api/api-dial-mpc.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ApiNotificationService } from './core/services/crm_api/api-notification.service';
import { ApiZoneService } from './core/services/crm_api/api-zone.service';
import { ApiCrmFileService } from './core/services/crm_api/api-file.service';
import { ApiOrganizationsService } from './core/services/crm_api/api-organizations.service';
import { ApiEmailCampaignService } from './core/services/crm_api/api-email-campaign.service';
import { ApiEmailListService } from './core/services/crm_api/api-email-list.service';
import { ApiEmailContactService } from './core/services/crm_api/api-email-contact.service';
import { CustomDatepickerI18n } from './core/datepicker-i18n';
import { NgbDateCustomParserFormatter } from './core/services/datepicker-format';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ApiSmsCampaignService } from './core/services/crm_api/api-sms-campaign.service';
import { ApiSmsListService } from './core/services/crm_api/api-sms-list.service';
import { ApiSmsContactService } from './core/services/crm_api/api-sms-contact.service';
import { ApiSmsLogService } from './core/services/crm_api/api-sms-log.service';
import { ApiTranslateService } from './core/services/api/api-translate.service';
import { ApiStripeService } from './core/services/crm_api/api-stripe.service';
import { ApiPublicNewsletterService } from './core/services/crm_api/api-public-newsletter.service';
import { ApiFormResultService } from './core/services/crm_api/api-form-result.service';
import { ApiCampaignService } from './core/services/api/api-campaign.service';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams) {
      return params.interpolateParams["Default"] || params.key;
    }
    return params.key;
  }
}
@NgModule({
    declarations: [
        AppComponent, CallCenterToast
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        NgxFileDropModule,
        ToastrModule.forRoot({
            preventDuplicates: true
        }),
        FormsModule,
        GooglePlaceModule,
        HttpClientModule,
        AppRoutingModule,
        NgbModule,
        CoreModule,
        SharedModule,
        FeatureModule,
        NgSelect2Module,
        EmailEditorModule,
        QuillModule.forRoot(),
        NgCircleProgressModule.forRoot({
            animationDuration: 300
        }),
        PickerModule,
        SortablejsModule.forRoot({
            animation: 200,
        }),
        NgxMaskModule.forRoot(),
        TranslateModule.forRoot({
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgSelectModule,
        IntercomModule.forRoot({ appId: 'lwjs0ya2',
            updateOnRouterChange: true })
    ],
    providers: [AuthService, ApiUserService, UtilsService, ApiOrganizationService, ApiAudioFilesService, ApiCallCampaignService, ApiListService, ApiContactService,
        {
            provide: MAT_DATE_LOCALE,
            useFactory: (translate: TranslateService) => translate.currentLang,
            deps: [TranslateService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
        ApiCallStatusService, ApiOrganizationPhoneService, ApiScriptService, ApiPlivoService, ApiCustomColumnService,
        ApiTagService, ApiDialService, ApiEmailTemplateService, ApiOrganizationEmailSenderService, ApiSmsTemplateService, ApiDemocratikService, ApiSuperAdminService, ApiGeneralService, ApiDialMpcService, ApiQuickBookService, ApiNotificationService, ApiTaskService, ApiTicketService, ApiZoneService, ApiContactsService, ApiOrganizationsService, ApiEmailCampaignService, ApiEmailListService, ApiEmailContactService, ApiSmsCampaignService, ApiSmsListService, ApiSmsContactService, ApiSmsLogService, ApiTranslateService,ApiStripeService,ApiPublicNewsletterService,ApiFormResultService, ApiCampaignService],
    bootstrap: [AppComponent]
})
export class AppModule { }
