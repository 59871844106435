import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiSmsCampaignService {
  api_name = "crm/sms_campaign"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {
  }
  // filter(filter={}):Observable<any> {
  //   return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`,filter, this.utils.getAuthHeader())
  // }

  filter(filter = {}): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`, JSON.stringify(filter), this.utils.getAuthHeader());
  }

  getOptions(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/options`, this.utils.getAuthHeader());
  }

  updateOptions(data): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/options`, data,this.utils.getAuthHeader());
  }
  get(type="all"):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}?type=${type}`, this.utils.getAuthHeader())
  }
  dialList():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/dial-list`, this.utils.getAuthHeader())
  }
  getItem(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/${id}`, this.utils.getAuthHeader())
  }
  delete(ids): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/${ids}`, this.utils.getAuthHeader())
  }
  clone(id): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/clone/${id}`, this.utils.getAuthHeader())
  }
  
  add(obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}`,obj, this.utils.getAuthHeader())
  }
  edit(id,obj): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/${id}`,obj, this.utils.getAuthHeader())
  }
  updateStatus(id,status): Observable<any>{
    return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/status/${id}`,{status}, this.utils.getAuthHeader())
  }
  inactive(id){
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/inactive/${id}`, this.utils.getAuthHeader())
  }
  active(id){
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/active/${id}`, this.utils.getAuthHeader())
  }
  transfer(id,obj){
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/transfer/${id}`,obj, this.utils.getAuthHeader())
  }
  getCampaignPageOption(){
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/page-option`, this.utils.getAuthHeader())
  }

  saveRecycle(id,obj){
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/recycle/${id}`,obj, this.utils.getAuthHeader())
  }
  getRecycleContact(id,obj){
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/recycle-contact/${id}`,obj, this.utils.getAuthHeader())
  }
  resetHooper(id){
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/reset-hooper/${id}`, this.utils.getAuthHeader())
  }


  getCategories():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/categories`, this.utils.getAuthHeader())
  }
  addCategory(obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/categories`,obj, this.utils.getAuthHeader())
  }
  editCategory(id,obj): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/categories/${id}`,obj, this.utils.getAuthHeader())
  }



  sendSms(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/send-sms/${id}`, this.utils.getAuthHeader())
  }

  addPowerPack(obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/sms-powerpack`,obj, this.utils.getAuthHeader())
  }

  addNumberToPowerPack(powerpack_id,obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/sms-powerpack/add-number`,{
      sms_powerpack_id:powerpack_id,
      phone_item:obj
    }, this.utils.getAuthHeader())
  }

  removeNumberFromPowerPack(powerpack_id,obj,cancelRent): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/sms-powerpack/remove-number`,{
      sms_powerpack_id:powerpack_id,
      phone_item:obj,
      cancelRent
    }, this.utils.getAuthHeader())
  }

  getPowerPack(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/sms-powerpack`, this.utils.getAuthHeader())
  }

  getPowerPackDetails(id): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/sms-powerpack/${id}`, this.utils.getAuthHeader())
  }

  updatePowerPack(id,obj): Observable<any>{
    return this.httpClient.patch<any>(`${environment.api_url}/api/sms-powerpack/${id}`,obj, this.utils.getAuthHeader())
  }
  removePoolFromCampaign(campaign_id){
    return this.httpClient.get<any>(`${environment.api_url}/api/sms-powerpack/remove-pool/${campaign_id}`, this.utils.getAuthHeader())
  }
  unlinkSmsSender(item){
    return this.httpClient.post<any>(`${environment.api_url}/api/sms-powerpack/unlink-sender`,item, this.utils.getAuthHeader())
  }

  getReport(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/report/${id}`, this.utils.getAuthHeader())
  }

  getSmsChat(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/sms-chat/${id}`, this.utils.getAuthHeader())
  }

  getSmsList(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/sms-list/${id}`, this.utils.getAuthHeader())
  }
  

  getSmsChatMessage(campaign_id,table_name,record_id):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/sms-chat/${campaign_id}`,{
      table_name,
      record_id
    }, this.utils.getAuthHeader())
  }
  sendSmsChatMessage(campaign_id,table_name,record_id,message):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/sms-chat-message/${campaign_id}`,{
      table_name,
      record_id,
      message
    }, this.utils.getAuthHeader())
  }

}



