<div class="sidebar-menu">
  <div class="sidebar-logo">
    <img src="assets/img/logo_short.png" style="width:45px;height:43px;" alt="logo">
  </div>
  <!-- <div class="sidebar-credit"  *ngIf="logged.role_id!==3">
    ${{logged.organization.credit}}
    <div class="btn btn-default">
      {{"Credit"|translate}}
    </div>
  </div> -->
  <div class="sidebar-menu-items main-m fancy-scrollbar">

    <ul>
      <li *ngFor="let section of items" [hidden]="!section.shown">
        <a [routerLink]="section.key" [autoClose]="'outside'" container="body" tooltipClass="info-tooltip" [ngbTooltip]="section.name | translate" placement="right">
          <i [ngStyle]="{'color': router.url.includes(section.key) ? bgColor : ''}" [class]="section.icon"> 
            <i *ngIf="section.icon2" [ngStyle]="{'color': router.url.includes(section.key) ? bgColor : ''}" [class]="section.icon2"></i>
          </i>
          <span>{{ section.name | translate }}</span>
        </a>
      </li>
      
    </ul>
    <!-- <ul *ngIf="logged.role_id===3">
      <li><a routerLink="/user/dashboard"><i [ngStyle]="{'color': router.url == '/user/dashboard' ? bgColor : ''}" class="fa fa-home"></i><span>{{"Dashboard"|translate}}</span></a></li>
      <li><a routerLink="/user/dashboard" ><i [ngStyle]="{'color': router.url == '/user/dashboard' ? bgColor : ''}" class="fas fa-phone"></i><span>{{"Campaign"|translate}}</span></a></li>
    </ul> -->
  </div>
  <div class="sidebar-user-profile"  [ngbPopover]="popProfile" container="body" [ngStyle]="{'background':bgColor}" 	triggers="mouseenter:click"   >
    <div class="dropdown" >
      {{user.first_name.charAt(0)}}{{user.last_name.charAt(0)}}
    </div>
  </div>
</div>
<ng-template #popProfile>
  <div class="pop-container" style="width:200px">
    <a *ngIf="hasMultiParti===true || logged.is_agent==1" class="btn " (click)="onChangeParti()">{{"Changer d'organisation"|translate}}</a>
    <a (click)="onLogout()" class="btn" >{{"Logout"|translate}}</a>
  </div>
</ng-template>


<ng-template #viewTechnicalModal let-modal>
  <!-- <p>Technical support appp</p> -->
  <tech-support-modal-body [agentData]="agentData" (closeEditForm)="closeSupportForm()"></tech-support-modal-body>
</ng-template>