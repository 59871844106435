import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";
import {UtilsService} from "../utils.service";
import { Injectable } from '@angular/core';

@Injectable()
export class ApiTaskService {
    api_name = "crm/task";
    url = "3.96.35.194";
    constructor( 
      private httpClient: HttpClient,
      private utils:UtilsService
    ) {}

    filter(filter={}):Observable<any> {
        return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`,filter, this.utils.getAuthHeader())
    }
    
    getOptions(): Observable<any>{
        return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/options`, this.utils.getAuthHeader())
    }
  
    updateOptions(body: any):Observable<any>{
        return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/options`, body,this.utils.getAuthHeader())
    }

    addTask(data: any): Observable<any>{
        return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/`, data, this.utils.getAuthHeader())
    }
    getTask(id: number): Observable<any>{
        return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/:${id}`, this.utils.getAuthHeader())
    }
    updateTask(id: number, data: any): Observable<any>{
        return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/:${id}`, data, this.utils.getAuthHeader())
    }
    removeTask(id: number): Observable<any>{
        return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/:${id}`, this.utils.getAuthHeader())
    }
    moveTask(data: any): Observable<any>{
        return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/move`, data, this.utils.getAuthHeader())
    }
  
}


