import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiContactsService } from 'src/app/core/services/crm_api/api-contacts.service';
import { ApiOrganizationsService } from 'src/app/core/services/crm_api/api-organizations.service';
import { ApiTicketService } from 'src/app/core/services/crm_api/api-tickets.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/core/services/utils.service';
import { parsePhoneNumber } from 'libphonenumber-js';
import { SessionStorageService } from 'src/app/core/services/session.storage.service';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiCommunicationService } from 'src/app/core/services/crm_api/api-communication.service';
import { Location } from '@angular/common';
import { ApiElectorsService } from 'src/app/core/services/crm_api/api-electors.service';
import { ApiCustomColumnService } from 'src/app/core/services/crm_api/api-custom-columns.service';

import user from '@iconify-icons/bi/person-fill'
import phone from '@iconify-icons/fluent/call-24-filled'
import location from '@iconify-icons/fluent/location-28-filled'
import group from '@iconify-icons/clarity/group-solid'
import cardsBold from '@iconify-icons/ph/cards-bold';
import arrowDown from '@iconify-icons/ri/arrow-drop-down-line';
import comment from '@iconify-icons/bx/bxs-comment-detail'
import sms from '@iconify-icons/fa-solid/sms'
import mail from '@iconify-icons/dashicons/email-alt'
import description from '@iconify-icons/fluent/text-description-20-filled'
import listAltSolid from '@iconify-icons/la/list-alt-solid';
import custom from '@iconify-icons/bx/bxs-customize'
import { ApiElectedService } from 'src/app/core/services/crm_api/api-elected.service';


@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css']
})
export class GlobalSearchComponent implements OnInit {
  arrowDownIcon = arrowDown
  phoneIcon = phone
  commentIcon = comment
  mailIcon = mail
  smsIcon = sms
  listAltSolidIcon = listAltSolid
  customIcon = custom

  //Property that's checked whenever all sections are done loading
  finishLoading = false;
  //Loading status of each individual section
  loadingProp = {
    contact: true,
    organization: true,
    elector: true,
    ticket: true,
    communication: true
  }

  contactData  
  organizationData
  electorData
  ticketData

  bgColor

  lastSearch

  communicationData
  ticketAssociations;
  contactAssociations;
  organizationAssociations;
  currentPopover

  customColumns = {
    contact: [],
    organization: [],
    ticket: []
  };

  @Input() search: string;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() showDetails: EventEmitter<any> = new EventEmitter();

  @ViewChild('searchModal', { static: false }) searchModal 
  @ViewChild('globalSearchInput', { static: false }) globalSearchInput

  constructor(
    private router: Router,
    private sessionStorage: SessionStorageService,
    private utils: UtilsService,
    private translate: TranslateService,
    private contactService: ApiContactsService, 
    private ticketService: ApiTicketService, 
    private organizationService:ApiOrganizationsService,
    private communicationService: ApiCommunicationService,
    private electorService: ApiElectedService,
    private location: Location,
    private customColumnService: ApiCustomColumnService) { }

  ngOnInit(): void {
    const logged = this.sessionStorage.getLoggedUser()
    logged.organization.color_primary ? this.bgColor = logged.organization.color_primary : this.bgColor = 'rgb(204, 204, 204)';

    this.init();

    setTimeout(() => {
      //Auto focus search
      this.globalSearchInput.nativeElement.focus()
      fromEvent(this.globalSearchInput.nativeElement, 'keyup').pipe(
        debounceTime(300)
      ).subscribe((event: KeyboardEvent) => {
        if(this.lastSearch != this.search && this.search.length > 2){
          this.lastSearch = this.search
          this.globalSearch();
        }
      })
    }, 0)
  }

  //All filters that will be sent to the different API endpoints
  getFilters(){
    let contactFilter = [
      {
        key: 'full_name',
        name: this.translate.instant('Nom Complet'),
        type: 'text',
        op: 'like',
        value: this.search,
        icon: user,
        value_name: this.search
      },
      'or',
      {
        key: 'phone',
        name: this.translate.instant('Phone'),
        type: 'text',
        op: 'like',
        value: this.search,
        icon: phone,
        value_name: this.search
      },
      'or',
      {
        key: 'cellphone',
        name: this.translate.instant('Cellphone'),
        type: 'text',
        op: 'like',
        value: this.search,
        icon: phone,
        value_name: this.search
      },
      'or',
      {
        key: 'email',
        name: this.translate.instant('Email'),
        type: 'text',
        op: 'like',
        value: this.search,
        icon: phone,
        value_name: this.search
      }
    ]
    contactFilter = this.appendCustomColumns('contact', contactFilter)

    let organizationFilter = [
      {
        key: 'name',
        name: this.translate.instant('Name'),
        type: 'text',
        op: 'like',
        value: this.search,
        icon: group,
        value_name: this.search
      },
      'or',
      {
        key: 'phone',
        name: this.translate.instant('Phone'),
        type: 'text',
        op: 'like',
        value: this.search,
        icon: phone,
        value_name: this.search
      },
      'or',
      {
        key: 'email',
        name: this.translate.instant('Email'),
        type: 'text',
        op: 'like',
        value: this.search,
        icon: phone,
        value_name: this.search
      },
      'or',
      {
        key: 'website',
        name: this.translate.instant('Website'),
        type: 'text',
        op: 'like',
        value: this.search,
        icon: group,
        value_name: this.search
      }
    ]

    organizationFilter = this.appendCustomColumns('organization', organizationFilter)

    let electorFilter = [
      {
        key: 'full_name',
        name: this.translate.instant('Nom Complet'),
        type: 'text',
        op: 'like',
        value: this.search,
        icon: user,
        value_name: this.search
      }
    ]

    let ticketFilter = [
      {
        key: 'name',
        name: this.translate.instant('Name'),
        type: 'text',
        op: 'like',
        value: this.search,
        icon: cardsBold,
        value_name: this.search
      },
      'or',
      {
        key: 'description',
        name: this.translate.instant('Description'),
        type: 'text',
        op: 'like',
        value: this.search,
        icon: description,
        value_name: this.search
      }
    ]

    ticketFilter = this.appendCustomColumns('ticket', ticketFilter)

    let communicationFilter = {
      content: this.search
    }

    return [
      contactFilter,
      organizationFilter,
      electorFilter,
      ticketFilter,
      communicationFilter
    ]
  }

  //Add custom columns to the searches
  appendCustomColumns(type, filter){
    this.customColumns[type].forEach((col) => {
      filter.push('or');
      filter.push({
        key: 'custom_' + col.id,
        name: this.translate.instant(col.name),
        type: col.type,
        op: ['choicelist_multi'].includes(col.type) ? 'or' : '=',
        value: ['choicelist', 'choicelist_multi'].includes(col.type) ? [this.search] : this.search,
        icon: this.customIcon,
        value_name: this.search
      })
    })
    return filter
  }

  init(){
    let requests = []
    requests.push(this.customColumnService.get('crm_contacts', 'created_date desc', '', 0).toPromise())
    requests.push(this.customColumnService.get('crm_organizations', 'created_date desc', '', 0).toPromise())
    requests.push(this.customColumnService.get('crm_tickets', 'created_date desc', '', 0).toPromise())

    Promise.all(requests).then((results) => {
      results.forEach((res, i) => {
        if(res.status == 'OK'){
          if(i == 0)
            this.customColumns.contact = res.data.filter((col) => col.type != 'number' && col.type != 'date' && col.type != 'datetime' && col.type != 'url' && col.type != 'password' && col.type != 'checkbox')
          else if(i == 1)
            this.customColumns.organization = res.data.filter((col) => col.type != 'number' && col.type != 'date' && col.type != 'datetime' && col.type != 'url' && col.type != 'password' && col.type != 'checkbox')
          else if(i == 2)
            this.customColumns.ticket = res.data.filter((col) => col.type != 'number' && col.type != 'date' && col.type != 'datetime' && col.type != 'url' && col.type != 'password' && col.type != 'checkbox')
        }
      })

      this.globalSearch();
    })
  }

  globalSearch(){
    //Reset properties and set loading to true
    this.loadingProp = {
      contact: true,
      organization: true,
      elector: true,
      ticket: true,
      communication: true
    }
    this.finishLoading = false
    this.contactData = null;
    this.organizationData = null;
    this.ticketData = null;
    
    const [contactFilter, organizationFilter, electorFilter, ticketFilter, communicationFilter] = this.getFilters()

    //Contact
    this.contactService.filter({
      filter: contactFilter,
      fields: [
        {field: 'id'}, 
        {field: 'first_name'}, 
        {field: 'last_name'}, 
        {field: 'email'}, 
        {field: 'phone'}, 
        {field: 'cellphone'}, 
        {field: 'organizations'}
      ],
      limit: 10,
      start: 0,
      sort: 'relevance desc'
    }).subscribe((res) => {
      if(res.status == "OK"){
        res.data.data.forEach((data) => {
          if(data['phone']){
            data = this.parsePhoneNumber(data, 'phone')
          } 
          if(data['cellphone']){
            data = this.parsePhoneNumber(data, 'cellphone')
          }
        })
       
        this.contactData = res.data
        this.loadingProp.contact = false;
        this.checkFinishLoading();
      } else{
        this.utils.showNotify('error', this.translate.instant('Error fetching contacts'))
        this.contactData = {
          count: 0,
          data: []
        }
        this.loadingProp.contact = false;
        this.checkFinishLoading();
      }
      this.contactData.collapsed = false
    })

    //Organization
    this.organizationService.filter({
      filter: organizationFilter,
      fields: [
        {field: 'id'}, 
        {field: 'name'}, 
        {field: 'email'}, 
        {field: 'phone'},  
        {field: 'contacts'}
      ],
      limit: 10,
      start: 0,
      sort: 'relevance desc'
    }).subscribe((res) => {
      if(res.status == "OK"){
        res.data.data.forEach((data) => {
          if(data['phone']){
            data = this.parsePhoneNumber(data, 'phone')
          }
          if(data['cellphone']){
            data = this.parsePhoneNumber(data, 'cellphone')
          }
        })
        this.organizationData = res.data
        this.loadingProp.organization = false;
        this.checkFinishLoading();
      } else{
        this.utils.showNotify('error', this.translate.instant('Error fetching organization'))
        this.organizationData = {
          count: 0,
          data: []
        }
        this.loadingProp.organization = false;
        this.checkFinishLoading();
      }
      this.organizationData.collapsed = false
    })

    //Elector
    this.electorService.filter({
      filter: electorFilter,
      fields: [
        {field: 'uniqid'},
        {field: 'full_name'},
        {field: 'zoneid'},
        {field: 'civic'},
        {field: 'parcel'},
        {field: 'street'},
        {field: 'apartment'}
      ],
      limit: 10,
      start: 0,
      sort: 'section asc'
    }).subscribe((res) => {
      if(res.status == "OK"){
        this.electorData = res.data
        this.loadingProp.elector = false;
        this.electorData.data.forEach((elector) => {
          elector = this.assignAddress(elector);
        })
        this.checkFinishLoading();
      } else{
        this.utils.showNotify('error', this.translate.instant('Error fetching electors'))
        this.electorData = {
          count: 0,
          data: []
        }
        this.loadingProp.elector = false;
        this.checkFinishLoading();
      }
      this.electorData.collapsed = false
    })

    //Ticket
    this.ticketService.filter({
      filter: ticketFilter,
      fields: [
        {field: 'id'}, 
        {field: 'name'}, 
        {field: 'description'}, 
        {field: 'labels'}, 
        {field: 'due_date'},
        {field: 'tasks'},
        {field: 'allow_user'},
        {field: 'ticket_pipeline_id'},
        {field: 'ticket_pipeline_stage_id'}
      ],
      limit: 10,
      start: 0,
      sort: 'relevance desc',
      ticket_pipeline_id: 0
    }).subscribe((res) => {
      if(res.status == "OK"){
        // console.log(res.data.data)
        this.ticketData = res.data
        this.loadingProp.ticket = false;
        this.checkFinishLoading();
      } else{
        this.utils.showNotify('error', this.translate.instant('Error fetching ticket'))
        this.ticketData = {
          count: 0,
          data: []
        }
        this.loadingProp.ticket = false;
        this.checkFinishLoading();
      }
      this.ticketData.collapsed = false
    
    })

    //Communication
    this.communicationService.filterCommunications({
      filter: communicationFilter,
      limit: 10,
      start: 0,
      sort: 'relevance desc',
    }).subscribe((res) => {
      if(res.status == "OK"){
        this.communicationData = res.data;
        this.loadingProp.communication = false;
        this.checkFinishLoading();
      } else{
        this.utils.showNotify('error', this.translate.instant('Error fetching communications'))
        this.communicationData = {
          count: 0,
          data: []
        }
        this.loadingProp.communication = false;
        this.checkFinishLoading();
      }
    })
  }

  //Assign the full address name to the contact object
  assignAddress(item){
    let address = ""
    address += item['civic'] ? item['civic'] + ' ' : ''
    address += item['parcel'] ? item['parcel'] + ' ' : ''
    address += item['street'] ? item['street'] + ', ' : ''
    address += item['apartment'] ? 'apt.' + item['apartment'] + ', ' : ''
    
    item = Object.assign(item, {address: address.substring(0, address.length - 2)})
    return item
  }

 /*  initAssociationLists(index){
    console.log(index)
    this.ticketAssociations = this.communicationData.data[index].thread.associations.filter((association) => !association.contact_type)
    this.contactAssociations = this.communicationData.data[index].thread.associations.filter((association) => association.contact_type == 'contact')
    this.organizationAssociations = this.communicationData.data[index].thread.associations.filter((association) => association.contact_type == 'organization')
  }

  onPopoverOpen(p, index){
    if(this.currentPopover && this.currentPopover != p){
      this.currentPopover.close();
    }
    this.currentPopover = p; 
    p.open({index: index});
  }

  checkInPopover(btnClassName, popoverClassName){
    setTimeout(() => {
      if (!$("." + btnClassName + ":hover").length && !$("." + popoverClassName + ":hover").length && this.currentPopover && this.currentPopover.popoverClass.includes(popoverClassName)) {
        this.currentPopover.close();
        this.currentPopover = null;
        this.ticketAssociations = []
        this.contactAssociations = []
        this.organizationAssociations = []
      }
    }, 100)
  } */

  openAssociation(id: number, type: string){
    let key
    if(type == 'contact'){
      key = 'contacts'
    } else if(type == 'organization'){
      key = 'organizations'
    } else if(type == 'ticket'){
      key = 'tickets'
    }
    const url = this.router.serializeUrl(this.router.createUrlTree(['/admin/crm/' + key + '/:' + id]))
    const newTab = window.open(url); 
    if(newTab) {
        newTab.opener = null;
    }
      
   
  }

  checkFinishLoading(){
    this.finishLoading = Object.values(this.loadingProp).filter((i) => i).length == 0
  }

  /* getDueDateClass(ticket){
    if(ticket.is_urgent){
      return 'due-date-container-urgent'
    } else if(ticket.is_late == 1){
      return 'due-date-container-late'
    } else if(ticket.due_date)
      return 'due-date-container-success'
    else
      return 'due-date-container'
  }  */

  onOpenResult(id: number, type: string){
    let key
    if(type == 'contact'){
      key = 'contacts'
    } else if(type == 'organization'){
      key = 'organizations'
    }else if(type == 'elector'){
      key = 'electors'
    } else if(type == 'ticket'){
      key = 'tickets'
    } 
    //If the user is already in the right section
    if(this.location.path().includes(key)){
      this.showDetails.emit(id)
      this.close.emit();
      return
    }
    //If not, go to the right section
    this.router.navigate(['/admin', 'crm', key ,':' + id]).then(() => {
      window.location.reload();
    }) 
  }

  parsePhoneNumber(obj, key){
    if(obj[key] != ''){
      const phone_parts = obj[key].split(';')
      //Main contact phone
      try{
        const phoneNumber = parsePhoneNumber(phone_parts[0] + phone_parts[1])
        phone_parts[2] ? phoneNumber.ext = phone_parts[2] : ''
        obj[key + '_str'] = '+' + (phoneNumber.countryCallingCode || '')  + ' ' + phoneNumber.formatNational()
      }catch(e){
        obj[key + '_str'] = obj[key].replace(/;/g, " ")
      }
    } else{
      obj[key + '_str'] = ''
    }

    return obj
  }

  showAllResults(type:string){
    let key
    const filters = this.getFilters()
    let filter
    if(type == 'contact'){
      key = 'contacts'
      filter = filters[0]
    } else if(type == 'organization'){
      key = 'organizations'
      filter = filters[1]
    } else if(type == 'elector'){
      key = 'electors'
      filter = filters[2]
    } else if(type == 'ticket'){
      key = 'tickets'
      filter = filters[3]
    }
    const url = this.router.serializeUrl(this.router.createUrlTree(['/admin/crm/' + key]))
    this.router.navigate([url], {queryParams: {filter: JSON.stringify(filter)}}).then(() => {
      this.close.emit();
    })
  }

}
