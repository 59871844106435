import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css']
})
export class TabComponent implements OnInit {

  @Input() selectedTab = 'all'
  @Input() tabItems: {uniqueName: string, displayName: string}[] = [];
  @Input() countObject={}
  @Output() selectedTabChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  toggleTab(tab: string): void{
    this.selectedTab = tab;
    this.selectedTabChange.emit(tab);
  }

}
