<div class="container">
    <div class="d-flex align-items-center section">
        <span class="fs-24 font-weight-bold">
            {{ 'Shortcut List' | translate }}
        </span>
        <ic-icon class="inline ml-auto text-gray" style="cursor: pointer;" [icon]="bigCloseIcon" width="24px" height="24px" (click)="close.emit()"></ic-icon>
    </div>
    <div class="body fancy-scrollbar section" id="shortcut-list">
        <div #editContainers class="shortcut-container" *ngFor="let shortcut of shortcuts; let i = index">
            <!-- Regular mode -->
            <div class="d-flex shortcut align-items-center" *ngIf="editIndex != i && shortcut.id != -1">
                <div class="w-50">
                    <label class="text-gray fs-14">{{ 'Shortcut name' | translate }}</label>
                    <span  class="select-value d-block">{{ shortcut.name }}</span>
                </div>
                <div class="ml-auto d-flex" style="flex: 1;">
                    <div style="width: 85%;">
                        <div class="float-right">
                            <div class="d-flex align-items-center">
                                <div class="d-flex align-items-center" *ngFor="let key of shortcut.keys; let i = index">
                                    <div class="key">
                                        <span style="font-size: 13px;" class="font-weight-bold m-auto" [innerHTML]="key.name"></span>
                                    </div>
                                    <div *ngIf="i != shortcut.keys.length - 1" class="m-l-10 m-r-10">
                                        <span class="key-plus">+</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div style="width: 15%" class="d-flex align-items-center">
                        <div class="shortcut-controls ml-auto">
                            <i (click)="editShortcut(i)" class="text-primary fas fa-edit fs-24" style="cursor: pointer"></i>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Edit mode -->
            <div class="d-flex shortcut align-items-center" *ngIf="editIndex == i || shortcut.id == -1">
                <div class="w-50">
                    <label class="text-gray fs-14">{{ 'Shortcut name' | translate }}</label>
                    <ng-select 
                    [clearable]="false"  
                    [closeOnSelect]="true" 
                    [placeholder]="'Click to select a shortcut' | translate"
                    [(ngModel)]="shortcut.shortcutId" 
                    class="form-select shortcut-dropdown"
                    bindLabel="name"
                    bindValue="id"
                    (change)="updateShortcut(i)"
                    [items]="availableShortCuts"
                    [appendTo]="'body'"
                    name="shortcut"
                    >
                    <ng-template ng-label-tmp let-item="item">
                        <span class="select-value">
                            <span>{{item.name}}</span>
                        </span>
                        
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <span class="select-option">{{item.name | translate}}</span>
                    </ng-template>
                    </ng-select>
                </div>

                <div class="ml-auto d-flex" style="flex: 1;">
                    <div style="width: 85%;">
                        <div class="float-right">
                            <div *ngIf="shortcut.keys.length == 0" id="input-reader" [ngClass]="{'listening' : listeningIndex == i}" (click)="listenInput(i)" class="input-reader d-flex align-items-center" style="width: fit-content;">
                                <span [ngClass]="{'listening' : listeningIndex == i}" style="white-space: pre; font-weight:700;" class="text-gray fs-14 input-reader-text">{{ listeningIndex == i ? 'Use keyboard to record the shortcut' : 'Click to record the shortcut' | translate }}</span>
                            </div>
                            <div *ngIf="shortcut.keys.length > 0"  [ngClass]="{'listening' : listeningIndex == i}" class="d-flex align-items-center edit-keys" style="width: fit-content;">
                                <div class="d-flex align-items-center" *ngFor="let key of shortcut.keys; let i = index">
                                    <div class="key">
                                        <span style="font-size: 13px;" class="font-weight-bold m-auto" [innerHTML]="key.name"></span>
                                    </div>
                                    <div *ngIf="i != shortcut.keys.length - 1" class="m-l-10 m-r-10">
                                        <span class="key-plus">+</span>
                                    </div>
                                </div>
                                <div style="cursor: pointer; color: #FF808B" (click)="clearInput(i)" class="m-l-10 d-flex align-items-center">
                                   <ic-icon [icon]="xCircleIcon" width="18px" height="18px" color="#FF808B"></ic-icon>
                                   <span class="fs-12 font-weight-bold">{{ 'Clear' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 15%" class="d-flex align-items-center">
                        <div class="shortcut-controls ml-auto">
                            <ic-icon (click)="deleteShortcut(i)" style="cursor: pointer" [icon]="trashIcon" [color]="'#FF808B'" width="24px" height="24px"></ic-icon>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
    <div class="footer m-t-auto section">
        <div class="w-100 add-btn d-flex" (click)="addShortcutField(shortcuts.length)">
            <span class="inline m-auto text-dark" style="font-size: 30px;font-weight: 800;">+</span>
        </div>
    </div>
</div>
