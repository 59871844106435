import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiTranslateService {
  api_name = "translate"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {
  }
  get():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}`, this.utils.getAuthHeader())
  }
  add(obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}`,obj, this.utils.getAuthHeader())
  }
  addMulti(obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/add-multi`,{obj}, this.utils.getAuthHeader())
  }
  edit(id,obj): Observable<any>{
    return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/${id}`,obj, this.utils.getAuthHeader())
  }
  delete(id): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/${id}`, this.utils.getAuthHeader())
  }
}



