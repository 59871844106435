import { UtilsService } from 'src/app/core/services/utils.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import {ApiPublicNewsletterService} from 'src/app/core/services/crm_api/api-public-newsletter.service'

@Component({
  selector: 'app-newsletter-unsubscribe',
  templateUrl: './newsletter-unsubscribe.component.html',
  styleUrls: ['./newsletter-unsubscribe.component.css']
})
export class NewsletterUnsubscribeComponent implements OnInit {
  isConfirm = false;
  newsletterLists:any[]=[];
  constructor(
    private route: ActivatedRoute,
    private translate:TranslateService,
    private utils:UtilsService,
    private newsletterService:ApiPublicNewsletterService
  ){

  }

  token

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        console.log(`params`,params); // { orderby: "price" }
        this.token = decodeURI(params.token)
        console.log(`params`,this.token);
        this.getNewsletter()
      }
    );
    
  }

  validNewsletter=false
  showWrongUrl = false

  record:any={}
  getNewsletter(){
    if(this.token){
      this.newsletterService.getUnsubscribe(this.token).subscribe(res=>{
        if(res.status == "OK"){
          this.record = res.data.record 
          let registered = []
          if(this.record.newsletter_subscription){
            registered = this.record.newsletter_subscription.map(x=>x.id)
          }
          this.validNewsletter = true
          this.newsletterLists = []
          if(res.data.newsletters && res.data.newsletters.length > 0){
            this.newsletterLists = res.data.newsletters.map(x=>{
              
              if(x.type=="opt-in"){
                if(registered.includes(x.id)){
                  x.isSelected = true
                }else{
                  x.isSelected = false
                }
              }else{
                if(registered.includes(x.id)){
                  x.isSelected = false
                }else{
                  x.isSelected = true
                }
              }

              return x
            })
          }
        }else{
          this.showWrongUrl=true
        }
      })
    }else{
      this.showWrongUrl=true
    }
  }

  updateNewsLetterItem(item: any){
    item.isSelected = item.isSelected == true ? false : true;
  }

  updateSetting(){

    let selected = []
    for(const item of this.newsletterLists){
      if(item.type == "opt-in"){
        if(item.isSelected==true){
          selected.push(item.id)
        }
      }else{
        if(item.isSelected==false){
          selected.push(item.id)
        }
      }
    }
    if(selected.length > 0){
      this.updateUnsubscribe({
        newsletter_subscription:selected.join(",")
      })
    }else{
      this.moveToConfirmation()
    }
    
  }

  updateUnsubscribe(data){
    this.newsletterService.updateUnsubscribe(this.token,data).subscribe(res=>{
      if(res.status == "OK"){
        this.utils.showNotify("success",this.translate.instant("You have success to update your subscribe list"));
      }else{
        this.utils.showNotify("error",this.translate.instant("Error when update setting, Please try again later"));
      }
    })
  }

  cancelAll(){
    this.updateUnsubscribe({
      newsletter_subscription:""
    })
  }

  moveToConfirmation(){
    this.isConfirm = true;
  }

}
