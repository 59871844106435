import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.css']
})
export class DesignComponent implements OnInit {
  constructor(
  ) {  
    //
  }
  ngOnInit(): void {
    //
  }
}
