import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({
  name: 'boldTextMatch'
})
export class BoldTextMatchPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) {}

  transform(value: string, regex): any {
    if(!regex) return "";
    const words = [...new Set(regex.split(" "))];
    let res_str = value
    words.forEach((word) => {
      if(word)
        res_str = this.replace(res_str, word)
    })
    // console.log(value,res_str,regex,this.sanitizer)
    return this.sanitize(res_str);
  }

  replace(str: string, regex) {
    return str.replace(new RegExp(`(${regex})`, 'gi'), '<b>$1</b>');
  }

  sanitize(str) {
    return this.sanitizer.sanitize(SecurityContext.HTML, str);
  }
}