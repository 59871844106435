import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable({
  providedIn: 'root'
})
export class ApiStripeService {
  api_name = "stripe"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {
  }

  authorizeAccount(code): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/authorize`,{code}, this.utils.getAuthHeader());
  }
}