import { SessionStorageService } from './../../../core/services/session.storage.service';
import { UtilsService } from './../../../core/services/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiNotificationService } from './../../../core/services/crm_api/api-notification.service';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import checkCircleSolid from '@iconify-icons/la/check-circle-solid'
import commentIcon from '@iconify-icons/la/comment'
import listIcon from '@iconify-icons/la/list'
import Pusher from 'pusher-js';
@Component({
  selector: 'app-recent-notification-summary',
  templateUrl: './recent-notification-summary.component.html',
  styleUrls: ['./recent-notification-summary.component.css']
})
export class RecentNotificationSummaryComponent implements OnInit,OnChanges {
  checkCircleIcon = checkCircleSolid
  commentIcon = commentIcon
  listIcon = listIcon
  pusher:Pusher
  @Input('groupName') groupName=""
  @Input('groupItems') groupItems= {}
  @Input('group_notifications_count')  group_notifications_count ={}
  @Input('notify_item') notify_item=null
  @Output() updateUnreadCount= new EventEmitter()

  constructor(
    private apiNotificationService:ApiNotificationService,
    private translate:TranslateService,
    private utils:UtilsService,
    private sessionStorageService:SessionStorageService
  ) { }

  groups = []
  group_reads =[]
  groupItemsReads={}
  groups_count = {}
  groups_class = {}
  
  groups_name = {}
  

  usersOptions=[]
  usersOptionsRead=[]
  group_notifications_count_read={}
  

  
  ngOnInit(): void {


    this.usersOptions=[{
      id: 0,
      name: this.translate.instant("Mark as read"),
      bgColor: 'rgba(255, 193, 7, 0.05)',
      color: '#ffc107',
      cb: this.markAsRead.bind(this)
    },{
      id: 1,
      name: this.translate.instant("Mark as unread"),
      bgColor: 'rgba(255, 128, 139, 0.05)',
      color: '#FF808B',
      cb: this.markAsUnRead.bind(this)
    },{
      id: 2,
      name: this.translate.instant("Delete"),
      bgColor: 'rgba(255, 128, 139, 0.05)',
      color: '#FF808B',
      cb: this.deleteNotification.bind(this)
    }]



    this.usersOptionsRead=[{
      id: 0,
      name: this.translate.instant("Mark as unread"),
      bgColor: 'rgba(255, 193, 7, 0.05)',
      color: '#ffc107',
      cb: this.markAsUnRead.bind(this)
    },{
      id: 1,
      name: this.translate.instant("Delete"),
      bgColor: 'rgba(255, 128, 139, 0.05)',
      color: '#FF808B',
      cb: this.deleteNotification.bind(this)
    }]
    this.groups = Object.keys(this.groupItems)
    this.group_reads = Object.keys(this.groupItems)
    const groupItems = {}
    groupItems[this.groupName] = []
    for(const gr of this.groups){
      const count_gr = {}
      for(const type of ['ticket','task','mention']){
        const type_count = this.groupItems[gr].filter(x=>x.type === type && x.status === 1).length
        if(type_count > 0){
          count_gr[type]=type_count
        }
      }
      this.group_notifications_count_read[gr]=count_gr
    }
    // console.log(this.group_notifications_count_read)

    // if(this.groupName==="today" || this.groupName==="yesterday" || this.groupName==="last_week"){
      
      for(const gr of this.groups){
        groupItems[this.groupName] = groupItems[this.groupName].concat(this.groupItems[gr])
      }
      // this.groupItemsReads = this.groupItems
      this.groupItems = groupItems
      this.groups=[this.groupName]
    // }
    // else{
    //   this.groupItemsReads = this.groupItems
    // }

    
    // this.pusher =new Pusher("eb26f38d9c6c914e2b74", {
    //   cluster: 'us2',
    //   forceTLS: true
    // });
    // let logged = this.sessionStorageService.getLoggedUser()
    // if(this.groupName==="today"){
    //   let name = `notification_${logged.user_id}`
    //   const channel = this.pusher.subscribe(name);
    //   channel.bind('notify',(data,metadata)=>{
    //     if(logged.organization.id===data.organization_id){
    //       this.utils.playAudio('notify')
    //       this.notify(data)
    //       const group_index = this.groups.find(x=>x==='now')
    //       if(group_index === undefined){
    //         this.groups.unshift('now')
    //       }
    //       this.groups_class['now']='not_read'
    //       this.groupItems['now'] = (this.groupItems['now'])?this.groupItems['now']:[]
    //       this.groupItems['now'].unshift(data)
    //       this.group_notifications_count['now'] = (this.group_notifications_count['now'])?this.group_notifications_count['now']:{}
    //       this.group_notifications_count['now'][data.type] = this.group_notifications_count['now'][data.type] ? this.group_notifications_count['now'][data.type]: 0
    //       this.group_notifications_count['now'][data.type] = this.group_notifications_count['now'][data.type] + 1
    //       this.updateUnreadCount.emit({
    //         count:1,
    //         type:data.type
    //       })
    //     }
    //   })
    // }


  }

  ngOnChanges(changes: SimpleChanges): void {
      // console.log(changes)
      // if(this.notify_item && changes.notify_item){

      // }
  }


  notify(item) {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
        
      const n = new Notification(item.message_alert, {
      });
      n.onclick = () => {
        this.handleClick(item);
      }
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          const n = new Notification(item.message_alert, {
          });
          n.onclick = () => {
            this.handleClick(item);
          }
        }
      });
    }
  }

  handleClick(item) {
    const host = window.location.protocol + "//" + window.location.host;
    window.open(`${host}${item.action_link}`, '_blank');
  }


  markAsRead(group,group_name){

    // console.log(group_name,group,this.groupItems)
    
    const ids = []
    for(const it of this.groupItems[group]){
      if(it.status === 0 && it.group_name==group_name){
        ids.push(it.id)
      }
    }
    if(ids.length > 0){
      this.apiNotificationService.updates({
        is_read:1,
        ids
      }).subscribe(res=>{
        if(res.status === "OK"){
                
          for(const it of this.groupItems[group]){
            if(it.status === 0 && it.group_name==group_name){
              this.updateUnreadCount.emit({
                count:-1,
                type:it.type
              })
              it.status = 1
            }
          }
          this.groups_class[group]='read'
        }else{
          this.utils.showNotify('danger',res.message)
        }
      })
    }
  }
  deleteNotification(group,group_name){
    
    const ids = []
    for(const it of this.groupItems[group]){
      if(it.group_name==group_name){
        ids.push(it.id)
      }
    }
    console.log(ids)
    if(ids.length > 0){
      this.apiNotificationService.removes({
        ids
      }).subscribe(res=>{
        if(res.status === "OK"){
          let index = 0
          for(const it of this.groupItems[group]){
            if(it.group_name==group_name){
              this.groupItems[group].splice(index,1)
            }
            index++
          }
          // this.groupItems[group]=[]
          // this.groups.splice(this.groups.findIndex(x=>x===group),1)
        }else{
          this.utils.showNotify('danger',res.message)
        }
      })
    }
  }
  markAsUnRead(group,group_name){

    const ids = []
    for(const it of this.groupItems[group]){
      if(it.status === 1  && it.group_name==group_name){
        ids.push(it.id)
      }
    }
    if(ids.length > 0){
      this.apiNotificationService.updates({
        is_read:1,
        ids
      }).subscribe(res=>{
        if(res.status === "OK"){
                
          for(const it of this.groupItems[group]){
            if(it.status === 1 && it.group_name==group_name){
              this.updateUnreadCount.emit({
                count:1,
                type:it.type
              })
              it.status = 0
            }
          }

          this.groups_class[group]='not_read'
        }else{
          this.utils.showNotify('danger',res.message)
        }
      })
    }
  }




  onOptionSelected(option, item,group_name){
    console.log(option,item,group_name)
    this.usersOptions[option.value].cb(item,group_name)
  }

  executeAction(item){
    if(item.status === 0){
      this.apiNotificationService.update(item.id,{
        is_read:1
      }).subscribe(res=>{
        if(res.status === "OK"){
          item.status = 1
          this.updateUnreadCount.emit({
            count:-1,
            type:item.type
          })
          this.goToLink(item)
        }else{
          this.utils.showNotify('danger',res.message)
        }
      })
    }else{
      this.goToLink(item)
    }
  }
  goToLink(item){
    const host = window.location.protocol + "//" + window.location.host;
    window.open(`${host}${item.action_link}`,'_self');
  }

  filterNotification(items: any[],status): any[] {
    return items.filter(p => p.status === status);
  }

  filterNotificationByGroup(items: any[],group,status=0): any[] {
    const results =  items.filter(p => p.group_name === group && p.status === status);
    let new_items = []
    let new_items_change = []
    for(const rs of results){
      if(rs.message == "change info"){
        new_items_change.push(rs)
      }else{
        new_items.push(rs)
      }
    }

    new_items = new_items.map(x=>{
      if(x.description && x.description.field){
        if(x.description.field == "due_date"){
          x.description.field = "Due Date"
        }else if(x.description.field == "description"){
          x.description.field = "Description"
        }
      }
      return x
    })

    new_items_change = new_items_change.map(x=>{
      if(x.description && x.description.field){
        if(x.description.field == "due_date"){
          x.description.field = "Due Date"
        }else if(x.description.field == "description"){
          x.description.field = "Description"
        }
      }
      return x
    })

    return [new_items,new_items_change]
  }

  getGroupName(items,status=0){
    const groups = []
    for(const item of items){
      if(item.status === status && !groups.includes(item.group_name)){
        groups.push(item.group_name)
      }
    }
    return groups
  }
}
