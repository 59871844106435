<div class="table-control-tab ml-0" [class.from-submenu] = "fromSubmenu">
    <button (click)="toggleTab('all')" mat-button [ngClass]="{'active': selectedTab == 'all'}">
        <span class="name">{{'All' | translate}} </span> <span *ngIf="unread_count>0" class="notification-count badge">{{unread_count}}</span>
    </button>
    <button (click)="toggleTab('mention')" mat-button [ngClass]="{'active': selectedTab == 'mention'}" mat-button>
        <span class="name">{{'Mentioned' | translate}}</span><span *ngIf="unread_count_group.mention && unread_count_group.mention>0" class="notification-count badge">{{unread_count_group.mention}}</span>
    </button>
    <button (click)="toggleTab('task')" mat-button [ngClass]="{'active': selectedTab == 'task'}" mat-button> 
        <span class="name">{{'Tasks' | translate}}</span><span *ngIf="unread_count_group.task && unread_count_group.task>0" class="notification-count badge">{{unread_count_group.task}}</span>
    </button>
    <button (click)="toggleTab('communication')" mat-button [ngClass]="{'active': selectedTab == 'communication'}" mat-button> 
        <span class="name">{{'Communication' | translate}}</span><span *ngIf="unread_count_group.communication && unread_count_group.communication>0" class="notification-count badge">{{unread_count_group.communication}}</span>
    </button>
</div>
