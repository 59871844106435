import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiCustomColumnService {
  api_name = "crm/custom_column";

  constructor( 
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {}

  get(table_name: string, sort: string, search: string, inactive,ticket_pipeline_id=0): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/crm/custom_column/?table_name=${table_name}&sort=${sort}&search=${encodeURI(search)}&inactive=${inactive}&ticket_pipeline_id=${ticket_pipeline_id}`, this.utils.getAuthHeader());
  }

  addColumn(data: any): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/crm/custom_column`, data, this.utils.getAuthHeader());
  }

  updateColumn(id: number, data: any):  Observable<any>{
    return this.httpClient.patch<any>(`${environment.api_url}/api/crm/custom_column/:${id}`, data, this.utils.getAuthHeader());
  }

  deleteColumn(id: number): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/crm/custom_column/:${id}`, this.utils.getAuthHeader());
  }
}

