import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneFormatPipe } from './pipe/phone-format.pipe';



@NgModule({
  declarations: [PhoneFormatPipe],
  exports: [
    PhoneFormatPipe
  ],
  imports: [
    CommonModule,
  ],
  providers: []
})
export class CoreModule { }
