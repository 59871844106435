import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject ,  Subject,Observable,of } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiOrganizationService {

  constructor(private httpClient: HttpClient,private utils:UtilsService) {

  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  getDnc(filter={}):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/dnclist/filter`,filter, this.utils.getAuthHeader())
  }
  countDnc():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/dnclist/count`, this.utils.getAuthHeader())
  }
  deleteDnc(ids): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/dnclist/${ids}`, this.utils.getAuthHeader())
  }
  addDnc(key,delimiter): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/dnclist/import`,{key,delimiter}, this.utils.getAuthHeader())
  }

  addDncInput(phones): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/dnclist`,{phones}, this.utils.getAuthHeader())
  }
  getDncLog(phone): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/dnclist/log`,{phone}, this.utils.getAuthHeader())
  }
  deleteOrganization():Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/organization`,this.utils.getAuthHeader())
  }

  getTeamList():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/organization/team`, this.utils.getAuthHeader())
  }


  getUserList(organization_id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/organization/users/${organization_id}`, this.utils.getAuthHeader())
  }

  sendSupportRequest(organization_id,user_id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/organization/support-request/${organization_id}/${user_id}`, this.utils.getAuthHeader())
  }

  teamFilter(filter={}):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/organization/team-filter`,filter, this.utils.getAuthHeader())
  }

  getOptions():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/organization/options`, this.utils.getAuthHeader())
  }


  filter(filter={}):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/organization/filter`,filter, this.utils.getAuthHeader())
  }

  editOptions(body):Observable<any> {
    return this.httpClient.patch<any>(`${environment.api_url}/api/organization/options`,body, this.utils.getAuthHeader())
  }

  addTeam(name): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/organization/team`,{name}, this.utils.getAuthHeader())
  }
  getTerm(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/organization/term`, this.utils.getAuthHeader())
  }
  saveTerm(term): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/organization/term`,{term}, this.utils.getAuthHeader())
  }
  editTeam(id,name): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/organization/team/${id}`,{name}, this.utils.getAuthHeader())
  }

  deleteTeam(id): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/organization/team/${id}`, this.utils.getAuthHeader())
  }

  getIpAddress():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/organization/ip-address`, this.utils.getAuthHeader())
  }
  addIpAddress(obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/organization/ip-address`,obj, this.utils.getAuthHeader())
  }
  updateIpAddress(id,obj):Observable<any> {
    return this.httpClient.patch<any>(`${environment.api_url}/api/organization/ip-address/${id}`,obj, this.utils.getAuthHeader())
  }
  deleteIpAddress(id):Observable<any> {
    return this.httpClient.delete<any>(`${environment.api_url}/api/organization/ip-address/${id}`, this.utils.getAuthHeader())
  }
  
  update2FaEnforce(obj):Observable<any> {
    return this.httpClient.patch<any>(`${environment.api_url}/api/organization/mfa-enforce`,{mfa:obj}, this.utils.getAuthHeader())
  }
  
  getPortailConfig():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/organization/portail`, this.utils.getAuthHeader())
  }

  updatePortailConfig(config):Observable<any> {
    return this.httpClient.put<any>(`${environment.api_url}/api/organization/portail`, config, this.utils.getAuthHeader())
  }

  generatePortailToken():Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/organization/portail/token`, null, this.utils.getAuthHeader())
  }
}



