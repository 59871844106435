<div *ngIf="inline">
    <ng-container *ngTemplateOutlet="datetimecontainer"></ng-container>
</div>

<div *ngIf="!inline" [ngClass]="{'readonly': readonly}" class="date-form-field align-items-center">
    <input #dateInput class="date-field" type="text" [disabled]="disabled || readonly" (change)="formatDate()" [(ngModel)]="dateModel"  [placeholder]="placeholderFormat" [textMask]="{mask: dateMask, guide: true}" [ngStyle]="{'height': height, 'background-color': backGroundColor}" [class.with-border]="withBorder">

    <a *ngIf="!readonly" (click)="popoverChanged(datetime)" [disablePopover]="disabled" #datetime="ngbPopover" popoverClass="datetimepopover" [ngbPopover]="datetimecontainer" [placement]="placement" [container]="'body'" [openDelay]="1"  [autoClose]="'outside'">
        <span class="iconify calendar-color" data-width="18px" data-height="18px" data-icon="mdi:calendar"></span> 
    </a>
</div>


<ng-template matDatepicker #datetimecontainer>
    <div (mousedown)="$event.stopPropagation()" (click)="$event.stopPropagation()">
        <mat-calendar [(selected)]="model_date" [startAt]="model_date" (selectedChange)="changeDate()"></mat-calendar>
        <hr>
        <ngb-timepicker *ngIf="!dateonly" class="d-flex" [(ngModel)]="model_time" (ngModelChange)="changeTime()"></ngb-timepicker>
    </div>
</ng-template>
