import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.css']
})
export class DatetimePickerComponent implements OnInit, OnDestroy {

  constructor(private translate: TranslateService) { }

  @Input() date;
  @Input() resetDate: Subject<any>;
  @Input() inline;
  @Input() readonly: boolean
  @Input() dateonly: boolean
  @Input() disabled: boolean
  @Input() placement: string = "auto"
  @Input() height: string = 'auto';
  @Input() withBorder: boolean = false;
  @Input() backGroundColor: string = 'inherit';
  @Output() dateChanged = new EventEmitter();
  

  @ViewChild('datetime', {static: false}) datetimePopover
  @ViewChild('dateInput', {static: false}) dateInput

  model_date;
  model_time;

  sub

  dateModel

  translations = {
    en: {
      dateFormat: 'yyyy/MM/DD',
      timeFormat: "HH:mm",
      placeholderDate: "yyyy/mm/dd",
      placeholderTime: "hh:mm"
    },
    fr: {
      dateFormat: 'DD/MM/yyyy',
      timeFormat: "HH:mm",
      placeholderDate: "jj/mm/aaaa",
      placeholderTime: "hh:mm"
    }
  }

  dateFormat: string;
  timeFormat: string;
  placeholderFormat: string;

  dateMask;

  //Format of the outputed date sent to parent
  finalFormat

  ngOnInit(): void {

    if(this.dateonly)
      this.finalFormat = `yyyy-MM-DD`
    else
      this.finalFormat = `yyyy-MM-DD HH:mm:ss`

    this.setTranslations();
    this.parseDate()

    /* if(!this.inline && this.date)
      this.formatDate(); */
      
    if(this.resetDate)
      this.sub = this.resetDate.subscribe((date) => {console.log(date); this.date = date; this.parseDate();})

  }

  ngOnDestroy(): void {
    if(this.sub)
      this.sub.unsubscribe();
  }

  /* ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    if (changes.date) {
      const format = this.dateonly ? `${this.dateFormat}` : `${this.dateFormat} ${this.timeFormat}`
      this.dateModel = moment(this.date).format(format);
      this.parseDate();
    }
  } */

  popoverChanged(popover){
    setTimeout(() => {
      const id = popover._ngbPopoverWindowId
      const popoverWindow = document.querySelector(`ngb-popover-window#${id}`);
      if(popoverWindow){
        popoverWindow.addEventListener('click', (event) => event.stopPropagation())
        popoverWindow.addEventListener('mouseup', (event) => event.stopPropagation())
      }
    }, 100)
  }

  setTranslations(){
    let lang = this.translate.currentLang
    //Set default language to english
    if(!this.translations[this.translate.currentLang]){
      lang = 'en'
    }
    this.dateFormat = this.translations[lang].dateFormat;
    this.timeFormat = this.translations[lang].timeFormat;
    this.placeholderFormat = this.dateonly ? this.translations[lang].placeholderDate : this.translations[lang].placeholderDate + ' ' + this.translations[lang].placeholderTime
    this.dateMask = []
    for(let c of this.placeholderFormat){
      if(c != '/' && c != ':' && c != ' ')
        this.dateMask.push(/[0-9]/)  
      else
        this.dateMask.push(c)
    }
  }

  parseDate(){
    if(this.date){
      this.date = moment(this.date).format(this.finalFormat)
      this.model_date = moment(this.date.split(' ')[0])

      if(!this.dateonly){
        const time =  this.date.split(' ')[1];
        this.model_time = {
          hour: parseInt(time.split(':')[0]),
          minute: parseInt(time.split(':')[1]),
          second: parseInt(time.split(':')[2]),
        }
      }

      //Set the date model to the given date
      if(!this.inline)
        this.dateModel = moment(this.date).format(`${this.dateFormat} ${this.timeFormat}`)
    } else{
      this.model_date = null
      this.model_time = {
        hour: 0,
        minute: 0,
        second: 0
      }
    }
  }
  

  changeDate(){
    console.log(`trigger change date`,this.model_date)
    const [validatedDate, validatedTime] = this.validateDate(
      { 
        year: this.model_date.year(), 
        month: this.model_date.month() + 1, 
        day: this.model_date.date() 
      },
      this.model_time
    );
    this.model_time = validatedTime;
    let time
    //Assign the new date
    if(!this.dateonly)
      time = this.model_time.hour && this.model_time.minute ? `${this.model_time.hour}:${this.model_time.minute}:00` : "00:00:00";

    this.date = this.dateonly ? `${validatedDate.year}-${validatedDate.month}-${validatedDate.day}` : `${validatedDate.year}-${validatedDate.month}-${validatedDate.day} ${time}`;
    if(!this.inline)
      this.dateModel = moment(this.date).format(`${this.dateFormat} ${this.timeFormat}`);

    this.dateChanged.emit(this.date);
    if(this.dateonly && this.datetimePopover){
      this.datetimePopover.close();
    }

    console.log(this.dateModel)
  }

  changeTime(){
    let validatedDate
    if(!this.model_date){
      this.model_date = moment();
      //validatedDate = {year: moment().year(), month: moment().month() + 1, day: moment().date()}
    }
    [validatedDate, this.model_time] = this.validateDate(
      { 
        year: this.model_date.year(), 
        month: this.model_date.month() + 1, 
        day: this.model_date.date() 
      },
      this.model_time
    );
    
    this.date = validatedDate.year + '-' + validatedDate.month + '-' + validatedDate.day  + ' ' + this.model_time.hour + ':' + this.model_time.minute + ':' + '00'
    if(!this.inline)
      this.dateModel = moment(this.date).format(`${this.dateFormat} ${this.timeFormat}`);
    this.dateChanged.emit(this.date);
  }

  validateDate(date, time){
    if(date){
      if(date.month<10 && (!date.month.length || date.month.length < 2)){
        date.month=`0${date.month}`
      }
      if(date.day<10 && (!date.day.length || date.day.length < 2)){
        date.day=`0${date.day}`
      }
    }
    if(time){
      if(time.hour<10 && (!time.hour.length || time.hour.length < 2)){
        time.hour=`0${time.hour}`
      }
  
      if(time.minute<10 && (!time.minute.length || time.minute.length < 2)){
        time.minute=`0${time.minute}`
      }
    }

    return [date, time]
  }

  removeMask(date){
    let i = 0;
    let res = ''
    for(let c of date){
      if(c == '_')
        break;
      else if(c != '/' && c != ':' && c != ' '){
        if(date[i + 1] == '_') break;
      }
      res += c
      i++;
    }
    return res
  }

  formatDate() {
    if(!this.dateModel) return this.date = null
    let resultDate = this.removeMask(this.dateModel);

    let format = `${this.dateFormat} ${this.timeFormat}`
    //Validate the date
    if(this.dateonly)
      format = `${this.dateFormat}`

    let date = moment(resultDate, format).format(format);
    console.log(this.dateonly)
    console.log(date)
    if(date == 'Invalid date'){
      //Since the date is invalid, revert to the previously saved date
      if(this.date){
        return this.dateModel = moment(this.date).format(format);
      } else{
        return this.dateModel = null;
      }
    }
    //If the date is valid, set the new date value as the dateModel
    this.dateModel = date

    this.date = moment(this.dateModel, format).format(this.finalFormat);
    console.log(this.date)
    this.parseDate();
    console.log(this.date)
    this.dateChanged.emit(this.date);
  }

  focus(){
    setTimeout(() => {
      if(this.dateInput)
        this.dateInput.nativeElement.focus();
    }, 0)
   
  }
}
