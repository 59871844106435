import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilsService } from '../utils.service';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiFormResultService {
  api_name = "crm/form"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {
  }
  
  filter(filter = {}): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/result-item/filter`, JSON.stringify(filter), this.utils.getAuthHeader());
  }

  getItem(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/result-item/${id}`, this.utils.getAuthHeader())
  }
}
