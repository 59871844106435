import { Component, Input, OnInit } from '@angular/core';

import x from '@iconify-icons/ls/x';
import plus from '@iconify-icons/ci/plus';
import listAltSolid from '@iconify-icons/la/list-alt-solid';
import cardsBold from '@iconify-icons/ph/cards-bold';
import listCheck from '@iconify-icons/bi/list-check';
import user from '@iconify-icons/bi/person-fill'
import custom from '@iconify-icons/bx/bxs-customize'
import linkChain from '@iconify-icons/akar-icons/link-chain'
import grid from '@iconify-icons/bi/grid-3x3';
import map from '@iconify-icons/akar-icons/map'
import calendar from '@iconify-icons/bx/bx-calendar';


@Component({
  selector: 'app-list-item-loading',
  templateUrl: './list-item-loading.component.html',
  styleUrls: ['./list-item-loading.component.css']
})
export class ListItemLoadingComponent implements OnInit {
  mainService: any;
  listCheckIcon = listCheck
  cardsBoldIcon = cardsBold
  listAltSolidIcon = listAltSolid
  plusIcon = plus
  xIcon = x
  userIcon = user
  customIcon = custom
  linkChainIcon = linkChain

  @Input() itemName = '';
  @Input() titleName = '';
  @Input() isShowSidebar = true;

  headerNavButtons = [
    {
      id: 'list',
      icon: listAltSolid,
      name: 'Liste',
      callback: this
    },
    {
      id: 'board',
      icon: cardsBold,
      name: 'Board',
      callback: this
    },
    {
      id: 'excel',
      icon: grid,
      name: 'Table',
      callback: this
    },
    {
      id: 'map',
      icon: map,
      name: 'Carte',
      callback: this
    },
    {
      id: 'calender',
      icon: calendar,
      name: 'Calender',
      callback: this
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
