import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-notification-menu-header',
  templateUrl: './notification-menu-header.component.html',
  styleUrls: ['./notification-menu-header.component.css']
})
export class NotificationMenuHeaderComponent implements OnInit,OnChanges {
  @Input() selectedTab = 'all'
  @Input() fromSubmenu = false;
  @Input() unread_count_group={
    mention:0,
    task:0,
    communication:0
  }
  @Input() unread_count = 0
  @Output() selectedTabChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
      console.log(changes)
  }

  toggleTab(tab: string): void{
    this.selectedTab = tab;
    this.selectedTabChange.emit(tab);
  }

}
