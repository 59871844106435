import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiEmailContactService {
  api_name = "crm/email_contact";

  constructor( 
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {}

  filter(filter = {}): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`, JSON.stringify(filter), this.utils.getAuthHeader());
  }
  getOptions(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/options`, this.utils.getAuthHeader());
  }
  updateOptions(data): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/options`, data,this.utils.getAuthHeader());
  }
  get():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}`, this.utils.getAuthHeader())
  }
  delete(ids): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/${ids}`, this.utils.getAuthHeader())
  }
  add(obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}`,obj, this.utils.getAuthHeader())
  }
  edit(id,obj): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/${id}`,obj, this.utils.getAuthHeader())
  }

  unsubscribe(token): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/unsubscribe`,{token}, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }


}