<div class="sidebar-menu right-side-menu" [class.extend] = "subSection != null && subSection.length > 0">
  <div class="sidebar-menu-items right-menu-items fancy-scrollbar h-100 mt-0" style="flex-grow: 1;">
    <!-- <div class="toggle-sidebar" [class.static]="subSection != null && subSection.length > 0">
      <span (click)="toggleSidebarClass()" 
            matTooltipPosition = 'above' 
            [matTooltip]="showRightSidebar ? 'Hide side bar' : 'Show side bar'">
      <i class="fas fa-angle-left"></i>
      </span>
    </div> -->
    <div class="toggle-button" (click)="hideExtendedSection()">
      <i class="fas fa-bars"></i>
    </div>
    <div>
      <ul>
        <li *ngIf="organization.module==='crm'" ><a (click)="openSideExtented('notification')">  <i [ngStyle]="{'color': (subSection && subSection == 'notification') ? bgColor : ''}" class="fas fa-bell"></i><span>{{ 'Notifications' | translate }}</span> <span *ngIf="unread_count>0" class="notification-count badge">{{unread_count}}</span> </a></li>
        <li *ngIf="organization.module==='crm'" ><a  (click)="openSideExtented('task')"><i [ngStyle]="{'color': (subSection && subSection == 'task') ? bgColor : ''}" class="fas fa-clipboard-list"></i><span>{{ 'Tasks' | translate }}</span> <span *ngIf="task_unread_count>0" class="notification-count badge">{{task_unread_count}}</span> </a></li>
        <li *ngIf="organization.module==='crm'" ><a><i [ngStyle]="{'color': (subSection && subSection == 'dossiers') ? bgColor : ''}" class="fas fa-clipboard-check"></i><span>{{ 'Dossiers' | translate }}</span></a></li>
        <li *ngIf="organization.module==='crm'"><a><i [ngStyle]="{'color': (subSection && subSection == 'communications') ? bgColor : ''}" class="fas fa-comments"></i><span>{{"Communications"|translate}}</span></a></li>
      </ul>
    </div>
  </div>
  <div *ngIf="subSection && subSection == 'notification'" class="notification-section sub-sidebar extendSidebar">
    <app-right-sidebar-menu-header [title] = "'Notifications'|translate" (read_all)="readAllNotify()" ></app-right-sidebar-menu-header>
    <div class="sub-section-body fancy-scrollbar">
      <div class="notifications-header">
        <app-notification-menu-header
        [(selectedTab)] = "currentTab"
        (selectedTabChange)="loadNotification(1)" 
        [unread_count]="unread_count"
        [unread_count_group]="unread_count_group" 
        >
        </app-notification-menu-header>
      </div> 
      <div class="notification-list">
        <ng-container *ngFor="let group of ['today','yesterday','last_week','other']" >
          <app-recent-notification-summary [groupName]="group" [groupItems]="group_notifications[group]" (updateUnreadCount)="updateUnreadCount($event)" [group_notifications_count]="group_notifications_count[group]" *ngIf="group_notifications[group]" ></app-recent-notification-summary>
        </ng-container>
        <p class="text-center" *ngIf="showLoadMore===true" >
          <button (click)="loadNotification(currentPage+1)" class="btn btn-primary">{{"Load more"|translate}} ... </button>
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="subSection && subSection == 'task'" class="task-section sub-sidebar extendSidebar">
    <app-right-sidebar-menu-header [title] = "'Tasks'|translate"></app-right-sidebar-menu-header>
    <div class="sub-section-body fancy-scrollbar">
      <div [hidden]="createTaskSection">
        <div class="tasks-header">
          <app-tab
          [(selectedTab)] = "currentTabTask"
          [tabItems] = "taskMenuHeaders"
          (selectedTabChange)="loadTask(1)"
          [countObject]="taskCount"
          >
          </app-tab>
        </div>
        <div class="task-list-body">
          <div class="task-container task-form-add">
            <div class="task-item-container " >
              <div class="task-name">
                <a class="task-logo" (click)="$event.stopPropagation();addNewTask()" >
                  <ic-icon [ngClass]="{'icon-disabled': false}" color="var(--primary)" width="25px" height="25px" style="cursor: pointer;" [icon]="plusIcon"></ic-icon>
                </a>
                <span class="" style="cursor: pointer;" (click)="$event.stopPropagation();addNewTask()" class="text-primary">
                  {{"Add new task"|translate}}
                </span>
                <span class="task-name-content">
                  <input 
                  [id]="'task-name-input-new'"
                  (click)="$event.stopPropagation();" 
                  (keydown)="$event.stopPropagation()"
                  type="text" class="task-input-name" [(ngModel)]="taskModel.name">
                </span> 
              </div>
            </div>
          </div>
          <div class="task-container">
            <div class="task-item-container" *ngFor="let item of tasks; let i = index;">
              <app-task-item [item]="item" 
                             [users]="users" 
                             [applyTopBorderRadius] = "i == 0"
                             [applyBottomBorderRadius] = "i == tasks.length - 1">
  
              </app-task-item>
            </div>
            <p class="text-center" *ngIf="showLoadMoreTask===true" >
              <button (click)="loadTask(currentTaskPage+1)" class="btn btn-primary m-t-10">{{"Load more"|translate}} ... </button>
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="createTaskSection">
        <ng-container *ngIf="showAssociate===false">
          <div class="back-to-list">
            <span (click)="createTaskSection = false">
              <i class="fas fa-angle-left m-r-5"></i>
              {{'Add New Task' | translate}}
            </span>
          </div>
          <div class="create-task-form text-left m-t-25">
            <div class="form-group">
              <label for="taskTitle">{{"Task Title"|translate}}</label>
              <input class="form-control" type="text" name="name" [(ngModel)]="taskModel.name" >
            </div>
            <div class="form-group">
              <label for="details">{{"Details"|translate}} :</label>
              <input class="form-control" type="text" name="description"  [(ngModel)]="taskModel.description" >
            </div>
            <div class="form-group position-relative">
              <mat-label>{{"Deadline"|translate}} :</mat-label>
              <input class="form-control" id="due_date"   [(ngModel)]="taskModel.due_date"  [matDatepicker]="picker">
              <mat-datepicker-toggle style="position: absolute; right: 0;top: 50%;
              transform: translateY(-50%);" matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
            <!-- <div class="form-group">
              <label class="full-width">{{"Association"|translate}} :</label>
              <div ngbDropdown class="d-inline-block associates ">
                <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                  <span *ngIf="selectedPipeline===''">{{ticket_select}}</span>
                  <span *ngIf="selectedPipeline!==''">{{selectedPipeline}} > </span>
                  <span *ngIf="selectedStage!==''">{{selectedStage}} > </span>
                  <span *ngIf="selectedTicket!==''">{{selectedTicket}} > </span>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button *ngIf="ticket_view!=='pipeline'" class="btn btn-back" (click)="backToTicket()" > < {{"Back"|translate}} </button>
                  <div class="dropdown-divider"></div>
                  <ul *ngIf="ticket_view==='pipeline'" >
                    <li *ngFor="let item of pipeplines" >
                      <a (click)="selectPipeline(item)" >{{item.name}}</a>
                    </li>
                  </ul>
                  <ul *ngIf="ticket_view==='stage'" >
                    <li *ngFor="let item of stages" >
                      <a (click)="selectStage(item)" >{{item.name}}</a>
                    </li>
                  </ul>
  
                  <ul *ngIf="ticket_view==='ticket'" >
                    <li *ngFor="let item of tickets" >
                      <a (click)="selectTicket(item)" >{{item.name}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->
            <div class="form-group">
              <label class="full-width">{{"Assign To"|translate}}  <a class="btn btn-primary btn-xs" (click)="selectAssociate()" >{{"Select association"|translate}}</a></label>

              <strong class="selected_assoc">{{selectedAssoc}}</strong>

            </div>
            <div class="form-group">
              <label>{{"Attachments"|translate}}:</label>
              <div>
               
              </div>
            </div>
          </div>
          <div class="action-button">
            <button mat-button class="cancel" (click)="createTaskSection = false" >{{"Cancel"|translate}}</button>
              <button mat-button class="create" (click)="saveNewTask()" >{{"Create"|translate}}</button>
          </div>
        </ng-container>
        <ng-container *ngIf="showAssociate===true">
          <div class="back-to-list">
            <span (click)="showAssociate = false">
              <i class="fas fa-angle-left m-r-5"></i>
              {{'Back' | translate}}
            </span>
          </div>
          <div class="create-task-form text-left m-t-25">
            <input type="text" class="form-control" (keyup)="searchAssociate($event)" placeholder="{{'Enter contact, organization or ticket here'}}" />
          </div>

          <div class="create-task-form text-left m-t-15" *ngIf="contacts.length > 0" >
            <div class="group-text">
              {{"Contact"|translate}}
            </div>
            <ul>
              <li *ngFor="let item of contacts">
                <a (click)="pickAssoc('contact',item.id,item.name)" >{{item.name}}</a>
              </li>
            </ul>
          </div>

          <div class="create-task-form text-left m-t-15"  *ngIf="organizations.length > 0">
            <div class="group-text">
              {{"Organization"|translate}}
            </div>
            <ul>
              <li *ngFor="let item of organizations">
                <a (click)="pickAssoc('organization',item.id,item.name)" >{{item.name}}</a>
              </li>
            </ul>
            
          </div>


          <div class="create-task-form text-left m-t-15"*ngIf="crm_tickets.length > 0">
            <div class="group-text">
              {{"Tickets"|translate}}
            </div>
            <ul>
              <li *ngFor="let item of crm_tickets">
                <a (click)="pickAssoc('ticket',item.id,item.name)" >{{item.pipeline_name}} > {{item.stage_name}} > {{item.name}} </a>
              </li>
            </ul>
            
          </div>

          <div class="action-button">
            <!-- <button mat-button class="cancel" (click)="createTaskSection = false" >{{"Cancel"|translate}}</button>
              <button mat-button class="create" (click)="saveNewTask()" >{{"Create"|translate}}</button> -->
          </div>
        </ng-container>
      </div>
      
    </div>
  </div>
</div>