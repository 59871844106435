import { tap, map } from 'rxjs/operators';
import { ApiOrganizationPhoneService } from './../../../core/services/api/api-organization-phone.service';
import { Observable } from 'rxjs';
import { ApiCallStatusService } from './../../../core/services/api/api-call-status.service';
import { ApiAudioFilesService } from './../../../core/services/api/api-audio-files.service';
import { ApiPlivoService } from './../../../core/services/api/api-plivo.service';
import { AwsService } from './../../../core/services/aws.service';
import { SessionStorageService } from './../../../core/services/session.storage.service';
import { UtilsService } from './../../../core/services/utils.service';
import { ApiUserService } from 'src/app/core/services/api/api-user.service';
import { ApiGeneralService } from './../../../core/services/api/api-general.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AuthService } from './../../../core/services/auth.service';
import { ApiSuperAdminService } from './../../../core/services/api/api-super-admin.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import * as mm from 'music-metadata-browser';
import {NgForm} from "@angular/forms"; 
import {loadStripe} from '@stripe/stripe-js/pure';
import { stat } from 'fs';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'aws-sdk/clients/ses';
const ct = require('countries-and-timezones');
const countryTelData = require('country-telephone-data')
// const MicRecorder = require('mic-recorder-to-mp3');

@Component({
  selector: 'app-config-organization',
  templateUrl: './config-organization.component.html',
  styleUrls: ['./config-organization.component.css']
})
export class ConfigOrganizationComponent implements OnInit {
  @Output() closeModal=new EventEmitter()
  constructor(
    private apiOrganizationPhoneService:ApiOrganizationPhoneService,
    private translate:TranslateService,
    private authService:AuthService,
    private apiGeneralService:ApiGeneralService,
    private apiUserService:ApiUserService,
    public utils:UtilsService,
    public sessionStorageService:SessionStorageService,
    public awsService:AwsService,
    public apiPlivoService:ApiPlivoService,
    public apiAudioFilesService:ApiAudioFilesService,
    public apiCallStatusService:ApiCallStatusService
  ) {    
  }


  user_basic={}
  user_address= {}
  user_contact = {}
  percent_completed=0
  organization:any={}

  languages=[]
  nationalities=[]
  genders=[]
  timezones=[]
  credits=[]
  logged={}
  credit={}
  
  stripe
  card:any
  cardNumber:any
  cardExpiry:any
  cardCvc:any

  payment={}
  recurrent_donate:any={}
  verifyModel:any={}
  countries=[]
  countries_phone=[]
  audio_files={}
  phone_number={}


  email_error=false
  teams=[]
  agents = []

  phone_status=[]
  showStatusForm=false
  callStatusModel:any={}

  fixedCode =[]

  steps=['organization-info','organization-address','organization-contact','organization-color','organization-billing','payment-method','payment-credit','payment-recurring','phone-number','phone-audio','phone-status','team-agent','team-list']
  current_step="organization-info"
  completed_steps=[]

  countryMask= 
  { 
    "AC": "+247-####", 
    "AD": "+376-###-###", 
    "AE": "+971-5#-###-####", 
    // "AE": "+971-#-###-####", 
    "AF": "+93-##-###-####", 
    "AG": "+1(268)###-####", 
    "AI": "+1(264)###-####", 
    "AL": "+355(###)###-###", 
    "AM": "+374-##-###-###", 
    "AN": "+599-###-####", 
    // "AN": "+599-###-####", 
    // "AN": "+599-9###-####", 
    "AO": "+244(###)###-###", 
    "AQ": "+672-1##-###", 
    "AR": "+54(###)###-####", 
    "AS": "+1(684)###-####", 
    "AT": "+43(###)###-####", 
    "AU": "+61-#-####-####", 
    "AW": "+297-###-####", 
    "AZ": "+994-##-###-##-##", 
    "BA": "+387-##-#####", 
    // "BA": "+387-##-####", 
    "BB": "+1(246)###-####", 
    "BD": "+880-##-###-###", 
    "BE": "+32(###)###-###", 
    "BF": "+226-##-##-####", 
    "BG": "+359(###)###-###", 
    "BH": "+973-####-####", 
    "BI": "+257-##-##-####", 
    "BJ": "+229-##-##-####", 
    "BM": "+1(441)###-####", 
    "BN": "+673-###-####", 
    "BO": "+591-#-###-####", 
    "BR": "+55(##)####-####", 
    // "BR": "+55(##)7###-####", 
    // "BR": "+55(##)9####-####", 
    "BS": "+1(242)###-####", 
    "BT": "+975-17-###-###", 
    // "BT": "+975-#-###-###", 
    "BW": "+267-##-###-###", 
    "BY": "+375(##)###-##-##", 
    "BZ": "+501-###-####", 
    "CA": "+1(###)###-####", 
    "CD": "+243(###)###-###", 
    "CF": "+236-##-##-####", 
    "CG": "+242-##-###-####", 
    "CH": "+41-##-###-####", 
    "CI": "+225-##-###-###", 
    "CK": "+682-##-###", 
    "CL": "+56-#-####-####", 
    "CM": "+237-####-####", 
    "CN": "+86(###)####-####", 
    // "CN": "+86(###)####-###", 
    // "CN": "+86-##-#####-#####", 
    "CO": "+57(###)###-####", 
    "CR": "+506-####-####", 
    "CU": "+53-#-###-####", 
    "CV": "+238(###)##-##", 
    "CW": "+599-###-####", 
    "CY": "+357-##-###-###", 
    "CZ": "+420(###)###-###", 
    "DE": "+49(####)###-####", 
    // "DE": "+49(###)###-####", 
    // "DE": "+49(###)##-####", 
    // "DE": "+49(###)##-###", 
    // "DE": "+49(###)##-##", 
    // "DE": "+49-###-###", 
    "DJ": "+253-##-##-##-##", 
    "DK": "+45-##-##-##-##", 
    "DM": "+1(767)###-####", 
    "DO": "+1(809)###-####", 
    // "DO": "+1(829)###-####", 
    // "DO": "+1(849)###-####", 
    "DZ": "+213-##-###-####", 
    "EC": "+593-##-###-####", 
    // "EC": "+593-#-###-####", 
    "EE": "+372-####-####", 
    // "EE": "+372-###-####", 
    "EG": "+20(###)###-####", 
    "ER": "+291-#-###-###", 
    "ES": "+34(###)###-###", 
    "ET": "+251-##-###-####", 
    "FI": "+358(###)###-##-##", 
    "FJ": "+679-##-#####", 
    "FK": "+500-#####", 
    "FM": "+691-###-####", 
    "FO": "+298-###-###", 
    "FR": "+262-#####-####", 
    // "FR": "+33(###)###-###", 
    // "FR": "+508-##-####", 
    // "FR": "+590(###)###-###", 
    "GA": "+241-#-##-##-##", 
    "GD": "+1(473)###-####", 
    "GE": "+995(###)###-###", 
    "GF": "+594-#####-####", 
    "GH": "+233(###)###-###", 
    "GI": "+350-###-#####", 
    "GL": "+299-##-##-##", 
    "GM": "+220(###)##-##", 
    "GN": "+224-##-###-###", 
    "GQ": "+240-##-###-####", 
    "GR": "+30(###)###-####", 
    "GT": "+502-#-###-####", 
    "GU": "+1(671)###-####", 
    "GW": "+245-#-######", 
    "GY": "+592-###-####", 
    "HK": "+852-####-####", 
    "HN": "+504-####-####", 
    "HR": "+385-##-###-###", 
    "HT": "+509-##-##-####", 
    "HU": "+36(###)###-###", 
    "ID": "+62(8##)###-####", 
    // "ID": "+62-##-###-##", 
    // "ID": "+62-##-###-###", 
    // "ID": "+62-##-###-####", 
    // "ID": "+62(8##)###-###", 
    // "ID": "+62(8##)###-##-###", 
    "IE": "+353(###)###-###", 
    "IL": "+972-5#-###-####", 
    // "IL": "+972-#-###-####", 
    "IN": "+91(####)###-###", 
    "IO": "+246-###-####", 
    "IQ": "+964(###)###-####", 
    "IR": "+98(###)###-####", 
    "IS": "+354-###-####", 
    "IT": "+39(###)####-###", 
    "JM": "+1(876)###-####", 
    "JO": "+962-#-####-####", 
    "JP": "+81-##-####-####", 
    // "JP": "+81(###)###-###", 
    "KE": "+254-###-######", 
    "KG": "+996(###)###-###", 
    "KH": "+855-##-###-###", 
    "KI": "+686-##-###", 
    "KM": "+269-##-#####", 
    "KN": "+1(869)###-####", 
    "KP": "+850-191-###-####", 
    // "KP": "+850-##-###-###", 
    // "KP": "+850-###-####-###", 
    // "KP": "+850-###-###", 
    // "KP": "+850-####-####", 
    // "KP": "+850-####-#############", 
    "KR": "+82-##-###-####", 
    "KW": "+965-####-####", 
    "KY": "+1(345)###-####", 
    "KZ": "+7(6##)###-##-##", 
    // "KZ": "+7(7##)###-##-##", 
    "LA": "+856(20##)###-###", 
    // "LA": "+856-##-###-###", 
    "LB": "+961-##-###-###", 
    // "LB": "+961-#-###-###", 
    "LC": "+1(758)###-####", 
    "LI": "+423(###)###-####", 
    "LK": "+94-##-###-####", 
    "LR": "+231-##-###-###", 
    "LS": "+266-#-###-####", 
    "LT": "+370(###)##-###", 
    "LU": "+352(###)###-###", 
    "LV": "+371-##-###-###", 
    "LY": "+218-##-###-###", 
    // "LY": "+218-21-###-####", 
    "MA": "+212-##-####-###", 
    "MC": "+377(###)###-###", 
    // "MC": "+377-##-###-###", 
    "MD": "+373-####-####", 
    "ME": "+382-##-###-###", 
    "MG": "+261-##-##-#####", 
    "MH": "+692-###-####", 
    "MK": "+389-##-###-###", 
    "ML": "+223-##-##-####", 
    "MM": "+95-##-###-###", 
    // "MM": "+95-#-###-###", 
    // "MM": "+95-###-###", 
    "MN": "+976-##-##-####", 
    "MO": "+853-####-####", 
    "MP": "+1(670)###-####", 
    "MQ": "+596(###)##-##-##", 
    "MR": "+222-##-##-####", 
    "MS": "+1(664)###-####", 
    "MT": "+356-####-####", 
    "MU": "+230-###-####", 
    "MV": "+960-###-####", 
    "MW": "+265-1-###-###", 
    // "MW": "+265-#-####-####", 
    "MX": "+52(###)###-####", 
    // "MX": "+52-##-##-####", 
    "MY": "+60-##-###-####", 
    // "MY": "+60(###)###-###", 
    // "MY": "+60-##-###-###", 
    // "MY": "+60-#-###-###", 
    "MZ": "+258-##-###-###", 
    "NA": "+264-##-###-####", 
    "NC": "+687-##-####", 
    "NE": "+227-##-##-####", 
    "NF": "+672-3##-###", 
    "NG": "+234(###)###-####", 
    // "NG": "+234-##-###-###", 
    // "NG": "+234-##-###-##", 
    // "NG": "+234(###)###-####", 
    "NI": "+505-####-####", 
    "NL": "+31-##-###-####", 
    "NO": "+47(###)##-###", 
    "NP": "+977-##-###-###", 
    "NR": "+674-###-####", 
    "NU": "+683-####", 
    "NZ": "+64(###)###-###", 
    // "NZ": "+64-##-###-###", 
    // "NZ": "+64(###)###-####", 
    "OM": "+968-##-###-###", 
    "PA": "+507-###-####", 
    "PE": "+51(###)###-###", 
    "PF": "+689-##-##-##", 
    "PG": "+675(###)##-###", 
    "PH": "+63(###)###-####", 
    "PK": "+92(###)###-####", 
    "PL": "+48(###)###-###", 
    "PS": "+970-##-###-####", 
    "PT": "+351-##-###-####", 
    "PW": "+680-###-####", 
    "PY": "+595(###)###-###", 
    "QA": "+974-####-####", 
    "RE": "+262-#####-####", 
    "RO": "+40-##-###-####", 
    "RS": "+381-##-###-####", 
    "RU": "+7(###)###-##-##", 
    "RW": "+250(###)###-###", 
    "SA": "+966-5-####-####", 
    // "SA": "+966-#-###-####", 
    "SB": "+677-###-####", 
    // "SB": "+677-#####", 
    "SC": "+248-#-###-###", 
    "SD": "+249-##-###-####", 
    "SE": "+46-##-###-####", 
    "SG": "+65-####-####", 
    "SH": "+290-####", 
    // "SH": "+290-####", 
    "SI": "+386-##-###-###", 
    "SK": "+421(###)###-###", 
    "SL": "+232-##-######", 
    "SM": "+378-####-######", 
    "SN": "+221-##-###-####", 
    "SO": "+252-##-###-###", 
    // "SO": "+252-#-###-###", 
    // "SO": "+252-#-###-###", 
    "SR": "+597-###-####", 
    // "SR": "+597-###-###", 
    "SS": "+211-##-###-####", 
    "ST": "+239-##-#####", 
    "SV": "+503-##-##-####", 
    "SX": "+1(721)###-####", 
    "SY": "+963-##-####-###", 
    "SZ": "+268-##-##-####", 
    "TC": "+1(649)###-####", 
    "TD": "+235-##-##-##-##", 
    "TG": "+228-##-###-###", 
    "TH": "+66-##-###-####", 
    // "TH": "+66-##-###-###", 
    "TJ": "+992-##-###-####", 
    "TK": "+690-####", 
    "TL": "+670-###-####", 
    // "TL": "+670-77#-#####", 
    // "TL": "+670-78#-#####", 
    "TM": "+993-#-###-####", 
    "TN": "+216-##-###-###", 
    "TO": "+676-#####", 
    "TR": "+90(###)###-####", 
    "TT": "+1(868)###-####", 
    "TV": "+688-90####", 
    // "TV": "+688-2####", 
    "TW": "+886-#-####-####", 
    // "TW": "+886-####-####", 
    "TZ": "+255-##-###-####", 
    "UA": "+380(##)###-##-##", 
    "UG": "+256(###)###-###", 
    "UK": "+44-##-####-####", 
    "US": "+1(###)###-####",
    "UY": "+598-#-###-##-##", 
    "UZ": "+998-##-###-####", 
    "VA": "+39-6-698-#####", 
    "VC": "+1(784)###-####", 
    "VE": "+58(###)###-####", 
    "VG": "+1(284)###-####", 
    "VI": "+1(340)###-####", 
    "VN": "+84-##-####-###", 
    // "VN": "+84(###)####-###", 
    "VU": "+678-##-#####", 
    // "VU": "+678-#####", 
    "WF": "+681-##-####", 
    "WS": "+685-##-####", 
    "YE": "+967-###-###-###", 
    // "YE": "+967-#-###-###", 
    // "YE": "+967-##-###-###", 
    "ZA": "+27-##-###-####", 
    "ZM": "+260-##-###-####", 
    "ZW": "+263-#-######" 
  }

  async  ngOnInit() {


    this.genders=[{
      id:"F",
      text:this.translate.instant("Female")
    },{
      id:"M",
      text:this.translate.instant("Male")
    },{
      id:"U",
      text:this.translate.instant("Undefined")
    }]

    this.fixedCode  = this.utils.getFixedStatusCode()
        
    this.apiGeneralService.getConfigOption().subscribe((res)=>{
      if(res.status === "OK"){
        this.languages = res.data.languages
        this.nationalities = res.data.nationalities
        this.organization = res.data.organization
        if(res.data.recurrent_donate){
          this.recurrent_donate = res.data.recurrent_donate
        }
        this.phone_status = res.data.phone_status
        if(res.data.audio_files){
          this.audio_files = res.data.audio_files
        }
        if(res.data.phone_number){
          this.phone_number = res.data.phone_number
        }

        this.teams = res.data.teams
        this.agents = res.data.agents
        
        if(this.organization.logo){
          this.logo_url = this.organization.logo;
        }



      }
    })


    const tzs = ct.getAllTimezones();
    
        
    for (let c of countryTelData.allCountries){
      this.countries.push({id:c.dialCode,text:c.name+" +"+c.dialCode})
      this.countries_phone.push({id:c.iso2,text:c.name})
    }

    const times =[]
    times.push({id:"",text:this.translate.instant("Selectionner la fuseau horaire désirée")})
    for(const timezone in tzs){
      let tz = tzs[timezone]
      times.push({id:tz.name,text:tz.name+" UTC"+tz.utcOffsetStr})
    }
    this.timezones = times;

    this.getCompletedStep()


    const logged = this.sessionStorageService.getLoggedUser()
    this.logged = logged


    this.stripe = await loadStripe(logged.stripe_pk);

    if(logged.credits){
      this.credits = logged.credits
      this.credit=this.credits.find((credit)=>credit.is_use_default==1)
      console.log(this.credit)
    }
    
  }
  getCompletedStep(){

    this.apiGeneralService.checkConfig().subscribe((res)=>{
      if(res.status === "OK"){
        this.completed_steps = res.data.completed_steps
        this.percent_completed = res.data.percent_completed
      }
    })
  }
  showConfigContent(tab){
    this.current_step=tab
    if(tab === "payment-method"){
      
      setTimeout(()=>{
        const elements = this.stripe.elements();
        this.cardNumber = elements.create('cardNumber',{
          style:{
            base:{
              fontWeight:'bold',
              fontSize:'18px'
            }
          }
        });
        this.cardNumber.mount('#card_number');
  
        this.cardExpiry = elements.create('cardExpiry',{
          style:{
            base:{
              fontWeight:'bold',
              fontSize:'14px'
            }
          }
        });
        this.cardExpiry.mount('#card_expiry');
  
        this.cardCvc = elements.create('cardCvc',{
          style:{
            base:{
              fontWeight:'bold',
              fontSize:'14px'
            }
          }
        });
        this.cardCvc.mount('#card_cvc');
      })
    }
  }

  goToNextStep(){
    const index = this.steps.indexOf(this.current_step);
    this.current_step = this.steps[index+1]
  }
  phone_wizard_step = 0
  rent_phone_wizard_step = 0

  phone_type = 'own'

  rentPhoneNumber(){
    this.phone_type = 'buy'
    this.getPagePhone(1)
  }

  async continueConfig(){
    console.log(this.current_step)
    switch(this.current_step){
      case 'organization-info':
      case 'organization-address':
      case 'organization-contact':
      case 'organization-color':
      case 'organization-billing':
        if(this.isImageCrop === true && this.logo_url){
          const file_data = this.logo_url.replace(/^data:image\/\w+;base64,/, "");
          const buf = new Buffer(file_data, 'base64');

          this.awsService.fileUploadCrop(buf, (data)=> {
            this.organization.logo = data.Location
            this.isImageCrop=false
            this.logo_url = data.Location
            this.saveOrganizationInfo()
          })
        }else{
          this.saveOrganizationInfo()
        }
        break;

      case 'payment-method':
        await this.saveCreditCard()
        break;
      case 'payment-credit':
        this.addCredit()
        break;
      case 'payment-recurring':
        this.saveRecurrentDonate()
        break;

      case 'phone-number':
        if(this.phone_wizard_step===0){
          if(this.verifyModel.code && this.verifyModel.phone ){
            this.sendVerifyPhone()
          }else{
            this.utils.showNotify('error',this.translate.instant('Please input phone and code'))
          }
        }else if(this.phone_wizard_step === 1){
          if(this.verifyModel.verify_code){
            this.validatePhone()
          }else{
            this.utils.showNotify('error',this.translate.instant('Please input the code send to the phone number'))
          }
        }else if(this.phone_wizard_step === 2){

          this.phone_wizard_step=0
          this.goToNextStep() 
          this.getCompletedStep()
        }

        break;
      case 'phone-status':
        this.goToNextStep()
        break;

        case 'team-agent':
          this.saveAgent()
          break;

        case 'team-list':
            this.saveTeam()
            break;
            
    }
  }

    sendVerifyPhone(){
      const phone = this.verifyModel.code+this.verifyModel.phone
      console.log(this.apiPlivoService)
      this.apiPlivoService.verifyPhone(phone).subscribe((res)=>{
        if(res.status === "OK"){
          this.phone_wizard_step ++
        }else{
          this.utils.showNotify('error',res.message)
        }
      })
    }
    validatePhone(){
      const verify_code = this.verifyModel.verify_code
      const phone = this.verifyModel.code+this.verifyModel.phone
      this.apiPlivoService.verifyCode(phone,verify_code).subscribe((res)=>{
        if(res.status === "OK"){
          this.phone_wizard_step ++
        }else{
          this.utils.showNotify('error',res.message)
        }
      })
    }


  saveOrganizationInfo(){
    this.apiUserService.saveOrganization(this.organization).subscribe(res=>{
      if(res.status==="OK"){

        const logged = this.sessionStorageService.getLoggedUser()
        logged.organization = this.organization
        this.sessionStorageService.set('logged',logged)

        this.utils.showNotify('success','Success')
        this.getCompletedStep()
        this.goToNextStep()
      }else{
        this.utils.showNotify('error',res.message)
      }
    })
  }



  skipConfig(){
    this.goToNextStep()
    this.closeModal.emit()
  }

  showUploadLogo(){
    document.getElementById('file').click()
  }

  showUploadAudio(){
    document.getElementById('file_audio').click()
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  field_types:any[]=[]

  logo_url =""
  isImageCrop=false

  imageCropped(event: ImageCroppedEvent) {
    this.logo_url = event.base64;
    this.isImageCrop=true
  }

  async saveCreditCard(){

    const {token, error} = await this.stripe.createToken(this.cardNumber);
    if(error !== undefined){
      console.log(error)
      this.utils.showNotify('error',error.message)
    }else{
      this.apiUserService.saveCredit({
        stripe_tok:token.id,
        last4:token.card.last4,
        brand:token.card.brand,
        exp_month:token.card.exp_month,
        exp_year:token.card.exp_year
      }).subscribe(res=>{
        if(res.status === "OK"){

          this.credits.push(res.data)
          const logged = this.sessionStorageService.getLoggedUser()
          logged.credits = this.credits
          this.sessionStorageService.set('logged',logged)

          this.cardNumber.clear()
          this.cardExpiry.clear()
          this.cardCvc.clear()
          this.goToNextStep() 
          this.getCompletedStep()
          this.utils.showNotify('success',this.translate.instant("Success"))
        }else{
          this.utils.showNotify('error',res.message)
        }
      })
    }
  }

  addCredit(){
    this.apiUserService.addCredit(this.payment).subscribe(res=>{
      if(res.status === "OK"){
        const credit = res.data.credit
        const logged = this.sessionStorageService.getLoggedUser()
        logged.organization.credit = credit
        this.sessionStorageService.set('logged',logged)
        this.organization.credit = credit
        this.getCompletedStep()
        this.goToNextStep()
        this.utils.showNotify('success',this.translate.instant("Success"))
      }else{
        this.utils.showNotify('error',res.message)
      }
    })
  }

  saveRecurrentDonate(){
    if(this.recurrent_donate.start_date){
      this.recurrent_donate.start_date = this.utils.dateObjectToMysqlDate(this.recurrent_donate.start_date)
    }
    this.apiUserService.addCreditMonthly(this.recurrent_donate).subscribe((res)=>{
      if(res.status === "OK"){
        this.getCompletedStep()
        this.goToNextStep()
        this.utils.showNotify('success',this.translate.instant("Success"))
      }else{
        this.utils.showNotify('error',res.message)
      }
    })
  }


  filename:File
  audioMetaData
  recording_state = 'ready'

  start_recording=false
  can_continue_recording=false

  importAudio(){
    const that = this
    this.awsService.fileUpload(this.filename,function (data) {
      that.utils.showLoading()
      const there = that
      that.apiAudioFilesService.add({
        name:that.filename.name,
        description:that.filename.name,
        location:data.Location,
        audio_format:that.filename.type,
        audio_filesize:that.filename.size,
        audio_length:that.audioMetaData.format.duration
      }).subscribe((res:any)=>{
        if(res.status === "OK"){
          there.getCompletedStep()
          there.goToNextStep()
          there.utils.hideLoading()
          there.utils.showNotify('success',there.translate.instant("Success"))
        }else{
          this.utils.showNotify('error',res.message)
        }
      })
    })
  }
  fileChange(event) {
    let fileList: FileList = event.target.files;

    if(fileList.length > 0) {
      let file: File = fileList[0];
      const that = this
      this.filename = file
      this.audioMetaData = {}
      mm.parseBlob(file).then((res)=>{
          that.audioMetaData = res
          that.importAudio()
      });
    }
  }

  show_recording = false
  showRecordAudio(){
    this.show_recording = true
  }

  recordingFile:File
  audio = new Audio()
  recorder = {}
  // new MicRecorder({
  //   bitRate: 128
  // });

  // startRecording(){
  //   this.recording_state = 'recording'
  //   this.recorder.start().then(() => {
  //   }).catch((e) => {
  //     console.error(e);
  //   });
  // }


  // async stopRecording(){
  //   this.recording_state = 'stop'
  //   const that = this
  //   this.recorder.stop().getMp3().then( async ([buffer, blob]) => {
  //     const file = new File(buffer, 'Recoding'+Date.now()+'.mp3', {
  //       type: blob.type,
  //       lastModified: Date.now()
  //     });
  //     this.recordingFile = file
  //   }).catch((e) => {
  //     this.utils.showNotify('error',this.translate.instant("We could not receive your message"))
  //     console.log(e);
  //   });
  // }
  // stop(){
  //   this.audio.pause();
  //   this.audio.currentTime = 0;
  // }
  // play(src){
  //   this.stop()
  //   this.audio.src = src
  //   this.audio.load()
  //   this.audio.play()
  // }
  // recordAgain(){
  //   this.startRecording()
  // }
  // playRecordAudio(){
  //   this.stop()
  //   this.audio.src = URL.createObjectURL(this.recordingFile)
  //   this.audio.load()
  //   this.audio.play()
  // }
  async saveRecord(filename){
    if(filename.value){
      this.utils.showLoading()
      let file = this.recordingFile
      const musicMetaData = await mm.parseBlob(file)
      let that = this
      that.awsService.fileUpload(file,function (data) {
        const there = that
        that.apiAudioFilesService.add({
          name:filename.value,
          description:"Recording",
          location:data.Location,
          audio_format:file.type,
          audio_filesize:file.size,
          audio_length:musicMetaData.format.duration
        }).subscribe((res:any)=>{
          there.utils.hideLoading()
          if(res.status === "OK"){
            there.utils.showNotify('success',there.translate.instant("The recording has been uploaded"))
              
            there.getCompletedStep()
            there.goToNextStep()
          }else{
            there.utils.showNotify('error',res.message)
          }
        })
      })
    }else{
      this.utils.showNotify('error',this.translate.instant("Please enter the file name"))
    }
  }

  showPhoneStatusForm(){
    this.showStatusForm=true
    this.callStatusModel={}
    this.callStatusModel.group_name = "Human"
  }
  savePhoneStatus(){
    if(this.callStatusModel.name && this.callStatusModel.code){

      if(this.callStatusModel.id){
        this.apiCallStatusService.edit(this.callStatusModel.id,this.callStatusModel).subscribe((res)=>{
          if(res.status === "OK"){

            this.utils.showNotify('success',this.translate.instant("Success"))

            const index = this.phone_status.findIndex(x=>x.id===this.callStatusModel.id);
            this.phone_status[index] = this.callStatusModel

            this.callStatusModel= {}
            
            this.showStatusForm=false
          }else{
            this.utils.showNotify('error',res.message)
          }
        })
      }else{
        this.apiCallStatusService.add(this.callStatusModel).subscribe((res)=>{
          if(res.status === "OK"){
            this.utils.showNotify('success',this.translate.instant("Success"))
            this.phone_status.push({
              id:res.data.id,
              name:res.data.name,
              code:res.data.code,
              color:res.data.color,
              group_name:res.data.group_name,
              description:res.data.description
            })

            this.callStatusModel= {}
            
            this.showStatusForm=false
          }else{
            this.utils.showNotify('error',res.message)
          }
        })
      }

    }else{
      this.utils.showNotify('error',this.translate.instant("Please enter the code and name"))
    }
  }
  userModel={}
  teamModel={}

  saveAgent(){
    // console.log(form)
    this.apiUserService.addUser(this.userModel).subscribe((res)=>{
      if(res.status === "OK"){
        this.utils.showNotify('success',this.translate.instant("Success"))

        this.agents.push(res.data)

        this.getCompletedStep()
        this.goToNextStep()


      }else{
        this.utils.showNotify('error',res.message)
      }
    })
  }

  saveTeam(){
    // console.log(form)
    this.apiUserService.addTeamAndAgent(this.teamModel).subscribe((res)=>{
      if(res.status === "OK"){
        this.utils.showNotify('success',this.translate.instant("Success"))
        this.closeModal.emit()
      }else{
        this.utils.showNotify('error',res.message)
      }
    })
  }

  editPhoneStatus(status){
    this.showStatusForm=true
    this.callStatusModel = status
  }


  public phoneRows:any[]
  asyncPhoneRows: Observable<any[]>;
  public phonePage = 1;
  public phoneLimit  = 10
  public phoneTotal: number;
  phoneFilter={}
  loading=false
  phoneModel:any={}

  getPagePhone(page: number) {
    console.log(page)
    this.loading = true;
    this.asyncPhoneRows = this.apiOrganizationPhoneService.filterPhone({page:page,limit:this.phoneLimit,filter:this.phoneFilter})
      .pipe(
        tap(res => {
          this.phoneTotal = res.data.count;
          this.phonePage = res.data.page;
          this.loading = false;
        }),
        map(res => {
          this.phoneRows = res.data.list
          return res.data.list
        })
      );
  }
  applyLimitPhone(limit:string){
    this.phoneLimit = Number(limit)
    this.getPagePhone(1)
  }

  rentPhone(phoneItem){
    this.phoneModel={}
    this.phoneModel.type = 'buy'
    this.phoneModel.phone=phoneItem.number
    this.rent_phone_wizard_step = 1
  }


  savePhoneForm(form:NgForm){
    
    this.apiOrganizationPhoneService.add(this.phoneModel).subscribe((res)=>{
      if(res.status === "OK"){
        this.getCompletedStep()
        this.goToNextStep()
      }else{
        this.utils.showNotify('error',res.message)
      }
    })
  }
  closePhoneForm(){
    this.rent_phone_wizard_step = 0
  }
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  options={
    componentRestrictions: { country: 'CA' }
  }
  public handleAddressChange(address: any) {
    console.log(address.address_components)
    let civique = ""
    let rue = ""
    for(const adr of address.address_components){
      if(adr.types.includes("street_number")){
        civique = adr.long_name
      }
      if(adr.types.includes("route")){
        rue = adr.long_name
      }

      if(adr.types.includes("postal_code")){
        this.organization.billing_codepostal  = adr.long_name
      }
      
      if(adr.types.includes("country")){
        this.organization.billing_country = adr.long_name
      }

      if(adr.types.includes("administrative_area_level_1")){
        this.organization.billing_province = adr.short_name
      }


      if(adr.types.includes("locality") && adr.types.includes("political")){
        this.organization.billing_city = adr.long_name
      }
      
    }
    this.organization.billing_address = civique +" "+rue
  }
  
  phone_mask = '000 000 0000'
  specialCharacters=[]
  phone_prefix = "+"

  detectPrefix(mask){
    let mask_prefix = mask.split('(',2)
    let prefix = ""
    if(mask_prefix[1]!==undefined){
      prefix="("
      return prefix
    }
    mask_prefix = mask.split('-',2)
    if(mask_prefix[1]!==undefined){
      prefix="-"
      return prefix
    }
  }




  onChangePhoneCountry(){
    const code = this.verifyModel.code.toUpperCase()
    let mask = this.countryMask[code]
    this.specialCharacters=[')','(','+','-']
    console.log(mask)
    const detect_prefix = this.detectPrefix(mask)
    const prefix = mask.split(detect_prefix)


    this.phone_prefix = prefix[0]
    prefix.shift()
    mask = detect_prefix+prefix.join(detect_prefix)
    const mk = mask.split('#')
    mask = mk.join('0')
    this.phone_mask = mask
    
  }

  deleteCredit(id){
    if(confirm(this.translate.instant("Are you sure?"))){
      this.apiUserService.deleteCredit(id).subscribe((res)=>{
        if(res.status === "OK"){

          this.credits=this.credits.filter((credit)=>credit.id!==id)

          const logged = this.sessionStorageService.getLoggedUser()
          logged.credits = this.credits
          this.sessionStorageService.set('logged',logged)
          this.utils.showNotify('success',this.translate.instant("Success"))
        }else{
          this.utils.showNotify('error',res.message)
        }
      })
    }
  }

  makeDefaultCredit(credit){
    if(confirm(this.translate.instant("Are you sure?"))){
      this.apiUserService.defaultCredit(credit.id).subscribe((res)=>{
        if(res.status === "OK"){

          this.credits= res.data //this.credits.filter((credit)=>credit.id!==id)

          const logged = this.sessionStorageService.getLoggedUser()
          logged.credits = this.credits
          this.sessionStorageService.set('logged',logged)
          this.utils.showNotify('success',this.translate.instant("Success"))
        }else{
          this.utils.showNotify('error',res.message)
        }
      })
    }
  }
}
