import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PopoverScrollFixService } from 'src/app/core/services/popover-scroll-fix.service';

@Component({
  host: {
  },
  selector: 'app-options-dropdown',
  templateUrl: './options-dropdown.component.html',
  styleUrls: ['./options-dropdown.component.css']
})
export class OptionsDropDownComponent implements OnInit {

  //Options input need a name (name) and a callback function (cb) to work
  //It can additionally have a color (color), a background color (bgColor), and an icon (icon)
  @Input() options: any[];
  @Input() width: string;
  @Input() title: string;
  @Input() autoClose: boolean;
  @Input() index: number | number[]
  @Input() container: string;
  @Input() isVertical = false;
  @Input() fromCOntentBox = false;

  //Options: 'regular', 'small'
  @Input() size: string;

  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Output() clicked: EventEmitter<any> = new EventEmitter();

  @ViewChild('p') popoverRef
  constructor(private _eref: ElementRef, private popoverFixService: PopoverScrollFixService) { }

  placement = [
    "bottom", "bottom-left", "bottom-right", "left-bottom", "top", "top-left", "top-right",  "left", "left-top",  "right", "right-top", "right-bottom"
  ]

  ngOnInit(): void {

  }

  /* onClick(event) {
   if (!this._eref.nativeElement.contains(event.target) && !event.target.classList.contains('dropdown-btn')) // or some similar check
    this.closePopover()
  }

  closePopover(){
    document.getElementById('dd').style.display = 'none';
  } */

  popoverOpen(pop){
    this.popoverFixService.registerPopover(pop)
  }

  popoverClose(pop){
    this.popoverFixService.unregisterPopover(pop)
  }

  optionSelected(index){
    setTimeout(() => {
      this.selected.emit({index: this.index, value: this.options[index].id});
    }, 30)
  }

  /* checkPopoverBoundaries(){
    const interval = setInterval(() => {
      const popover = document.getElementById('popover-ref')
      if(popover){
        popover.style.opacity = '0'
        const rect1 = popover.getBoundingClientRect();
        if(rect1.width > 0 && rect1.height > 0){
          clearInterval(interval)
          if((rect1.top >= 0 && rect1.left >= 0 && rect1.bottom <= (window.innerHeight || document.documentElement.clientHeight))){
          } else{
            this.popoverRef.placement = 'top'
          }
          popover.style.opacity = '1'
        }
      }
    }, 1)
  } */

  public close(){
    this.popoverRef.close();
  }

}
