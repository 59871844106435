<app-home-header></app-home-header>
<div class="container">
  <div class="main">
    <div class="page-title">{{"Unsubscribe"|translate}}
    </div>

    <div class="page-help m-t-30">{{"Are you sure ?"|translate}}</div>
    <div class="login-form text-center">
        <button class="btn btn-default">{{"Cancel"}}</button>
        <button (click)="sendUnsubscribe()" class="btn btn-danger">{{"Submit"}}</button>
    </div>
  </div>
</div>
