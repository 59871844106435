import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ticket-item',
  templateUrl: './ticket-item.component.html',
  styleUrls: ['./ticket-item.component.css']
})
export class TicketItemComponent implements OnInit {

  constructor(private router: Router) { }

  @Input() ticket
  @Input() border:boolean = true;
  @Input() maxContactCount: number = 1;
  @Input() maxOrganizationCount: number = 1;

  ngOnInit(): void {
  }

  getContactSurplusCount(arr1, arr2){
    if(!arr2) return arr1.length - 2
    if(arr2.length >= 1){
      return arr1.length - 1
    } else if(arr2.length < 1){
      return arr1.length + arr2.length - 2 
    }
  }

  openAssociation(id: number){
    const url = this.router.serializeUrl(this.router.createUrlTree(['/admin/crm/tickets/:' + id]))
    const newTab = window.open(url, '_blank'); 
    if(newTab) {
        newTab.opener = null;
    }
  }

}
