import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ApiUserService } from 'src/app/core/services/api/api-user.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import user from '@iconify-icons/fa-solid/user';
import heavyMultiplicationX from '@iconify-icons/emojione-monotone/heavy-multiplication-x';
import { Input } from '@angular/core';

@Component({
  selector: 'tech-support-modal-body',
  templateUrl: './tech-support-modal-body.component.html',
  styleUrls: ['./tech-support-modal-body.component.css']
})
export class TechSupportModalBodyComponent implements OnInit {
  currentTechSupportMOdalStep = '1';
  userIcon = user;
  heavyMultiplicationXIcon = heavyMultiplicationX;

  @Input() agentData
  @Output() closeEditForm: EventEmitter<any> = new EventEmitter();

  constructor(
    private apiUserService:ApiUserService,
    private translate:TranslateService,
    private utils:UtilsService
  ) { }

  ngOnInit(): void {
  }
  expiry_option="day"
  expired_date
  agree = false
  handleTechSupportConfirm(){
    if(this.currentTechSupportMOdalStep == '1') {
      this.currentTechSupportMOdalStep = '2'
    } else {
      if(this.agree==true){
        // console.log(this.agree,this.expiry_option,this.expired_date)
        this.apiUserService.approveAgentRequest({
          agent_id:this.agentData.user.id,
          expiry_option:this.expiry_option,
          expired_date:this.expired_date
        }).subscribe(res=>{
          if(res.status == "OK"){
            this.utils.showNotify("success",this.translate.instant("Success"))
            this.closeEditForm.emit()
          }else{
            this.utils.showNotify("error",this.translate.instant("Error"))
          }
        })
      }
      
    }
  }

}
