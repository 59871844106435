export const environment = {
  production: true,
  site_url:'https://crm.monelu.org',
  api_url:'https://crmapi.monelu.org',
  secret_key:'predictive',
  front_end_url:'https://portail.democratik.org',
  democratik_api_url:'https://monelection.org',
	AwsUserPoolId: 'ca-central-1_83eNx7xS1', 
  AwsIdentifyPoolId:'ca-central-1:80abcbdc-6251-47a2-a46a-ea211e01bc4d',
	AwsClientId: '3fnjn7s7db8flqtd96u6iffr93', 
  AwsRegion:'ca-central-1',
  stripeClientId:"ca_O5O3gZ03qzEXZ0fJNwZFjQzttyIBJlNY",
  stripePk:"pk_test_51NJDA1BlhtBlrWQOYhXa6uFydTcSpYEW9uDfF4lmqj8z7E9t3Z5r8RNG9VxNJT0MlbNWeWSdnLwcDXwPLwH0PvQy008rKpRUT4"
};