import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiContactsService {
  api_name = "crm/contact";

  constructor( 
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {}

  filter(filter = {}): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`, JSON.stringify(filter), this.utils.getAuthHeader());
  }

  getCounts(filter = {}): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/count`, JSON.stringify(filter), this.utils.getAuthHeader());
  }

  export(data, responseType): Observable<any>{
    const options = {headers: this.utils.getAuthHeader().headers, responseType: responseType}
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/export`, data, options);
  }

  add(data): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}`, data, this.utils.getAuthHeader());
  }

  edit(id, data): Observable<any> {
    return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/${id}`, data, this.utils.getAuthHeader());
  }

  searchByFullName(search, limit=50): Observable<any>{
    // console.log(this)
    const filter_obj = {
      filter: [
        {
          key: 'full_name',
          value: search,
          op: 'like'
        }
      ],
      fields: [
        {field:'id'}, {field:'first_name'}, {field:'last_name'}, {field:'phone'}, {field:'email'}, {field:'profile_image'}
      ],
      sort: 'id asc',
      start: 0,
      limit: limit
    }
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`, filter_obj, this.utils.getAuthHeader());
  }

  checkDuplicate(data): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/duplicate`, data, this.utils.getAuthHeader());
  }

  delete(ids): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/${ids}`, this.utils.getAuthHeader());
  }

  permanentlyDelete(id): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/permanently/:${id}`, this.utils.getAuthHeader());
  }

  get(id): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/:${id}`, this.utils.getAuthHeader());
  }

  languages(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/languagelist`, this.utils.getAuthHeader());
  }

  nationalities(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/nationalitylist`, this.utils.getAuthHeader());
  }

  categories(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/categories`, this.utils.getAuthHeader());
  }

  streets = ((query, limit=1000): Observable<any> => {
    return this.httpClient.get<any>(`${environment.api_url}/api/crm/contact/street/searchasyoutype/?search=${encodeURI(query)}&limit=${limit}`, this.utils.getAuthHeader());
  })

  getOptions(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/crm/contact/options`, this.utils.getAuthHeader());
  }

  updateOptions(options): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/crm/contact/options`, options, this.utils.getAuthHeader());
  }

  getActivityLog(id: number, size: number, offset: number, sort: string): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/activity/:${id}/?size=${size}&offset=${offset}&sort=${sort}`, this.utils.getAuthHeader())
  }


  getFilterOptions(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/crm/contact/filter-options`, this.utils.getAuthHeader());
  }


  getTitle(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/title`, this.utils.getAuthHeader());
  }

  addTitle(obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/title` , obj , this.utils.getAuthHeader());
  }

  updateTitle(id,obj): Observable<any>{
    return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/title/${id}` , obj , this.utils.getAuthHeader());
  }

  deleteTitle(id): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/title/${id}`, this.utils.getAuthHeader());
  }


  mergeContact(sourceId,destinationId,option): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/merge`,{
      source_id: sourceId,
      destination_id: destinationId,
      option
    }, this.utils.getAuthHeader());
  }

  geSubscriptions(id): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/subscriptions/${id}`, this.utils.getAuthHeader())
  }

  getFieldData(body, is_create): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/fields?is_create=${is_create}`, body, this.utils.getAuthHeader())
  }
}



