import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiCommunicationService {
  api_name = "crm/communication";

  constructor( 
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {}

  addCommunication(data): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}`, data, this.utils.getAuthHeader());
  }
  
  getCommunicationById(communicationId): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/:${communicationId}`, this.utils.getAuthHeader());
  }

  filterCommunications(filter): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`, filter,this.utils.getAuthHeader());
  }

  updateCommunication(communicationId, data): Observable<any>{
    return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/:${communicationId}`, data,this.utils.getAuthHeader());
  }

  deleteCommunication(id): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/:${id}`, this.utils.getAuthHeader());
  }

  addThread(data): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/thread`, data, this.utils.getAuthHeader());
  }

  updateThread(id, data): Observable<any>{
    return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/thread/:${id}`, data, this.utils.getAuthHeader());
  }

  deleteThread(id): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/thread/:${id}`, this.utils.getAuthHeader());
  }

  threadList(type, id): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/threadlist?type=${type}&id=${id}`, this.utils.getAuthHeader());
  }

  //Main filter function to filter by threads
  filter(filter={}): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/thread/filter`, filter,this.utils.getAuthHeader());
  }

  inboxList(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/inboxlist`, this.utils.getAuthHeader());
  }

  getRecentContacts(inboxId): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/recent?inbox_id=${inboxId}`, this.utils.getAuthHeader());
  }

  sendSms(data): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/sms/send`, data, this.utils.getAuthHeader());
  }

  sendEmail(data): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/email/send`, data, this.utils.getAuthHeader());
  }
  getCall(obj={}):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/get-call`,obj, this.utils.getAuthHeader())
  }
}



