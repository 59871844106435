import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";
import {UtilsService} from "../utils.service";
import { Injectable } from '@angular/core';

@Injectable()
export class ApiNotificationService {
    api_name = "crm/notification";
    url = "3.96.35.194";
    constructor( 
      private httpClient: HttpClient,
      private utils:UtilsService
    ) {}

    filter(filter={}):Observable<any> {
        return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`,filter, this.utils.getAuthHeader())
    }
  
    add(data: any): Observable<any>{
        // console.log(`${environment.api_url}/api/${this.api_name}/`)
        return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}`, data, this.utils.getAuthHeader())
    }
    get(type='all',page=1): Observable<any>{
        return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}?type=${type}&page=${page}`, this.utils.getAuthHeader())
    }
    getItem(id: number): Observable<any>{
        return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/:${id}`, this.utils.getAuthHeader())
    }
    update(id: number, data: any): Observable<any>{
        return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/:${id}`, data, this.utils.getAuthHeader())
    }
    remove(id: number): Observable<any>{
        return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/:${id}`, this.utils.getAuthHeader())
    }
    move(data: any): Observable<any>{
        return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/move`, data, this.utils.getAuthHeader())
    }

    updates(data: any): Observable<any>{
        return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/updates`, data, this.utils.getAuthHeader())
    }


    readAll(): Observable<any>{
        return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/read-all`, {}, this.utils.getAuthHeader())
    }

    removes(data: any): Observable<any>{
        return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/removes`, data, this.utils.getAuthHeader())
    }
}


