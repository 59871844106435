import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject ,  Subject,Observable,of } from 'rxjs';
import {SignupRes} from "../../models/signup.res";
import {SigninRes} from "../../models/signin.res";
import {environment} from "../../../../environments/environment";
import {tap,catchError,map} from "rxjs/operators";
import {Signup} from "../../../feature/public/signup/signup.model";
import {UserListRes} from "../../models/user-list.res";
import {OrgSignup} from "../../../feature/public/signup/org.signup.model";
import {OrganizationTeamRes} from "../../models/organization-team.res";
import {OrganizationTeamListRes} from "../../models/organization-team-list.res";
import {UtilsService} from "../utils.service";


@Injectable()
export class ApiUserService {
  constructor(private httpClient: HttpClient,private utils:UtilsService) {

    console.log('in the  api user service')
  }
  

   httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
   };

  signUp(user:Signup):Observable<SignupRes> {
    return this.httpClient.post<SignupRes>(`${environment.api_url}/api/user/signup`,user,this.httpOptions).pipe(
      tap((res:  SignupRes ) => {
      })
    );
  }

  signIn(parti_id):Observable<SigninRes>{
    return this.httpClient.post<SigninRes>(`${environment.api_url}/api/user/signin`,{organization_id:parti_id},this.utils.getAuthHeader());
  }

  logIn(token_obj){
    return this.httpClient.post<SigninRes>(`${environment.api_url}/api/user/login`,token_obj,this.utils.getAuthEmailHeader(token_obj.email));
  }
  
  updateToken(token_obj){
    return this.httpClient.post<SigninRes>(`${environment.api_url}/api/user/update-token`,token_obj,this.utils.getAuthEmailHeader(token_obj.email));
  }
  
  checkValidToken(token){
    return this.httpClient.post<SigninRes>(`${environment.api_url}/api/user/check-token`,{token},this.utils.getAuthHeader());
  }

  signInAsSuperAdmin():Observable<SigninRes>{
    return this.httpClient.post<SigninRes>(`${environment.api_url}/api/user/signin-as-super-admin`,{},this.utils.getAuthHeader());
  }

  getUserOrganization(email=""):Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/user/organization`,this.utils.getAuthHeader());
  }

  sendInvite(email,role):Observable<SignupRes>{
    return this.httpClient.post<SignupRes>(`${environment.api_url}/api/user/invite`,{email,role},this.utils.getAuthHeader());
  }

  addOrganization(email,org:OrgSignup):Observable<SignupRes>{
    return this.httpClient.post<SignupRes>(`${environment.api_url}/api/organization`,org,this.utils.getAuthEmailHeader(email));
  }

  checkInviteCode(email,token,invite_code):Observable<SignupRes>{
    return this.httpClient.post<SignupRes>(`${environment.api_url}/api/user/checkinvite`,{email,token,invite_code},this.httpOptions);
  }

  acceptInvite(email,invite_id):Observable<SignupRes>{
    return this.httpClient.post<SignupRes>(`${environment.api_url}/api/user/acceptinvite`,{email,invite_id},this.utils.getAuthEmailHeader(email));
  }
  
  declineInvite(email,invite_id):Observable<SignupRes>{
    return this.httpClient.post<SignupRes>(`${environment.api_url}/api/user/decline-invite`,{email,invite_id},this.utils.getAuthEmailHeader(email));
  }

  getUsers():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/user`, this.utils.getAuthHeader());
  }
  filterUsers(filter):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/user/filter`,filter, this.utils.getAuthHeader());
  }

  addTeam(name):Observable<OrganizationTeamRes> {
    return this.httpClient.post<OrganizationTeamRes>(`${environment.api_url}/api/organization/team`,{name}, this.utils.getAuthHeader());
  }
  editTeam(id,name):Observable<any> {
    return this.httpClient.put<any>(`${environment.api_url}/api/organization/team/${id}`,{name}, this.utils.getAuthHeader());
  }

  deleteTeam(id):Observable<any> {
    return this.httpClient.delete<any>(`${environment.api_url}/api/organization/team/${id}`, this.utils.getAuthHeader());
  }
  getTeam():Observable<OrganizationTeamListRes> {
    return this.httpClient.get<OrganizationTeamListRes>(`${environment.api_url}/api/organization/team`, this.utils.getAuthHeader());
  }
  saveUserTeam(user_id,teams):Observable<any> {
    return this.httpClient.put<any>(`${environment.api_url}/api/organization/team`,{user_id,teams}, this.utils.getAuthHeader());
  }

  saveUser(user):Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/user/profile`, user,this.utils.getAuthHeader());
  }

  getTicketPrefill():Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/user/ticket-prefill`,this.utils.getAuthHeader());
  }

  saveSecurity(user):Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/user/security`, user,this.utils.getAuthHeader());
  }


  saveOrganization(organization):Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/organization`, organization,this.utils.getAuthHeader());
  }

  saveCredit(stripe_tok):Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/user/savecredit`, stripe_tok,this.utils.getAuthHeader());
  }

  addCredit(obj={}):Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/user/credit`, obj,this.utils.getAuthHeader());
  }
  deleteCredit(id):Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/user/credit/${id}`,this.utils.getAuthHeader());
  }
  defaultCredit(id):Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/user/default-credit/${id}`,{},this.utils.getAuthHeader());
  }

  addCreditMonthly(obj):Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/user/addcreditmonthty`, obj,this.utils.getAuthHeader());
  }

  getCreditMonthly():Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/user/addcreditmonthty`,this.utils.getAuthHeader());
  }


  getPayments():Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/user/payments`,this.utils.getAuthHeader());
  }

  deleteUser(id):Observable<any> {
    return this.httpClient.delete<any>(`${environment.api_url}/api/user/${id}`, this.utils.getAuthHeader());
  }
  addUser(obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/user`,obj, this.utils.getAuthHeader());
  }
  approveAgentRequest(obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/user/approve-agent`,obj, this.utils.getAuthHeader());
  }
  getIntegration():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/user/integration`, this.utils.getAuthHeader());
  }
  saveIntegration(obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/user/integration`,obj, this.utils.getAuthHeader());
  }
  removeIntegration(partner):Observable<any> {
    return this.httpClient.delete<any>(`${environment.api_url}/api/user/integration/${partner}`, this.utils.getAuthHeader());
  }
  getItem(id):Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/user/${id}`, this.utils.getAuthHeader());
  }
  saveItem(id,obj):Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/user/${id}`,obj, this.utils.getAuthHeader());
  }
  addTeamAndAgent(obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/user/team-user`,obj, this.utils.getAuthHeader());
  }
  resendInviteEmail(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/user/resend-invite-email/${id}`, this.utils.getAuthHeader());
  }

  getFilterCategories(){
    return this.httpClient.get<any>(`${environment.api_url}/api/user/page-option`, this.utils.getAuthHeader());
  }
  logoutUser(){
    return this.httpClient.get<any>(`${environment.api_url}/api/user/logout`, this.utils.getAuthHeader());
  }

  getApiKey(){
    return this.httpClient.get<any>(`${environment.api_url}/api/user/api-key`, this.utils.getAuthHeader());
  }
  renewApiKey(){
    return this.httpClient.post<any>(`${environment.api_url}/api/user/api-key` ,{}, this.utils.getAuthHeader());
  }
  deleteApiKey(){
    return this.httpClient.delete<any>(`${environment.api_url}/api/user/api-key`, this.utils.getAuthHeader());
  }
  
  getUserIp():Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/user/get-ip`,this.utils.getAuthHeader());
  }  
  forgetDevice(username,deviceKey):Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/user/forget-device`,{
      deviceKey,
      username
    },this.utils.getAuthHeader());
  } 
  
  sendChangePasswordEmail():Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/user/send-change-password-email`,this.utils.getAuthHeader());
  }  
  sendOwnerPromotionCode():Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/user/send-owner-promotion-code`,this.utils.getAuthHeader());
  }  
  verifyOwnerPromotionCode(code,user_id):Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/user/verify-owner-promotion-code`,{code,user_id},this.utils.getAuthHeader());
  }  
}



