import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgxMaskModule } from 'ngx-mask';
import { CoreModule } from 'src/app/core/core.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ConfigOrganizationComponent } from './components/config-organization/config-organization.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgSelectModule } from '@ng-select/ng-select';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { UserProfileComponent } from './components/user-profile.component';
import { DashboardCampaignComponent } from './components/dashboard-campaign/dashboard-campaign.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {RouterModule} from "@angular/router";
import {HttpClientModule} from "@angular/common/http";
import { PublicComponent } from './layouts/public/public.component';
import { AdminComponent } from './layouts/admin/admin.component';
import {TranslateModule} from "@ngx-translate/core";
import { SubMenuComponent } from './layouts/sub-menu/sub-menu.component';
import {FormsModule} from "@angular/forms";
import { PageHeaderComponent } from './layouts/page-header/page-header.component';
import { MainMenuComponent } from './layouts/main-menu/main-menu.component';
import { PaginationComponent } from './layouts/pagination/pagination.component';
import { NgbDropdown, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NgxPaginationModule} from "ngx-pagination";
import { TableHeaderComponent } from './layouts/table-header/table-header.component';
import {NgSelect2Module} from "ng-select2";
import {ActionDropdownComponent} from "./layouts/action-dropdown/action-dropdown.component";
import { TimerCounterComponent } from './components/timer-counter/timer-counter.component';
import {NgbDatepickerModule, NgbDropdownModule, NgbTimepickerModule} from "@ng-bootstrap/ng-bootstrap";
import { ContactColumnMappingComponent } from './components/contact-column-mapping/contact-column-mapping.component';
import { ScriptEditorComponent } from './components/script-editor/script-editor.component';
import {QuillModule} from "ngx-quill";
import { NgDragDropModule } from 'ng-drag-drop';
import { UserComponent } from './layouts/user/user.component';
import { UserMenuComponent } from './layouts/user-menu/user-menu.component';
import { QuickSearchComponent } from './layouts/quick-search/quick-search.component';
import { UserSubMenuComponent } from './layouts/user-sub-menu/user-sub-menu.component';
import { TimerComponent } from './components/timer/timer.component';
import { CustomColumnComponent } from './components/custom-column/custom-column.component';
import { BeeEditorComponent } from './components/bee-editor/bee-editor.component';
import { TextEditorComponent } from './crm-components/text-editor/text-editor.component';
import { PageHeaderComponent as CrmPageHeaderComponent} from './crm-components/page-header/page-header.component'
import { AutocompleteComponent } from './crm-components/autocomplete/autocomplete.component';
import { BoldTextMatchPipe } from '../core/pipe/bold-text-match.pipe';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { OptionsDropDownComponent } from './crm-components/options-dropdown/options-dropdown.component';
import { IconModule } from '@visurel/iconify-angular';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'

import { SafeHtmlPipe } from 'src/app/core/pipe/safe-html.pipe'

import { TextMaskModule } from 'angular2-text-mask';
import { NgApexchartsModule } from 'ng-apexcharts';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { DashboardAgentComponent } from './components/dashboard-agent/dashboard-agent.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ShortcutsComponent } from './crm-components/shortcuts/shortcuts.component';
import { DesignComponent } from './layouts/design/design.component';
import { DesignSubMenuComponent } from './layouts/design-sub-menu/design-sub-menu.component';
import { MaterialModule } from '../material/material.module';
import { ZoomToolComponent } from './components/zoom-tool/zoom-tool.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { MenuComponent } from './crm-components/menu/menu.component';
import { CodeCurlyComponent } from './code-curly/code-curly.component';
import { RemoveIconComponent } from './remove-icon/remove-icon.component';
import { UsersModalComponent } from './users-modal/users-modal.component';
import { GlobalSearchComponent } from './crm-components/global-search/global-search.component';
import { RightPageSidebarComponent } from './layouts/right-page-sidebar/right-page-sidebar.component';
import { NotificationMenuHeaderComponent } from './layouts/notification-menu-header/notification-menu-header.component';
import { RightSidebarMenuHeaderComponent } from './layouts/right-sidebar-menu-header/right-sidebar-menu-header.component';
import { NotificationItemComponent } from './layouts/notification-item/notification-item.component';
import { TabComponent } from './components/tab/tab.component';
import { TaskItemComponent } from './layouts/task-item/task-item.component';
import { RecentNotificationSummaryComponent } from './layouts/recent-notification-summary/recent-notification-summary.component';
import { TicketItemComponent } from './crm-components/ticket-item/ticket-item.component';
import { ApiElectorsService } from 'src/app/core/services/crm_api/api-electors.service';
import { FieldsListSelectorComponent } from './crm-components/fields-list-selector/fields-list-selector.component';
import { AddEmailTemplateComponent } from './components/add-email-template/add-email-template.component';
import { AddSmsTemplateComponent } from './components/add-sms-template/add-sms-template.component';
import { DeleteButtonComponent } from './components/delete-button/delete-button.component';
import { IconifyComponent } from './components/iconify/iconify.component';
import { DatetimePickerComponent } from './crm-components/datetime-picker/datetime-picker.component';
import { MomentPipe } from '../core/pipe/moment-pipe';
import { CurrencyInputComponent } from './crm-components/currency-input/currency-input.component';
import { CommonSelectComponent } from './components/common-select/common-select.component';
import { TechSupportModalBodyComponent } from './components/tech-support-modal-body/tech-support-modal-body.component';
import { CopyBtnComponent } from './components/copy-btn/copy-btn.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CallOngoingPopupComponent } from './components/call-ongoing-popup/call-ongoing-popup.component';
import { AdminSearchBarComponent } from './crm-components/admin-search-bar/admin-search-bar.component';
import { AdminFilterHeaderComponent } from './crm-components/admin-filter-header/admin-filter-header.component';
import { AdminQuickFilterFormComponent } from './crm-components/admin-quick-filter-form/admin-quick-filter-form.component';
import { AdminItemsListComponent } from './crm-components/admin-items-list/admin-items-list.component';
import { ApiCommunicationService } from '../core/services/crm_api/api-communication.service';
import { ApiCrmFileService } from '../core/services/crm_api/api-file.service';
import { AdminExportModalComponent } from './crm-components/admin-export-modal/admin-export-modal.component';
import { ApiTagService } from '../core/services/crm_api/api-tag.service';
import { ApiRoleService } from '../core/services/crm_api/api-role.service';
import { TableHeaderFilterComponent } from './components/table-header-filter/table-header-filter.component';
import { AdminListItemLoadingComponent } from './crm-components/admin-list-item-loading/admin-list-item-loading.component';
import { ListItemLoadingComponent } from './components/list-item-loading/list-item-loading.component';
import { EnumerationOverflowPipe } from '../core/pipe/enumeration-overflow.pipe';
import { ApiCustomColumnService } from '../core/services/crm_api/api-custom-columns.service';

@NgModule({
  providers: [
    ApiElectorsService,
    DatePipe,
    ApiCommunicationService,
    ApiCrmFileService,
    ApiTagService,
    ApiCustomColumnService,
    ApiRoleService
  ],
    declarations: [PublicComponent, AdminComponent, SubMenuComponent, PageHeaderComponent, MainMenuComponent, PaginationComponent, TableHeaderComponent, ActionDropdownComponent, TimerCounterComponent, DateTimePickerComponent, ContactColumnMappingComponent,
        ScriptEditorComponent,
        UserComponent,
        EnumerationOverflowPipe,
        ListItemLoadingComponent,
        UserMenuComponent,
        QuickSearchComponent,
        UserSubMenuComponent,
        TimerComponent,
        BoldTextMatchPipe,
        TicketItemComponent,
        TextEditorComponent,  AutocompleteComponent, OptionsDropDownComponent, FileViewerComponent, SafeHtmlPipe,
        CustomColumnComponent, BeeEditorComponent,DashboardCampaignComponent,UserProfileComponent,ConfigOrganizationComponent,DashboardAgentComponent, ShortcutsComponent,DesignComponent,DesignSubMenuComponent,ModalDialogComponent,
        TextEditorComponent,FileViewerComponent, 
        CrmPageHeaderComponent,AutocompleteComponent,OptionsDropDownComponent,ShortcutsComponent,
        CustomColumnComponent, BeeEditorComponent,DashboardCampaignComponent,UserProfileComponent,ConfigOrganizationComponent,DashboardAgentComponent, DesignComponent,DesignSubMenuComponent, ZoomToolComponent, SkeletonLoaderComponent, MenuComponent, CodeCurlyComponent, RemoveIconComponent, UsersModalComponent, GlobalSearchComponent,
        RightPageSidebarComponent,NotificationMenuHeaderComponent,RightSidebarMenuHeaderComponent,NotificationItemComponent, TabComponent,TaskItemComponent, RecentNotificationSummaryComponent, FieldsListSelectorComponent,AddEmailTemplateComponent, AddSmsTemplateComponent, DeleteButtonComponent, IconifyComponent, DatetimePickerComponent,
        MomentPipe,
        CurrencyInputComponent,
        CommonSelectComponent,
        TechSupportModalBodyComponent,
        CopyBtnComponent,
        CallOngoingPopupComponent,
        AdminSearchBarComponent,
        AdminFilterHeaderComponent,
        AdminQuickFilterFormComponent,
        AdminItemsListComponent,
        AdminExportModalComponent,
        AdminListItemLoadingComponent,
        TableHeaderFilterComponent],
  exports: [
    TableHeaderFilterComponent,
    EnumerationOverflowPipe,
    ListItemLoadingComponent,
    PageHeaderComponent,
    MainMenuComponent,
    CallOngoingPopupComponent,
    SubMenuComponent,
    PaginationComponent,
    TableHeaderComponent,
    DeleteButtonComponent,
    ActionDropdownComponent,
    TimerCounterComponent,
    DateTimePickerComponent,
    ContactColumnMappingComponent,
    ScriptEditorComponent,
    UserMenuComponent,
    UserSubMenuComponent,
    TimerComponent,
    CustomColumnComponent,
    BeeEditorComponent,
    TextEditorComponent,
    AutocompleteComponent,
    OptionsDropDownComponent,
    FileViewerComponent,
    DashboardCampaignComponent,
    DashboardAgentComponent,
    UserProfileComponent,
    ConfigOrganizationComponent,
    CodeCurlyComponent,
    ShortcutsComponent,
    DesignSubMenuComponent,
    ModalDialogComponent,
    MaterialModule,
    MatProgressSpinnerModule,
    CrmPageHeaderComponent,
    SkeletonLoaderComponent,
    MenuComponent,
    ZoomToolComponent,
    CommonSelectComponent,
    UsersModalComponent,
    RightPageSidebarComponent,
    NotificationMenuHeaderComponent,
    NotificationItemComponent,
    TabComponent,
    TaskItemComponent,
    RecentNotificationSummaryComponent,
    SafeHtmlPipe,
    TicketItemComponent,
    GlobalSearchComponent,
    BoldTextMatchPipe,
    FieldsListSelectorComponent,
    AddEmailTemplateComponent,
    IconifyComponent,
    DatetimePickerComponent,
    MomentPipe,
    CommonModule,
    FormsModule,
    CurrencyInputComponent,
    TechSupportModalBodyComponent,
    CopyBtnComponent,
    AdminSearchBarComponent,
    AdminFilterHeaderComponent,
    AdminQuickFilterFormComponent,
    AdminItemsListComponent,
    AdminListItemLoadingComponent,
    QuillModule
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    CoreModule,
    FormsModule,
    TranslateModule,
    NgxPaginationModule,
    NgSelect2Module,
    NgSelectModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    QuillModule,
    NgDragDropModule.forRoot(),
    GooglePlaceModule,
    IconModule,
    NgbModule,
    NgSelectModule,
    TextMaskModule,
    NgxMaskModule,
    NgApexchartsModule,
    ImageCropperModule,
    NgCircleProgressModule,
    ColorPickerModule,
    GooglePlaceModule,
    NgxMaskModule,
    PickerModule,
    MaterialModule,
    DragDropModule,
    LeafletDrawModule,
    LeafletModule,
    MatProgressSpinnerModule,
    ClipboardModule
  ]
})
export class SharedModule { }
