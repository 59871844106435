import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ApiUserService } from './../../../core/services/api/api-user.service';
import { Component, OnInit } from '@angular/core';
import {UserModel} from "../../../core/models/user.model";
import {AuthService} from "../../../core/services/auth.service";
import {SessionStorageService} from "../../../core/services/session.storage.service";
import Pusher from 'pusher-js';
import { Router } from '@angular/router';
import { SigninRes } from 'src/app/core/models/signin.res';
import { ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {

  @ViewChild('viewTechnicalModal') viewTechnicalModal:NgbModal

  user:UserModel
  organization:any
  logged:any
  bgColor

  hasMultiParti = false

  ipAddress
  pusher:Pusher
  isElected = false

  isFederal=false

  sub_type=""
  level = ""

  agentData:any={}

  items

  constructor(
    private authService:AuthService,
    private apiUserService:ApiUserService,
    private translate:TranslateService,
    private modalService:NgbModal,
    private sessionStorageService:SessionStorageService,
    public router: Router,
    private utils:UtilsService,
    private httpClient:HttpClient
  ) {  }

  ngOnInit(): void {
    this.pusher =new Pusher("eb26f38d9c6c914e2b74", {
      cluster: 'us2',
      forceTLS: true
    });
    this.logged = this.sessionStorageService.getLoggedUser()

    console.log(`this.logged`,this.logged)

    const parties = this.sessionStorageService.get('parties')
    if(parties && parties.length > 1){
      this.hasMultiParti = true
    }

    if(this.logged.organization.sub_type=="elected"){
      this.isElected = true

      if(this.logged.organization.level=="federal"){
        this.isFederal = true
      } 
    }

    this.sub_type = this.logged.organization.sub_type
    this.level  = this.logged.organization.level
    if(this.sub_type=="leadership" && this.level == "federal"){
      this.isElected = true
    }
    

    this.getIPAddress()

    if(this.logged === undefined){
      this.router.navigateByUrl('/')
    }else{
      this.user = this.logged.user
      this.logged = this.logged
      this.organization = this.logged.organization
      this.bgColor = this.logged.organization.color_primary

      let name = `owner-message`
      const channel = this.pusher.subscribe(name);
      channel.bind('credit-change',(data,metadata)=>{
        // console.log(data)
        if(this.logged.organization_id ===data.organization_id){
          this.logged.organization.credit = data.credit
        }
      })


      channel.bind('support-request',(data,metadata)=>{
        if(this.logged.organization_id === data.organization_id && this.logged.user_id == data.request_user){
          this.agentData = data
          this.openTechnicalModal();
        }
      })
      
      channel.bind('logout',(data,metadata)=>{
        if(this.logged.organization_id === data.organization_id && this.logged.user_id ===data.user_id){
          console.log('logout')
          setTimeout(()=>{
            this.authService.logout();
          },500)
        }
      })
    }

  }

  filterItems(logged){
    let permissions = logged.role.permissions;
    if(permissions.elector.view.status != 'allowed'){
      this.items.splice(this.items.findIndex((item) => item.key == '/admin/crm/electors'), 1)
    }
    if(permissions.contact.view.status != 'allowed'){
      this.items.splice(this.items.findIndex((item) => item.key == '/admin/crm/contacts'), 1)
    }
    if(permissions.organization.view.status != 'allowed'){
      this.items.splice(this.items.findIndex((item) => item.key == '/admin/crm/organizations'), 1)
    }
    if(permissions.ticket.view.status != 'allowed'){
      this.items.splice(this.items.findIndex((item) => item.key == '/admin/crm/tickets'), 1)
    }
    if(permissions.payment.view.status != 'allowed'){
      this.items.splice(this.items.findIndex((item) => item.key == '/admin/crm/subscription-payment/payments'), 1)
    }
    if(permissions.tag.view.status != 'allowed'){
      this.items.splice(this.items.findIndex((item) => item.key == '/admin/crm/tags'), 1)
    }
    if(permissions.import.view.status != 'allowed'){
      this.items.splice(this.items.findIndex((item) => item.key == '/admin/crm/imports'), 1)
    }
    if(permissions.email_campaign.view.status != 'allowed'){
      this.items.splice(this.items.findIndex((item) => item.key == '/admin/email-campaign'), 1)
    }
    if(permissions.sms_campaign.view.status != 'allowed'){
      this.items.splice(this.items.findIndex((item) => item.key == '/admin/sms-campaign'), 1)
    }
  }

  onLogout() {
    this.authService.logout();
  }

  initItems(){
    this.items = [
      {
        key: "/admin/crm/dashboard",
        name: "Dashboard",
        icon: "fa fa-home",
        shown: this.organization.module==='crm'
      },
      {
        key: "/admin/crm/electors",
        name: "Electors",
        icon: "fas fa-user elector-icon position-relative",
        icon2: "fas fa-vote-yea ballot-icon",
        shown: this.organization.module==='crm' && this.isElected == false
      },
      {
        key: "/admin/crm/elected",
        name: "Elected",
        icon: "fas fa-user elector-icon position-relative",
        icon2: "fas fa-vote-yea ballot-icon",
        shown: this.organization.module==='crm' && this.isElected == true
      },
      {
        key: "/admin/crm/elections",
        name: "Elections",
        icon: "fas fa-user elector-icon position-relative",
        icon2: "fas fa-vote-yea ballot-icon",
        shown: this.organization.module==='crm' && this.isElected == false
      },
      {
        key: "/admin/crm/score-statistic",
        name: "Score And Statistic",
        icon: "fa-solid fa-chart-simple",
        shown: this.organization.module==='crm'  && this.isElected == false
      },
      {
        key: "/admin/crm/contacts",
        name: "Contacts",
        icon: "far fa-address-book",
        shown: this.organization.module==='crm'
      },
      {
        key: "/admin/crm/organizations",
        name: "Organizations",
        icon: "fas fa-users",
        shown: this.organization.module==='crm'
      },
      {
        key: "/admin/crm/tickets",
        name: "Dossiers",
        icon: "fas fa-clipboard-check",
        shown: this.organization.module==='crm'
      },
      {
        key: "/admin/crm/tasks",
        name: "Tasks",
        icon: "fas fa-clipboard-list",
        shown: this.organization.module==='crm'
      },
      {
        key: "/admin/crm/tags",
        name: "Tags",
        icon: "fas fa-tags",
        shown: this.organization.module==='crm'
      },
      {
        key: "/admin/crm/subscription-payment/payments",
        name: "Payments And Subscription",
        icon: "fas fa-money-check",
        shown: this.organization.module==='crm' && this.isElected == false
      },
      {
        key: "/admin/crm/bingo/list",
        name: "Bingo",
        icon: "far fa-dot-circle",
        shown: this.organization.module==='crm' && this.isElected == false
      },
      {
        key: "/admin/crm/communications",
        name: "Communications",
        icon: "fas fa-comments",
        shown: this.organization.module==='crm'  && this.isElected == false
      },
      {
        key: "/admin/crm/imports",
        name: "Imports",
        icon: "fas fa-file-import",
        shown: this.organization.module==='crm' && this.isElected == false
      },
      {
        key: "/admin/crm/approval-rejection",
        name: "Approval And Rejection",
        icon: "fas fa-check-square",
        shown: this.organization.module==='crm'  && this.isElected == false
      },
      {
        key: "/admin/email-campaign",
        name: "Email campaign",
        icon: "fas fa-envelope",
        shown: true  && this.isElected == false
      },
      {
        key: "/admin/sms-campaign",
        name: "Sms campaign",
        icon: "fas fa-mobile",
        shown: true  && this.isElected == false
      },
      {
        key: "/admin/form-builder",
        name: "Form builder",
        icon: "fas fa-code",
        shown: this.ipAddress==='52.60.73.78' || this.ipAddress==='127.0.0.1'
      },
      {
        key: "/admin/journey-builder",
        name: "Journey builder",
        icon: "fas fa-bars-progress",
        shown: this.ipAddress==='52.60.73.78' || this.ipAddress==='127.0.0.1'
      },
      {
        key: "/admin/settings",
        name: "Settings",
        icon: "fa fa-cog",
        shown: true
      }
    ]

    this.filterItems(this.logged)
  }

  getIPAddress()
  {
    this.apiUserService.getUserIp().subscribe(res=>{
      if(res.status === "OK"){
        this.ipAddress = res.data.clientIp
        if(this.ipAddress == "::1"){
          this.ipAddress = "127.0.0.1"
        }
        
      }
      this.initItems()
    })
  }
  onChangeParti(){
    this.router.navigateByUrl("/signin/parties")
  }

  openTechnicalModal(){
    this.modalService.open(this.viewTechnicalModal,{windowClass: 'tech-support-modal'})
  }
  closeSupportForm(){
    this.modalService.dismissAll()
  }
}
