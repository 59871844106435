import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiCampaignService {
  api_name = "campaign"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {
  }
  filter(filter={}):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`,filter, this.utils.getAuthHeader())
  }
  get(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/${id}`, this.utils.getAuthHeader())
  }
  delete(ids): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/${ids}`, this.utils.getAuthHeader())
  }

  getOptions():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/options`, this.utils.getAuthHeader())
  }
  
  create(obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}`,obj, this.utils.getAuthHeader())
  }
  edit(id,obj): Observable<any>{
    return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/${id}`,obj, this.utils.getAuthHeader())
  }
  
}



