<div class="new-loading-container">
    <!-- Toggle & hide left Sidebar -->
    <div *ngIf="isShowSidebar" id="sidebar" class="page-sidebar">
      <app-main-menu></app-main-menu>
      <!-- Sub menu Side bar -->
      <div id="submenu" class="sidebar-submenu hidden w-100" style="transition: all 2s ease;">
          <div id="subHeader" style="padding: 28px 28px;" class="submenu-header transitionAnimation">

          </div>
          <div id="subItems" class="submenu-items transitionAnimation">
            <section>
              <div *ngFor="let item of [1,2]">
                <app-skeleton-loader [Cwidth]="267"></app-skeleton-loader>
              </div>
            </section>
            <section class="mt-4">
              <div *ngFor="let item of [1,2,3,4]" class="side-section-item">
                <div>
                  <app-skeleton-loader [Cwidth]="286"></app-skeleton-loader>
                </div>
                <div class="d-flex justify-content-between" style="flex-grow: 1;">
                  <div>
                    <app-skeleton-loader [Cwidth]="58"></app-skeleton-loader>
                  </div>
                  <div>
                    <app-skeleton-loader [Cwidth]="58"></app-skeleton-loader>
                  </div>
                </div>
                <div>
                  <app-skeleton-loader [Cwidth]="286"></app-skeleton-loader>
                </div>
              </div>
            </section>
            <section style="margin-top: 70px !important;">
              <div *ngFor="let item of [1,2]">
                <app-skeleton-loader [Cwidth]="267"></app-skeleton-loader>
              </div>
            </section>
            <section class="sidebar-footer-section">
              <div style="padding: 10px;margin-bottom: 70px;">
                <div class="d-flex justify-content-between" style="flex-grow: 1;">
                  <div>
                    <app-skeleton-loader [Cwidth]="212"></app-skeleton-loader>
                  </div>
                  <div>
                    <app-skeleton-loader [Cwidth]="14"></app-skeleton-loader>
                  </div>
                </div>
                <div>
                  <app-skeleton-loader [Cwidth]="83" [center]="true"></app-skeleton-loader>
                </div>
              </div>
              <div class="footer-bar-section row">
                <div class="col-md-6 text-center" style="border-right: 1px solid #EEEEEE;">
                  <div style="margin-bottom: 10px;">
                    <app-skeleton-loader [Cwidth]="43" [center]="true"></app-skeleton-loader>
                  </div>
                  <div>
                    <app-skeleton-loader [Cwidth]="105" [center]="true"></app-skeleton-loader>
                  </div>
                </div>
                <div class="col-md-6 text-center">
                  <div style="margin-bottom: 10px;">
                    <app-skeleton-loader [Cwidth]="43" [center]="true"></app-skeleton-loader>
                  </div>
                  <div>
                    <app-skeleton-loader [Cwidth]="105" [center]="true"></app-skeleton-loader>
                  </div>
                </div>
              </div>
            </section>
          </div>

      </div>

  </div>
  <!-- Main page content -->
  <div [ngStyle]="{'z-index': '1'}" [class]="isShowSidebar ? 'page-container' : 'page-container pl-0 pr-0'">
      <div *ngIf="isShowSidebar" style="height: 81px;" class="d-flex align-items-center">
          <app-crm-page-header
          style="width: 100%"
          #pageHeader
          [title]="titleName | translate | titlecase"
          [headerNavButtons]="headerNavButtons"
          [itemType]="'communication'"
          [itemName]="itemName"
          [mainService]="mainService"
          >
          </app-crm-page-header>
      </div>

      <div #pageBody class="page-body">
        <div style="z-index: 1" class="page-content">
          <section class="main-content-header">
            <div class="d-flex flex-wrap justify-content-between" style="flex-grow: 1;">
              <div *ngFor="let item of [1,2,3]" class="d-flex" style="gap: 40px;">
                <div>
                  <app-skeleton-loader [Cwidth]="93"></app-skeleton-loader>
                </div>
                <div>
                  <app-skeleton-loader [Cwidth]="93"></app-skeleton-loader>
                </div>
              </div>
            </div>
          </section>
          <section class="body-section">
            <div class="d-flex flex-column" style="gap: 15px;">
              <div *ngFor="let item of [1,2,3,4,5,6,7,8]" class="d-flex flex-wrap body-section-item justify-content-between align-content-center">
                <div>
                  <app-skeleton-loader *ngFor="let item of [1,2]" [Cwidth]="121"></app-skeleton-loader>
                </div>
                <div>
                  <div class="d-flex justify-content-between">
                    <div>
                      <app-skeleton-loader [Cwidth]="60"></app-skeleton-loader>
                    </div>
                    <div>
                      <app-skeleton-loader [Cwidth]="60"></app-skeleton-loader>
                    </div>
                  </div>
                  <div>
                    <app-skeleton-loader [Cwidth]="277"></app-skeleton-loader>
                  </div>
                </div>
                <div class="align-self-center">
                  <app-skeleton-loader [Cwidth]="68"></app-skeleton-loader>
                </div>
                <div>
                  <app-skeleton-loader [Cwidth]="171" [Cheight]="34"></app-skeleton-loader>
                </div>
                <div>
                  <app-skeleton-loader [Cwidth]="171" [Cheight]="34"></app-skeleton-loader>
                </div>
                <div class="align-self-center">
                  <app-skeleton-loader [Cwidth]="68"></app-skeleton-loader>
                </div>
                <div>
                  <app-skeleton-loader [Cwidth]="34" [Cheight]="34"></app-skeleton-loader>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
  </div>
</div>
