
<div class="full-height" id="admin-container" [ngClass]="{'show_config_bar':false, 'show_right_side_bar':showRightSidebar}" >
  <!-- showConfig=== -->
  <div class="config-bar  btn-warning"  *ngIf="false"  > 
    <a (click)="openConfigModal()" class="btn">{{"Config your organization"|translate}} ({{this.percent_completed}}% )</a>
    <a class="fa fa-times close-config" (click)="closeConfig()"></a>
  </div>

  <router-outlet></router-outlet>
  <!-- <div class="loading-container">
    <div class="loading-content">
      <i class="fa fa-shield-alt"></i>\
    </div>
  </div> -->

  <ng-template #viewConfigModal let-modal>
    <app-config-organization (closeModal)="closeModal()" ></app-config-organization>
  </ng-template>


</div>
<div class="page-sidebar  page-right-sidebar-menu transition-animation">
  <app-right-page-sidebar></app-right-page-sidebar>
</div>
