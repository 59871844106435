import { SessionStorageService } from 'src/app/core/services/session.storage.service';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const I18N_VALUES = {
	fr: {
		weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
		months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
		weekLabel: 'sem',
	},
    en: {
		weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
		months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		weekLabel: 'we',
	}
	// other languages you would support
};


// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
    constructor(private sessionStorageService:SessionStorageService) {
        super();
    }
	getWeekdayLabel(weekday: number): string {
        // console.log(this.sessionStorageService.get('language'))
		return I18N_VALUES[this.sessionStorageService.get('language')?this.sessionStorageService.get('language'):'en'].weekdays[weekday - 1];
	}
    getWeekdayShortName(weekday: number): string {
        return I18N_VALUES[this.sessionStorageService.get('language')?this.sessionStorageService.get('language'):'en'].weekdays[weekday - 1];
    }
	getWeekLabel(): string {
		return I18N_VALUES[this.sessionStorageService.get('language')?this.sessionStorageService.get('language'):'en'].weekLabel;
	}
	getMonthShortName(month: number): string {
        return I18N_VALUES[this.sessionStorageService.get('language')?this.sessionStorageService.get('language'):'en'].months[month - 1];
	}
	getMonthFullName(month: number): string {
		return this.getMonthShortName(month);
	}
	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}-${date.month}-${date.year}`;
	}
}

// @Component({
// 	selector: 'ngbd-datepicker-i18n',
// 	standalone: true,
// 	imports: [NgbDatepickerModule, NgbAlertModule, FormsModule],
// 	templateUrl: './datepicker-i18n.html',
// 	providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }], // define custom NgbDatepickerI18n provider
// })
// export class NgbdDatepickerI18n {
// 	model: NgbDateStruct;
// }