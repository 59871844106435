import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import { SessionStorageService } from 'src/app/core/services/session.storage.service';
import { latLng, tileLayer } from 'leaflet';
import { UtilsService } from "src/app/core/services/utils.service";
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import Pusher from 'pusher-js'

import bxShapeCircle from '@iconify-icons/bx/bx-shape-circle';
import bxShapePolygon from '@iconify-icons/bx/bx-shape-polygon';
import multipleMarked from '@iconify-icons/ri/checkbox-multiple-fill'
import multipleBlankOutline from '@iconify-icons/ri/checkbox-multiple-blank-line'
import bigClose from '@iconify-icons/ci/close-big'
import shortcut from '@iconify-icons/fluent/arrow-turn-right-24-filled'
import map from '@iconify-icons/akar-icons/map'
import grid from '@iconify-icons/bi/grid-3x3';
import listAltSolid from '@iconify-icons/la/list-alt-solid';
import plus from '@iconify-icons/ci/plus'
import warning from '@iconify-icons/fluent/warning-24-filled'
import { Subject } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';

import usersFill from '@iconify-icons/ph/users-fill';
import peopleTeam24Filled from '@iconify-icons/fluent/people-team-24-filled';
import history from '@iconify-icons/material-symbols/history';

@Component({
  selector: 'app-crm-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent implements OnInit, OnDestroy{

  constructor(
    private modalService: NgbModal, 
    private sessionStorage: SessionStorageService, 
    private translate:TranslateService, 
    private utils:UtilsService, 
    private renderer: Renderer2,
    ) { }
  //ICONS
  multipleBlankOutlineIcon = multipleBlankOutline
  multipleMarkedIcon = multipleMarked
  bxShapeCircle = bxShapeCircle;
  bxShapePolygon = bxShapePolygon;
  bigCloseIcon = bigClose;
  shortcutIcon = shortcut
  listAltSolidIcon = listAltSolid
  gridIcon = grid
  mapIcon = map
  plusIcon = plus
  selectedRange = "YTD";
  rangeOptions = [
    
  ]
  warningIcon = warning
  options = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 5,
    center: latLng(46.879966, -121.726909)
  };

  @Input() currentSelectedView : string;
  @Input() title: string;
  @Input() headerTitleOptions;
  @Input() sub_title: string;
  @Input() sub_title_2: string;
  @Input() itemName: string;
  @Input() availableShortCuts
  @Input() mainService;
  @Input() headerNavButtons;
  @Input() itemType: string
  @Input() isTitleChoicelist: boolean
  @Input() isTitleSidebarOptions: boolean = false
  @Input() checkEmptyBoard: Subject<any>;
  @Input() showAddPipeline: boolean = true;


  @Input() userHeaderTitle = "Invite User";
  
  @Output() openTechModal = new EventEmitter<any>();
  @Output() openUserModal = new EventEmitter<any>();
  @Output() openTeamModal = new EventEmitter<any>();
  @Output() openRoleModal = new EventEmitter<any>();
  @Output() userTitleChange=new EventEmitter<any>();
 /*  Parameter if title is a choicelist 
     items: the choicelist options
    ngModel: the current value
     placeholder: the placeholder
 */
  @Input() choicelistOptions: any;
  @Input() showResult:boolean = true;
  @Output() titleClick=new EventEmitter<any>();
  @Output() subTitleClick=new EventEmitter<any>();
  @Output() searchKeyup = new EventEmitter<any>();
  @Output() searchEnter= new EventEmitter<any>();
  @Output() resultClickEvent = new EventEmitter<any>();
  @Output() globalSearchClose = new EventEmitter<any>();
  @Output() reloadOptions = new EventEmitter<any>();
  @Output() currentSelectedViewChanged = new EventEmitter<any>();
  @Output() titleChanged = new EventEmitter<any>();
  @Output() rangeOptionUpdate = new EventEmitter<any>();
  @Output() showDetails = new EventEmitter<any>();
  @Output() onBoardOptionClick = new EventEmitter<any>();
  @ViewChild('shortcutModal', { static: false }) shortcutModal
  @ViewChild('shortcutBtn', { static: false}) shortcutBtn
  @ViewChild('boardWarningPopover', { static: false }) boardWarningPopover


  usersIcon = usersFill
  teamIcon = peopleTeam24Filled
  listIcon= listAltSolid;
  historyIcon = history;

  pusher:Pusher
  pusherChannel
  showGlobalSearch: boolean=false
  showSearchForm: boolean = false

  list_ids = []

  dropListIds= []

  totalResults

  checkEmptyBoardSubscription

  uniqAddressCount
  uniqPhoneCount
  geoDataCount

  isUncertainPhoneCount
  isUncertainAddressCount

  searchValue

  isShownSearchModal = false
  logged
  bgColor

  ngOnInit(): void {
    if(this.checkEmptyBoard)
      this.checkEmptyBoardSubscription = this.checkEmptyBoard.subscribe(() => {
        if(this.choicelistOptions.ngModel == null){
          this.boardWarningPopover.open();
        }
      })
    this.logged = this.sessionStorage.getLoggedUser()
    this.logged.organization.color_primary ? this.bgColor = this.logged.organization.color_primary : this.bgColor = 'rgb(204, 204, 204)';
    this.rangeOptions=[{
      id: '1 weeks',
      name: `1 ${this.translate.instant('Week')}`
    },
    {
      id: '2 weeks',
      name: `2 ${this.translate.instant('Weeks')}`
    },
    {
      id: '1 months',
      name: `1 ${this.translate.instant('Month')}`
    },
    {
      id: '3 months',
      name: `3 ${this.translate.instant('Months')}`
    },
    {
      id: '1 years',
      name: `1 ${this.translate.instant('Year')}`
    },
    {
      id: 'YTD',
      name: this.translate.instant('This Year')
    }];
    this.pusher = new Pusher("eb26f38d9c6c914e2b74", {
      cluster: 'us2',
      forceTLS: true
    });
    if(this.itemType == 'contact' || this.itemType == 'organizations' || this.itemType == 'ticket'){
      this.pusherChannel = this.pusher.subscribe(`${this.logged.organization.id}-${this.itemType}`);
      this.pusherChannel.bind(this.itemType, (data) => {
        //Make sure that if it's a ticket, the user is inside the pipeline in which the ticket was/is located.
        if((!data.ticket_pipeline_id && (!data.body || !data.body.ticket_pipeline_id)) || ((data.ticket_pipeline_id && (data.ticket_pipeline_id == this.choicelistOptions.ngModel)) || this.choicelistOptions.ngModel == 0) || (data.body?.ticket_pipeline_id == this.choicelistOptions.ngModel || this.choicelistOptions.ngModel == 0)){
          switch(data.action){
            case 'add':
              this.totalResults++;
              if(data.body.phone || data.body.cellphone){
                this.isUncertainPhoneCount = true
              }
              if(data.body.civic || data.body.street || data.body.city){
                this.isUncertainAddressCount = true
              }
              break;
            case 'edit':
              if(data.body.replace && (data.body.replace.phone || data.body.replace.cellphone)){
                this.isUncertainPhoneCount = true
              }
              if(data.body.replace && (data.body.replace.civic || data.body.replace.street || data.body.replace.city)){
                this.isUncertainAddressCount = true
              }
              break;
            case 'delete':
              this.totalResults--;
              this.isUncertainPhoneCount = true
              this.isUncertainAddressCount = true
            break;
          }
        }
      })
    }


  }

  ngOnDestroy(): void {
    if(this.pusherChannel)
      this.pusherChannel.unbind(this.itemType)
    if(this.checkEmptyBoardSubscription){
      this.checkEmptyBoardSubscription.unsubscribe()
    }
  }


  openUserDialog() {
    if (this.userHeaderTitle == 'Create New Team') {
      this.openTeamModal.emit(true)
      //to do
    } else if (this.userHeaderTitle == 'Invite User') {
      this.openUserModal.emit(true)
    } else if(this.userHeaderTitle == 'Tech support'){
      this.openTechModal.emit(true);
    } else if (this.userHeaderTitle == 'Create new role') {
      this.openRoleModal.emit(true)
    }
  }

  showShortcutModal(){
    this.shortcutBtn.nativeElement.click()
    /* this.openModal(this.shortcutModal) */
  }

  openModal(modalRef){
    this.utils.playAudio('menu')
    this.modalService.open(modalRef, { size: 'xl', centered: true, windowClass: 'modal-class' })
  }

  //Toggle main menu 
  toggleExtented() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('extended');
  }

  //Close quick search
  closeGlobalSearch() {
    this.showGlobalSearch = false;
    this.globalSearchClose.emit();
  }

  //When clicking on search as you type result
  resultClick(result) {
    this.resultClickEvent.emit(result);
    this.closeGlobalSearch();
  }

  openGlobalSearch(inputElem){
    if(!this.showGlobalSearch)
      this.searchValue = ''
    this.showGlobalSearch = true;
    inputElem.focus();
  }
  
  onGlobalSearchSubmit(search, modalRef){
    this.searchValue = search
    this.modalService.open(modalRef, { size: 'xl', windowClass: 'global-search-modal'})
    this.showGlobalSearch = false;
  }

  loadResults(){
    this.totalResults = null;
    this.uniqAddressCount = null;
    this.uniqPhoneCount = null;
    this.geoDataCount = null;
  }

  setResults(event){
    this.isUncertainAddressCount = false;
    this.isUncertainPhoneCount = false;
    if(event.totalResults != null)
      this.totalResults = event.totalResults;
    if(event.uniqAddressCount != null)
      this.uniqAddressCount = event.uniqAddressCount;
    if(event.uniqPhoneCount != null)
      this.uniqPhoneCount = event.uniqPhoneCount;
    if(event.geoDataCount != null)
      this.geoDataCount = event.geoDataCount;
  }

  onChoicelistChanged(event){
    console.log(event)
    this.titleChanged.emit(event)
  }

  updateChoiceList(event,item,bookmarked,pop: NgSelectComponent){
    event.preventDefault();
    pop.closeOnSelect = false;
    this.mainService.updateBookmarked(item.id,bookmarked).subscribe(res => {
      if(res.status == "OK"){
        item.bookmarked = bookmarked;
        this.choicelistOptions.items = [...this.choicelistOptions.items];
        this.choicelistOptions.items = this.choicelistOptions.items.sort((a, b)=> {
          return b.bookmarked - a.bookmarked;
        })
      }else{
        this.utils.showNotify('error',this.translate.instant("Error"))
      }
    })
    

  }

  clickEventOnSubmenu(popover,item,event, pop: NgSelectComponent){
    event.preventDefault();
    //this.choicelistOptions.items = [...this.choicelistOptions.items];
    pop.closeOnSelect = false;
    if (popover.isOpen()) {
			popover.close();
		} else {
			popover.open({ item });
		}
  }

  clickBoardItem(type,item){
    // console.log(type,item)
    this.onBoardOptionClick.emit({type,item})
  }

}
