import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiOrganizationsService {
  api_name = "crm/contact";

  constructor( 
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {}

  filter(filter = {}): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter?is_organization=1`, JSON.stringify(filter), this.utils.getAuthHeader());
  }

  getCounts(filter = {}): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/count?is_organization=1`, JSON.stringify(filter), this.utils.getAuthHeader());
  }

  nationalities(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/nationalitylist`, this.utils.getAuthHeader());
  }

  export(data, responseType): Observable<any>{
    const options = {headers: this.utils.getAuthHeader().headers, responseType: responseType}
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/export?is_organization=1`, data, options);
  }

  add(data): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}?is_organization=1`, data, this.utils.getAuthHeader());
  }

  edit(id, data): Observable<any> {
    return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/${id}?is_organization=1`, data, this.utils.getAuthHeader());
  }

  checkDuplicate(data): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/duplicate?is_organization=1`, data, this.utils.getAuthHeader());
  }

  delete(ids): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/${ids}?is_organization=1`, this.utils.getAuthHeader());
  }

  get(id): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/:${id}?is_organization=1`, this.utils.getAuthHeader());
  }

  searchByName(search, limit=50): Observable<any>{
    const filter_obj = {
      filter: [
        {
          key: 'name',
          value: search,
          op: 'like'
        }
      ],
      fields: [
        {field:'id'}, {field:'name'}, {field: 'website'},{field:'phone'}, {field:'email'}, {field:'profile_image'}
      ],
      sort: 'id asc',
      start: 0,
      limit: limit
    }
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter?is_organization=1`, filter_obj, this.utils.getAuthHeader());
  }

  languages(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/languagelist`, this.utils.getAuthHeader());
  }

  zones(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/zonelist`, this.utils.getAuthHeader());
  }

  categories(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/categories?is_organization=1`, this.utils.getAuthHeader());
  }

  streets = ((query, limit=1000): Observable<any> => {
    return this.httpClient.get<any>(`${environment.api_url}/api/crm/contact/street/searchasyoutype/?search=${encodeURI(query)}&limit=${limit}&is_organization=1`, this.utils.getAuthHeader());
  })

  getOptions(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/crm/contact/options?is_organization=1`, this.utils.getAuthHeader());
  }

  updateOptions(options): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/crm/contact/options?is_organization=1`, options, this.utils.getAuthHeader());
  }

  getActivityLog(id: number, size: number, offset: number, sort: string): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/activity/:${id}/?size=${size}&offset=${offset}&sort=${sort}&is_organization=1`, this.utils.getAuthHeader())
  }

  getFieldData(body, is_create): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/fields?is_create=${is_create}&is_organization=1`, body, this.utils.getAuthHeader())
  }
}



