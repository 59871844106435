import { ApiUserService } from 'src/app/core/services/api/api-user.service';
import { Intercom } from 'ng-intercom';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location, registerLocaleData } from '@angular/common';

import { AuthService } from './core/services/auth.service';
import {SessionStorageService} from "./core/services/session.storage.service";
import {TranslateService} from "@ngx-translate/core";
import {UtilsService} from "./core/services/utils.service";

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isAuthenticated = false;
  isPartiLogged = false

  constructor(private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private sessionStorageService:SessionStorageService,
              private apiService:ApiUserService,
              private translate: TranslateService,
              private utils: UtilsService,
              private intercom:Intercom,
              private _router:Router,
    ) {
    translate.addLangs(['en', 'fr'])
    const  userLang = navigator.language.split("-")[0];

    let lang = this.sessionStorageService.get('language')
    if(lang){
      translate.setDefaultLang(lang)
    }else{

      if(userLang==="en" || userLang==="vi"){
        translate.setDefaultLang("en")
      }else{
        translate.setDefaultLang("fr")
      }
    }

  }
  

  ngOnInit() {
    const colors = new Map([
      ['primary', '#5E81F4'],
      ['primary_opacity','rgba(94, 129, 244, 0.1)'],
      ['primary_rgb', '94, 129, 244']
    ])

    Array.from(colors.entries()).forEach(([name, value]) => {
      document.body.style.setProperty(`--${name}`, value);
    })

    this.intercom.boot({
      app_id:"lwjs0ya2"
    });
    this.authService.authStatusChanged.subscribe(
      (authenticated) => {
        this.isAuthenticated = authenticated;
        if (authenticated === true) {
          if( ['unsubscribe', 'stripe-code', 'newsletter-unsubscribe'].includes(window.location.pathname)){ 
            //
          }else{
              
            const cognitoUser = this.authService.getAuthenticatedUser()
            if(cognitoUser){
              cognitoUser.getSession((err,session)=>{

                this.apiService.checkValidToken(session.getAccessToken().getJwtToken()).subscribe(res=>{
                  if(res.status==="ERROR"){
                    console.log('session invalid')
                    cognitoUser.signOut()
                    this.router.navigateByUrl('/');
                  }else{
                    const logged = this.sessionStorageService.get('logged')
                    if(logged!==undefined){
                      const language = this.sessionStorageService.get('language')
                      if(language){
                        this.translate.use(language)
                      } else if(logged.user.language){
                        this.sessionStorageService.set('language',logged.user.language)
                      }

                      const url = window.location.pathname.split('?')[0];

                      if(logged.is_logged_as_super_admin===1){
                        if(url === '/' || url === '/signin' || url === "/signin/signup"){
                          this.router.navigateByUrl('/super-admin/db-tool')
                        }
                      }else{

                        let organization_name = "";
                        if(logged.organization && logged.organization.name){
                          organization_name = logged.organization.name
                        }

                        this.intercom.boot({
                          app_id:"lwjs0ya2",
                          Organisation:`V3 : ${organization_name}`,
                          name:`${logged.user.first_name} ${logged.user.last_name}`,
                          email:logged.user.email
                        });

                        Sentry.setTag("organization", `CRM : ${organization_name}`);
                        Sentry.setTag("User name", `${logged.user.first_name} ${logged.user.last_name}`);
                        Sentry.setTag("Email", logged.user.email);


                        this.isPartiLogged = true
                        if(url === '/' || url === '/signin' || url === "/signin/signup"){
                          if(organization_name){
                            // if(logged.role_id === 3){
                            //   this.router.navigateByUrl('/user/dashboard')
                            // }else{
                            //Get the user's last location and redirect to that if url exists
                            const url = this.sessionStorageService.get(`persistent_${logged.user.id}_${logged.organization.id}_location`);
                            if(url)
                              this.router.navigateByUrl(url)
                            else
                              this.router.navigateByUrl('/admin/crm/dashboard')
                          
                              //this.router.navigateByUrl('/admin/crm/contacts/list')
                            // }
                          }else{
                            this.router.navigateByUrl('/signin/parties')
                          }
                            
                        }else{
            
                          if(logged && logged.organization && logged.organization.name!==undefined){
                            this.utils.applyOrganizationColor()
                          }else{
                            this.router.navigateByUrl('/signin/parties')
                          }
                        }
                      }
                    }else{
                      this.router.navigateByUrl('/signin/parties')
                    }
                    
                  }
                })

              })
            }else{
              this.router.navigateByUrl('/');
            }
          }

        } else {
          let url = window.location.pathname.split('?')[0];
          // console.log(url)
          if(url === '/' || url === "/signin/signup"|| url === "/document" || window.location.pathname.includes("unsubscribe")){
            //
          }else{
            this.router.navigateByUrl('/');
          }
        }
      }
    );
    this.authService.initAuth();

    Sentry.init({
      dsn: "https://4e1a17fb9b7d4b8ca9ca15a03307cbca@o1143197.ingest.sentry.io/6202479",
      release: "Predictive caller@1.0",
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });

    //Listen to router change, so that when a new page is selected, we save the user's location
      this.location.onUrlChange((url) => {
      // console.log(url)
      if(!url.includes('/admin')) return
      const logged = this.sessionStorageService.get('logged');
      // console.log(logged)
      if(logged.organization.id){
        this.sessionStorageService.set(`persistent_${logged.user.id}_${logged.organization.id}_location`, url);
        /* this.apiService.setLastUserLocation(url).subscribe((res) => {

        }) */
      }
      
    })

  // this._router.routeReuseStrategy.shouldReuseRoute = function(){
  //     return false;
  // };

  // this._router.events.subscribe((evt) => {
  //     if (evt instanceof NavigationEnd) {
  //         this._router.navigated = false;
  //         window.scrollTo(0, 0);
  //     }
  // });

  }

  onLogout() {
    this.authService.logout();
    /* this.authService.logout();
    this.sessionStorageService.clear() */
  }
}
