<div class="header">
    <div>
        <ic-icon color="#FFFFFF" width="22px" height="22px" [icon]="userIcon"></ic-icon>
        <span class="m-l-20">{{"Add Tech Support"|translate}}</span>
    </div>
    <div (click)="closeEditForm.emit()" class="exit-container">
        <ic-icon class="exit-icon" color="#FF808B" width="15px" height="15px" [icon]="heavyMultiplicationXIcon"></ic-icon>
    </div>
</div>
<div class="modal-user-body tech-modal fancy-scrollbar">
  <div class="tech-modal-body-wrapper">
    <div *ngIf="currentTechSupportMOdalStep == '1'" class="tech-step-1">
      <div class="illust-tech-support">
        <img src="/assets/img/tech-support-illus.svg" alt="" srcset="">
      </div>
      <div class="support-invitation-msg">
        {{agentData.user.last_name}} {{'is requesting for support previlages'|translate}}.
      </div>
      <div class="tech-persopn-box">
        <div class="tech-p-logo">{{agentData.user.first_name[0]}}{{agentData.user.first_name[1].toUpperCase()}}</div>
        <div class="tech-p-detauils">
          <div class="t-p-name">{{agentData.user.first_name}} {{agentData.user.last_name}}</div>
          <div class="t-p-position">{{agentData.user.email}}</div>
        </div>
      </div>
      <div class="expiry-box">
        <div class="expiry-label">{{'Expiry' | translate}}</div>
        <div class="expiry-options">
          <div class="expiry-option" (click)="expiry_option='day'" [class.selected]="expiry_option=='day'">1 {{'Day'|translate}}</div>
          <div class="expiry-option"  (click)="expiry_option='week'" [class.selected]="expiry_option=='week'">1 {{'Week'|translate}}</div>
          <div class="expiry-option"  (click)="expiry_option='month'" [class.selected]="expiry_option=='month'">1 {{'Month'|translate}}</div>
          <div class="expiry-option"  (click)="expiry_option='forever'" [class.selected]="expiry_option=='forever'" >{{'Forever'|translate}}</div>
          <div class="expiry-option"  (click)="expiry_option='custom'" [class.selected]="expiry_option=='custom'" >
            <div 
            id="date-input" 
            style="cursor: pointer" 
            class="due-date-container d-flex m-auto"
            [popoverClass]="'due-date-popover-' + -1" 
            [ngbPopover]="datePopover" 
            #p="ngbPopover" 
            [autoClose]="'outside'" 
            placement="bottom top left right"
            [container]="'body'" >
                <div class="m-auto fs-16 font-weight-bold" >
                    <span *ngIf="expired_date" class="text-gray fs-14"> {{ (expired_date | date: 'dd LLL yyyy HH:mm' | translate) }} </span>
                    <span *ngIf="!expired_date" > {{"Select date"|translate}} <i  style="font-size: 16px; margin-top: 2px;" class="text-gray fas fa-calendar-week"></i> </span> 
                </div>  
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div *ngIf="currentTechSupportMOdalStep == '2'" class="tech-step-2">
      <div class="legal-content">
        <div class="title">Last updated: 12 December 2021</div>
        <div class="para">Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</div>


        <div class="title">Consent</div>
        <div class="para">As a user, you will be asked to provide consent for the collection and access to your data and accounts by our technical support agents at Democratik. This consent is essential to allow our agents to assist you effectively with your needs.</div>
        <div class="para">We want to emphasize that your consent is based on the key principles of the Quebec Private Sector Privacy Act (LPRPSP). You will be fully informed about the nature and purpose of the data collection, as well as the individuals authorized to access it.</div>
        <div class="para">By clicking the "I agree" button, you freely and specifically consent to our technical support agents being able to connect to your accounts and access your data. This will enable them to use this information solely for the purpose of assisting you and resolving any technical issues you may encounter.</div>
        <div class="para">We want to highlight that you have the right to withdraw your consent at any time. If you choose to do so, please inform us so that we can take appropriate measures to cease accessing your data and accounts. Please note that withdrawing consent may impact our ability to provide you with comprehensive technical support.</div>
        <div class="para">At Democratik, we take the security of your data seriously. We have implemented rigorous security measures to protect your personal information against unauthorized access, disclosure, or misuse.</div>
        <div class="para">We retain your personal data only for the period necessary to achieve the purpose for which it was collected, unless the law requires a longer retention period.</div>
        <div class="para">If you have any questions or concerns regarding our data collection and access practices, please don't hesitate to contact us. We will be happy to address your inquiries and provide you with additional information about our privacy and data protection practices.</div>
        <div class="para">By clicking "I agree," you acknowledge that you have read, understood, and agreed to the terms of our consent for accessing your data</div>
        <div class="title">Contact Us</div>
        <div class="para">If you have any questions about these Terms, please contact us</div>
      </div>
      <div class="tech-persopn-box">
        <div class="tech-p-logo">{{agentData.user.first_name[0]}}{{agentData.user.first_name[1].toUpperCase()}}</div>
        <div class="tech-p-detauils">
          <div class="t-p-name">{{agentData.user.first_name}} {{agentData.user.last_name}}</div>
          <div class="t-p-position">{{agentData.user.email}}</div>
        </div>
      </div>
      
      <div class="accept-legal-tertms-cond">
        <div class="main-c-checkbox">
          <mat-checkbox [(ngModel)]="agree" class="example-margin">Accept legal terms conditions</mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-user-footer">
    <div>
       <button mat-button (click)="closeEditForm.emit()" class="cancel">
        {{"Refuse"|translate}}
       </button>
       <button mat-button [disabled]="currentTechSupportMOdalStep=='2' && !agree" class="confirm-us" (click)="handleTechSupportConfirm()"  >
         {{"Accept"|translate}}
       </button>
    </div>
</div>

<ng-template #datePopover let-task="task">
  <div [style.width]="'250px'">
      <app-datetime-picker [date]="expired_date" [inline]="true" (dateChanged)="expired_date =$event"></app-datetime-picker>
  </div>
</ng-template>