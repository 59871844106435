

<div class="page-header d-flex align-items-center position-relative justify-content-between">
    <div class="page-header-left inline w-25">
        <h3 class="page-title d-flex align-items-center">
            <div>
                <a (click)="toggleExtented()" class="btn btn-icon m-r-20"><i class="fa fa-list"></i></a>
            </div>
           
            <span *ngIf="sub_title">
                <a (click)="titleClick.emit($event)">{{title | translate}}</a>
                <i class="fa fa-angle-right m-r-10 m-l-10"></i>
                <span *ngIf="sub_title_2">
                    <a (click)="subTitleClick.emit($event)">{{sub_title | translate}}</a>
                    <i class="fa fa-angle-right m-r-10 m-l-10"></i>
                    <span>{{sub_title_2 | translate}}</span>
                </span>
                <span *ngIf="!sub_title_2">{{sub_title | translate}}</span>
            </span>
            <div
            placement="bottom-left" 
            [ngbPopover]="SelectBoard"
            triggers="manual" 
            #boardWarningPopover="ngbPopover" 
            [popoverClass]="'board-warning-popover'"
            style="flex: 1"
            class="d-flex"
            *ngIf="isTitleChoicelist"
            >
                <ng-select 
                [ngClass]="{'empty-border': choicelistOptions.ngModel == null}"
                style="flex: 1; padding: 5px;"
                #selectRef
                [items]="choicelistOptions.items"
                [ngModel]="choicelistOptions.ngModel"
                [placeholder]="choicelistOptions.placeholder"
                [searchable]="true"
                groupBy="bookmarked"
                bindLabel="name"
                [appendTo]="'body'"
                [clearable]="false"
                [ngStyle]="{'min-width': '290px'}"
                bindValue="id"
                class="hidden-choicelist board-select form-select"
                (ngModelChange)="onChoicelistChanged($event)"
                (close)="selectRef.closeOnSelect = true"
                >
                <ng-template ng-header-tmp>
                    <div *ngIf="showAddPipeline">
                        <div (click)="titleChanged.emit(-1); selectRef.close()" class="d-flex add-new-btn align-items-center">
                            <span class="select-option add-new-option">{{ 'Create a Table' | translate }}</span> 
                            <ic-icon class="icon-style m-l-5" style="margin-bottom: 3px;" width="20px" height="30px" [color]="bgColor" [icon]="plusIcon"></ic-icon> 
                        </div>  
                        <div class="d-flex copy-t-btn align-items-center">
                            <span class="select-option add-new-option">{{ 'Copy a Table' | translate }}</span> 
                            <span class="copy-t-icon">
                                <span class="iconify" data-icon="material-symbols:content-copy"></span>
                            </span>
                        </div>  
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="select-option option-tmp-item">
                        <span>{{item.name}}</span>
                        <div class="other-icon-list">
                            <span *ngIf="!item.bookmarked" class="grade-icon" (click)="updateChoiceList($event,item,true,selectRef)">
                                <span class="iconify" data-icon="material-symbols:grade-outline"></span>
                            </span>
                            <span *ngIf="item.bookmarked" class="grade-icon yellow" (click)="updateChoiceList($event,item,false,selectRef)">
                                <span class="iconify" data-icon="material-symbols:grade"></span>
                            </span>
                            <span 
                                (click)="clickEventOnSubmenu(formPopVariable,item,$event, selectRef);"
                                class="more-vert-icon" 
                                popoverClass="form-builder-variable-popover"
                                [ngbPopover]="submenuBox"
                                [container]="'body'"
                                triggers="manual"
                                [autoClose]="false"
                                #formPopVariable="ngbPopover"
                                [placement]="'right'">
                                <span class="iconify" data-icon="material-symbols:more-vert"></span>
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template ng-optgroup-tmp let-item="item">
                    <span *ngIf="item.bookmarked">{{'Bookmarked Ascending' | translate}}</span>
                    <span *ngIf="!item.bookmarked">{{'Full List Ascending' | translate}}</span>
                </ng-template>
                </ng-select>
            </div>
           
            <div 
            *ngIf="isTitleChoicelist"
            [autoClose]="'outside'" 
            container="body" 
            style="cursor: pointer;"
            tooltipClass="info-tooltip" 
            [ngbTooltip]="'Voici le nom du tableau que vous êtes en train de visualiser \n Appuyez sur le titre du tableau pour voir vos autres tableaux et en créer des nouveaux!' | translate"
            placement="auto"
            class="info-bubble m-l-5">
                <span class="m-auto">{{ 'i' }}</span>
            </div>
            <span *ngIf="!sub_title && !isTitleChoicelist">{{ title | translate }}</span>


            <button (click)="openUserDialog()" *ngIf="title == 'users' && userHeaderTitle!='Tech support' " mat-button class="invite-user">
                <ic-icon [icon]="plusIcon" color="#FFFFFF" [width]="'20px'" [height]="'24px'"></ic-icon>
                {{userHeaderTitle | translate}}
            </button>
        </h3>
    </div>
    
    <div *ngIf="showResult" class="results-container d-flex m-auto">
        <div class="d-flex results"> 
          <div class="section-1">
            <span style="font-size: 11.5px;" class="font-weight-bold">{{ (itemName  | translate) + ' ' + ('trouvés' | translate) + ': ' }} </span>
            <br>
            <span *ngIf="totalResults != null" style="font-size: 21px; position: relative; bottom: 5px;" class="font-weight-bold">{{  totalResults || 0 }}</span>
            <mat-spinner class="header-spinner" [diameter]="28" *ngIf="totalResults == null"></mat-spinner>
          </div>
          <div class="d-flex" *ngIf="( itemType == 'contact' || itemType == 'organization' ) && currentSelectedView=='list'">
            <div class="results-separator"></div>
            <div class="section-2">
              <span style="font-size: 11.5px;" class="font-weight-bold">{{ ('Téléphones uniques' | translate) + ': ' }} </span>
              <br>
              <div class="d-flex align-items-center">
                <ic-icon style="position:relative; bottom: 3px;" placement="bottom" container="body" ngbTooltip="{{ 'Count might be inexact, please refresh to get the exact count' | translate }}" *ngIf="isUncertainPhoneCount" [icon]="warningIcon" [color]="bgColor" [width]="24" [height]="24"></ic-icon>
                <span *ngIf="uniqPhoneCount != null" style="font-size: 21px; position: relative; bottom: 5px;" class="font-weight-bold">{{  uniqPhoneCount  || 0 }}</span>
                <mat-spinner class="header-spinner" [diameter]="28" *ngIf="uniqPhoneCount == null"></mat-spinner>
              </div>
             
            </div>
            <div class="results-separator"></div>
            <div class="section-3">
              <span style="font-size: 11.5px;" class="font-weight-bold">{{ ('Adresses uniques' | translate) + ': ' }} </span>
              <br>
              <div class="d-flex align-items-center">
                <ic-icon style="position:relative; bottom: 3px;" placement="bottom" container="body" ngbTooltip="{{ 'Count might be inexact, please refresh to get the exact count' | translate }}" *ngIf="isUncertainAddressCount" [icon]="warningIcon" [color]="bgColor" [width]="24" [height]="24"></ic-icon>
                <span *ngIf="uniqAddressCount != null" style="font-size: 21px; position: relative; bottom: 5px;" class="font-weight-bold">{{  uniqAddressCount  || 0 }}</span>
                <mat-spinner class="header-spinner" [diameter]="28" *ngIf="uniqAddressCount == null"></mat-spinner>
              </div>
              
            </div>
          </div>
          <div class="d-flex" *ngIf="( itemType == 'contact' || itemType == 'organization' ) && currentSelectedView=='map'">
            <div class="results-separator"></div>
            <div class="section-2">
              <span style="font-size: 11.5px;" class="font-weight-bold">{{ ('With geodata' | translate) + ': ' }} </span>
              <br>
              <div class="d-flex align-items-center">
                <span *ngIf="geoDataCount != null" style="font-size: 21px; position: relative; bottom: 5px;" class="font-weight-bold">{{  geoDataCount  || 0 }}</span>
                <mat-spinner class="header-spinner" [diameter]="28" *ngIf="geoDataCount == null"></mat-spinner>
              </div>
            </div>
          </div>
         
        </div>
    </div>



    <!-- <div class="page-header-right inline">
        <div *ngIf="showGlobalSearch===true && showSearchForm===false" class="quick-search">
            <div class="search-input">
                <div style="display: flex;align-items: center;">
                    <select class="form-control m-r-10" style="width:30%" #searchOp>
                        <option value="like">Contains</option>
                        <option value="=">Equals</option>
                        <option value="!=">Not equals</option>
                        <option value=">">Bigger than</option>
                        <option value="<">Lesser than</option>
                    </select>
                    <input class="form-control inline" 
                    #searchBox type="text"
                    (keyup.enter)="searchEnter.emit(searchBox.value)"
                    (keyup)="searchKeyup.emit({'query': searchBox.value, 'op':searchOp.value})">
                    <a class="fa fa-angle-down m-r-5 search-input-action" (click)="showFilterForm()"></a>
                    <a class="fas fa-times search-input-action" (click)="closeGlobalSearch()"></a>
                </div>
                <div *ngFor="let result of searchResults">
                    <div class="result" (click)="resultClick(result)">
                        <span>{{result.name}}</span>
                        <span>{{result.results}}</span>
                    </div>
                </div>
            </div>
        </div>
    
        
    </div> -->
    <ng-container *ngIf="title == 'users'" >

        <div class="page-center d-flex">
            <div class=" align-items-center page-nav-item" >
                <button mat-button [ngClass]="{'active-button' : userHeaderTitle == 'Create New Team'}" class="team" (click)="userHeaderTitle = 'Create New Team'; userTitleChange.emit('team')">
                <ic-icon [icon]="teamIcon" class="mr-1" [width]="'16px'" [height]="'16px'"></ic-icon>
                {{'TEAMS'|translate}}
                </button>
            </div>
            
            <div class="align-items-center page-nav-item" >
                <button mat-button class="users" [ngClass]="{'active-button' : userHeaderTitle == 'Create new role'}" (click)="userHeaderTitle = 'Create new role'; userTitleChange.emit('role')">
                <ic-icon [icon]="usersIcon" class="mr-1" [width]="'16px'" [height]="'16px'"></ic-icon>
                {{'ROLES' | translate}}
                </button>
            </div>
            
            <div class="align-items-center page-nav-item" >
                <button mat-button class="users" [ngClass]="{'active-button' : ['Invite User', 'Tech support'].includes(userHeaderTitle)}" (click)="userHeaderTitle = 'Invite User'; userTitleChange.emit('user')">
                <ic-icon [icon]="usersIcon" class="mr-1" [width]="'16px'" [height]="'16px'"></ic-icon>
                {{'USERS' | translate}}
                </button>
            </div>

        </div>
    </ng-container> 
    <div class="page-header-right inline d-flex align-items-center" style="display: inline-block">
        <div *ngIf="itemName == 'accountTransaction'" class="select-duration">
            <ng-select
            #dateRangeRef
            [clearable]="false"  
            [searchable]="false"
            [closeOnSelect]="true" 
            [(ngModel)]="selectedRange" 
            class="form-select date-range-select"
            bindLabel="name"
            bindValue="id"
            [items]="rangeOptions"
            [appendTo]="'body'"
            (change)="rangeOptionUpdate.emit($event)"
            style="font-size: 14px;line-height: 17px;font-weight: bold;color: #1C1D21;"
            >
            </ng-select>
        </div>
        <div class="d-flex align-items-center page-nav-item" *ngFor="let button of headerNavButtons; let i = index" [ngClass]="{'m-r-10': i == headerNavButtons.length - 1}">
            <span [ngClass]="{'selected-btn-wrapper': currentSelectedView == button.id}">
                <a (click)="button.callback(button.id)" [ngClass]="{'selected-btn': currentSelectedView == button.id}">
                    <ic-icon class="m-r-5" [icon]="button.icon" [color]="currentSelectedView == button.id ? bgColor : '#8181A5'" width="16" height="16"></ic-icon>
                    <span style="font-weight: 900" [ngClass]="{'text-primary': currentSelectedView == button.id}" class="fs-12 text-gray">{{ button.name | translate | uppercase }}</span>
                </a>
                <ng-container *ngIf="button.dropDownOption && logged.is_admin">
                    <button class="extra-button-more" [matMenuTriggerFor]="extraDropdownMenu">
                        <span class="iconify" data-icon="material-symbols:more-vert"></span>
                    </button>
                    <mat-menu #extraDropdownMenu="matMenu">
                    <button *ngFor="let bItem of button.dropDownOption" mat-menu-item (click)="bItem.callBack(button.id,bItem.name)">
                        <span class="meny-icon-wraper">
                            <span class="iconify" [attr.data-icon]="bItem.icon"></span>
                        </span>
                        {{bItem.name|translate}}
                    </button>
                    </mat-menu>
                </ng-container>
            </span>
            <div [hidden]="i == headerNavButtons.length - 1" class="vertical-separator"></div>
        </div>

       
        <ng-container>
            <a 
            #shortcutBtn 
            class="btn btn-icon m-l-5 menu" 
            (click)="openModal(shortcutModal)"
            [autoClose]="'outside'" 
            container="body" 
            style="cursor: pointer;"
            tooltipClass="info-tooltip" 
            [ngbTooltip]="'Raccourcis' | translate"
            placement="auto"
            >
                <ic-icon [icon]="shortcutIcon" width="22px" height="22px" color="#8181A5"></ic-icon>
            </a>
            <!-- Global search bar -->
            <div 
            [autoClose]="'outside'" 
            container="body" 
            style="cursor: pointer;"
            tooltipClass="info-tooltip" 
            [ngbTooltip]="'Recherche globale' | translate"
            placement="auto" 
            [disableTooltip]="showGlobalSearch"
            class="m-l-5 align-items-center search-container" 
            [ngClass]="{'expanded': showGlobalSearch}">
                <div class="btn-icon btn global-search-btn" [ngStyle]="{'cursor': showGlobalSearch ? 'initial' : 'pointer'}" (click)="openGlobalSearch(globalInput)" style="background: none;">
                    <a class="text-gray"><i class="fa fa-search"></i></a>
                </div>
                
                <input [(ngModel)]="searchValue" (keyup.Enter)="globalInput.value ? onGlobalSearchSubmit(globalInput.value, searchModal) : ''" class="global-search-input" #globalInput [ngClass]="showGlobalSearch ? 'input-shown' : 'input-hidden'" type="text" name="globalSearch" [placeholder]="'Type to search' | translate ">
            </div>
            <a 
            [hidden]="!showGlobalSearch" 
            (click)="showGlobalSearch = false" 
            class="btn btn-icon m-l-5"
            
            >
                <span class="iconify text-gray" data-icon="bx:first-page"></span>
            </a>
            
        </ng-container>
    </div>
        
    <!-- <div class="backdrop" *ngIf="showGlobalSearch===true"></div> -->
       
</div>

<ng-template #searchModal let-modal>
    <app-global-search (close)="modal.close()" (showDetails)="showDetails.emit($event)" [search]="searchValue"></app-global-search>
</ng-template>


<ng-template #shortcutModal let-modal>
    <app-shortcuts (reloadOptions)="reloadOptions.emit()" [mainService]="mainService" [availableShortCuts]="availableShortCuts" (close)="modal.close()"></app-shortcuts>
</ng-template>

<ng-template #SelectBoard>
    <div style="padding: 10px; text-align: center">
        <span class="iconify warning-icon" data-width="72px" data-height="72px" data-icon="mdi:warning-circle"></span>
        <h1 class="warning-message">{{ 'Please Select A Board From The Dropdown' | translate }}</h1>
    </div>
</ng-template>

<ng-template #submenuBox let-item="item">
    <div class="submenu-boxs-wrapper">
        <a class="submenu-item" (click)="clickBoardItem('link',item)" >
            <span class="submenu-icon">
                <span class="iconify" data-icon="material-symbols:link"></span>
            </span>
            <span class="sub-name">{{'Link' | translate}}</span>
        </a>
        <a class="submenu-item"  (click)="clickBoardItem('share',item)" >
            <span class="submenu-icon">
                <span class="iconify" data-icon="material-symbols:share"></span>
            </span>
            <span class="sub-name">{{'Share' | translate}}</span>
        </a>
        <a class="submenu-item"  (click)="clickBoardItem('rename',item)" >
            <span class="submenu-icon">
                <span class="iconify" data-icon="material-symbols:edit"></span>
            </span>
            <span class="sub-name">{{'Rename' | translate}}</span>
        </a>
        <a class="submenu-item"  (click)="clickBoardItem('config',item)" >
            <span class="submenu-icon">
                <span class="iconify" data-icon="material-symbols:page-info"></span>
            </span>
            <span class="sub-name">{{'Config' | translate}}</span>
        </a>
    </div>
</ng-template>


