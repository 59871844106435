import { NgModule } from "@angular/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({

  // imports:[
  //   MatDialogModule,
  //   MatSidenavModule,
  //   MatToolbarModule,
  //   MatAutocompleteModule,
  //   MatExpansionModule,
  //   MatChipsModule,
  //   MatBadgeModule,
  //   MatTableModule,
  //   MatTabsModule,
  //   MatIconModule,
  //   MatSortModule,
  //   MatDatepickerModule,
  //   MatFormFieldModule,
  //   MatNativeDateModule,
  //   MatInputModule,
  //   MatRadioModule,
  //   MatCheckboxModule,
  //   MatGridListModule,
  //   MatListModule,
  //   MatPaginatorModule,
  //   MatButtonModule,
  //   MatSnackBarModule,
  //   MatCardModule,
  //   MatSlideToggleModule,
  //   MatSelectModule,
  //   MatBottomSheetModule,
  //   DragDropModule,
  //   ScrollingModule,
  //   MatMenuModule,
  //   MatStepperModule,
  //   MatProgressBarModule,
  //   MatProgressSpinnerModule,
  //   MatTooltipModule,
  //   NgSelectModule
  // ],
  exports: [
    // MatSidenavModule,
    // MatToolbarModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatExpansionModule,
    // MatChipsModule,
    // MatBadgeModule,
    MatTableModule,
    MatTabsModule,
    // MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    // MatNativeDateModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    // MatGridListModule,
    // MatListModule,
    // MatCardModule,
    MatButtonModule,
    // MatSnackBarModule,
    MatSlideToggleModule,
    MatSelectModule,
    // MatBottomSheetModule,
    // DragDropModule,
    // ScrollingModule,
    MatMenuModule,
    // MatStepperModule,
    // MatProgressBarModule,
    // MatProgressSpinnerModule,
    MatTooltipModule,
    MatMomentDateModule
    // NgSelectModule
  ],
})
export class MaterialModule {}
