import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.css']
})
export class SkeletonLoaderComponent {

  @Input() Cwidth;
  @Input() Cheight;
  @Input() circle: boolean;
  @Input() center: boolean;
  @Input() margin = '';

  getMyStyles(): any {
    const myStyles = {
        'width.px': this.Cwidth ? this.Cwidth : '',
        'height.px': this.Cheight ? this.Cheight : '',
        'border-radius': this.circle ? '50%' : '',
        'margin': this.center ? 'auto' : this.margin
    };
    return myStyles;
}

}
