<app-home-header></app-home-header>
<ng-container *ngIf="validNewsletter==true" >
  <div *ngIf="!isConfirm" class="token-unsubscribe-wrapper">
    <div class="container token-unsubscribe-inner-wrapper">
      <div class="left-desc">
        <div class="main-title">
          <span>{{record.first_name}},</span>
          <br />
          <span>{{'Curate your Newsletters'|translate}}</span>
        </div>
        <div class="page-short-desc">
          {{'Eget penatibus odio consectetur diam semper dignissim faucibus vulputate'|translate}}. {{'Suspendisse aliquam hendrerit donec imperdiet semper'|translate}}. {{'Tempor in cursus et gravida dictum nulla enim mi malesuada'|translate}}. {{'Viverra posuere quam cras et venenatis'|translate}}.
        </div>
        <div class="news-letter-lists">
          <div
            *ngFor="let item of newsletterLists"
            (click)="updateNewsLetterItem(item)"
            class="news-lettetr-item"
            [class.selected]="item.isSelected"
          >
            <div class="left-prior-desc">
              <div
                class="round-newsletter-identify"
                [ngStyle]="{ 'background-color': item.color }"
              ></div>
              <div class="newsletter-name">
                <div class="newsletter-category">{{ item.name }}</div>
                <div class="newsletter-desc">{{ item.description }}</div>
              </div>
            </div>
            <div class="right-selected-round"></div>
          </div>
        </div>
        <div class="change-news-preference-btn">
          <button (click)="updateSetting()">
              {{'Change News Preferences'|translate}}
          </button>
        </div>
        <div class="dont-send-me-link">
          <a >{{'Don’t send me Anything'|translate}}</a>
        </div>
      </div>
      <div class="right-illus">
          <img src="/assets/img/token-illus=illustration.png" alt="" srcset="">
      </div>
    </div>
  </div>

  <div *ngIf="isConfirm" class="unsubscription-confirmation">
    <div class="confimation-content-wrapper">
        <div class="container">
            <div class="confimation-content-inner-wrapper">
                <div class="page-main-title">
                    <span>{{record.first_name}},</span>
                    <br>
                    <span>{{'Are you Sure to Cancel all Subscriptions?'|translate}}</span>
                </div>
                <div class="page-sub-text">
                  {{'Eget penatibus odio consectetur diam semper dignissim faucibus vulputate'|translate}}. {{'Suspendisse aliquam hendrerit donec imperdiet semper'|translate}}. {{'Tempor in cursus et gravida dictum nulla enim mi malesuada'|translate}}. {{'Viverra posuere quam cras et venenatis'|translate}}.
                </div>
                <div class="action-button-list">
                    <button (click)="isConfirm=false" >{{'Curate my List'|translate}}</button>
                    <button (click)="cancelAll()" >{{'Cancel all Subscriptions'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="showWrongUrl===true" >
  <div class="token-unsubscribe-wrapper">
    <div class="container token-unsubscribe-inner-wrapper">
      <p>{{'The page is not exist'|translate}}</p>
    </div>
  </div>
</ng-container>
