import { ApiTicketService } from 'src/app/core/services/crm_api/api-tickets.service';
import { ApiTaskService } from 'src/app/core/services/crm_api/api-task.service';
import { Location } from '@angular/common';
import { UtilsService } from 'src/app/core/services/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import checkCircleSolid from '@iconify-icons/la/check-circle-solid'
import plusIcon from '@iconify-icons/la/plus-solid'
import chats from '@iconify-icons/jam/messages-f';
import moment from 'moment';
import { SessionStorageService } from 'src/app/core/services/session.storage.service';


@Component({
  selector: 'app-task-item',
  templateUrl: './task-item.component.html',
  styleUrls: ['./task-item.component.css']
})
export class TaskItemComponent implements OnInit {
  @Input() item:any = {}
  @Input() inputClass=""
  @Input() users=[]
  @Input() applyTopBorderRadius = false;
  @Input() applyBottomBorderRadius = false;
  isCompleted = false;
  chatsIcon = chats
  @Output() updateUnreadCount= new EventEmitter()

  constructor(
    private router:Router,
    private translate:TranslateService,
    private apiTaskService:ApiTaskService,
    private utils:UtilsService,
    private location:Location,
    private sessionStorage:SessionStorageService,
    private apiTicketService:ApiTicketService
  ) { }

  usersOptions=[]
  usersOptionsRead=[]
  isExpanded=false
  checkCircleIcon = checkCircleSolid
  plusIcon = plusIcon
  ticket:any={}
  
  ngOnInit(): void {
    // console.log(this.item)
    // console.log(this.users)
    this.users = this.sessionStorage.get('users');

    // console.log(`users`,this.users)

    if(this.item.parent_type === "ticket" && this.item.parent_id){
      // this.apiTicketService.get(this.item.parent_id).subscribe(res=>{
        this.ticket = this.item?.association ? this.item?.association:{}
        if(this.ticket?.allow_user?.length > 0){
          this.ticket.allow_user = this.ticket.allow_user.map(it=>{
            // console.log('it',it)
            const us = this.users.find(u=>u.id===it.id)
            return us
          })
        }
        // console.log(this.ticket)
      // })
    }

    // this.usersOptions=[{
    //   id: 0,
    //   name: this.translate.instant("Mark as read"),
    //   bgColor: 'rgba(255, 193, 7, 0.05)',
    //   color: '#ffc107',
    //   cb: this.markAsRead.bind(this)
    // },{
    //   id: 1,
    //   name: this.translate.instant("Delete"),
    //   bgColor: 'rgba(255, 128, 139, 0.05)',
    //   color: '#FF808B',
    //   cb: this.deleteNotification.bind(this)
    // }]

    // this.usersOptionsRead=[{
    //   id: 0,
    //   name: this.translate.instant("Mark as unread"),
    //   bgColor: 'rgba(255, 193, 7, 0.05)',
    //   color: '#ffc107',
    //   cb: this.markAsUnRead.bind(this)
    // },{
    //   id: 1,
    //   name: this.translate.instant("Delete"),
    //   bgColor: 'rgba(255, 128, 139, 0.05)',
    //   color: '#FF808B',
    //   cb: this.deleteNotification.bind(this)
    // }]
  }

  updateDueDate() {
    this.item.due_date = moment(this.item.due_date).format('YYYY-MM-DD hh:mm');
    this.apiTaskService.updateTask(this.item.id, {'due_date': this.item.due_date}).subscribe((res: any) => {
      if(res.status == "OK") {
        
      } else {
        console.log(res)
      }
    });
  }



  onOptionSelected(option, item){
    // console.log(option,item)
    // if(item.is_archived){
    //   this.usersOptions[1][option.value].cb(this.itemsList[option.index].id)
    // }else{
      if(item.status === 1){
        this.usersOptionsRead[option.value].cb(item)
      }else{
        this.usersOptions[option.value].cb(item)
      }
    // }
    
  }

  getDueDateClass(due_date){
    let cl=""
    if(due_date){
      const diff = moment().diff(due_date,'days')
      if(diff ===0 ){
        return {'today':true}
      }else{
        if(diff > 0){
          return {'after':true}
        }else{
          return {'before':true}
        }
      }
    }
    
    return {}
  }

  goToLink(item){
    const host = window.location.protocol + "//" + window.location.host;
    window.open(`${host}${item.action_link}`,'_self');
  }
  completeTask(){
    this.item.is_done = 1
    this.apiTaskService.updateTask(this.item.id,{
      is_done:1
    }).subscribe(res=>{
      if(res.status === "OK"){
        setTimeout(() => {
          this.item=null
        }, 5000);
      }else{
        this.utils.showNotify('danger',res.message)
      }
    })
  }
  updateTaskName(){
    this.apiTaskService.updateTask(this.item.id,{
      name:this.item.name
    }).subscribe(res=>{
      if(res.status === "OK"){
        const inputElem = document.getElementById('task-name-input');
        inputElem.blur();
        this.utils.showNotify('success',"OK")
      }else{
        this.utils.showNotify('danger',res.message)
      }
    })
  }

  toggleBrokeText() {
    const elem = document.getElementById('task-name-'+this.item.id);
    const inputElem = document.getElementById('task-name-input-'+this.item.id);
    elem.classList.toggle("d-none");
    inputElem.classList.toggle("d-none");
    inputElem.focus();
  }
  updateTaskAllowUser($event){

  }
  openTicket(){
    const host = window.location.protocol + "//" + window.location.host;
    if(this.item.parent_id){
      switch(this.item.parent_type){
        case 'ticket':
          window.open(`${host}/admin/crm/tickets/board/:${this.item.parent_id}`,'_self'); 
          break;
        case 'contact':
          window.open(`${host}/admin/crm/contacts/list/:${this.item.parent_id}?section=Task`,'_self'); 
          break;
        case 'organization':
          window.open(`${host}/admin/crm/organizations/list/:${this.item.parent_id}?section=Task`,'_self'); 
          break;
      }
    }else{
      window.open(`${host}/admin/crm/tasks/list/:${this.item.id}`,'_self');
    }
  }
}
