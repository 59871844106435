<div class="global-search">
    <!-- Modal header -->
    <div class="modal-header">
        <div class="w-100" style="display:flex;justify-content: space-between;align-items: center;">
            <div class="title">{{ ('Searching For: ' | translate) + '"' + search + '"' }}</div>
            <a class="close" (click)="close.emit()">X</a>
        </div>
    </div>
    
    <div class="outer-padding d-flex overflow-auto">
        <div class="modal-body fancy-scrollbar">

            <!-- Search term -->
            <div class="d-flex align-items-center input-container m-b-20">
                <span class="fs-16 text-gray m-r-5">{{ ('Search' | translate) + ':' }}</span>
                <input #globalSearchInput class="global-search-input position-relative" [(ngModel)]="search" #globalInput type="text" name="globalSearch" [placeholder]="'Type to search' | translate ">
            </div>
        
            <!-- No results found -->
            <div *ngIf="finishLoading && contactData.count == 0 && organizationData.count == 0 && ticketData.count == 0 && communicationData.count == 0">
                <div class="d-flex flex-column">
                    <div class="m-auto d-flex"> 
                        <img style="width: 500px;z-index: 2; margin-left: 89px;" src="../../../../assets/img/not-found.png">
                        <img style="width: 500px;margin-left: -410px;z-index: 1;" src="../../../../assets/img/question_mark.png">
                    </div>
                    
                    <span class="font-weight-bold text-primary m-auto m-t-20" style="font-size: 24px">{{ 'Nothing was found with that search term!' | translate }}</span>
                    <button (click)="close.emit()" class="btn btn-primary m-auto m-t-20" style="width: 115px;">{{ 'Go Back' | translate }}</button>
                </div>
                
                    
                
            </div>

            <!-- List of results -->
            <div class="content-container">
                <!-- Loading view -->
                <ng-container *ngIf="loadingProp.contact">
                    <ng-container *ngTemplateOutlet="loading"></ng-container>
                </ng-container>
                <div *ngIf="!loadingProp.contact && contactData.count > 0" class="contact-container m-b-10">
                    <div [ngClass]="{'collapsed': contactData.collapsed}" class="header-section d-flex align-items-center">
                        <div (click)="contactData.collapsed = !contactData.collapsed" class="d-flex" style="cursor: pointer">
                            <span>{{ 'Found' | translate }} <strong>{{ 'Contacts' | translate }}</strong></span>
                            <ic-icon [icon]="arrowDownIcon" width="24" height="24" color="#464A5F" class="section-arrow d-flex" [ngClass]="{'section-expanded': !contactData.collapsed}"></ic-icon>
                        </div>

                        <div class="records-found-count m-l-10">
                            <span class="text-primary" style="font-weight: 900; font-size: 17px;">{{ contactData.count }}</span> <span class="text-primary inline m-l-5 font-weight-bold">{{ 'Records found' | translate }}</span>
                        </div>
                    </div>
                    <div class="table-container" [ngClass]="{'hidden-table': contactData.collapsed}">
                        <table class="item-table table">
                            <tbody>
                                <tr *ngFor="let contact of contactData.data" (click)="onOpenResult(contact.id, 'contact')">
                                    <td>
                                        <a class="item-details d-flex position-relative">
                                            <div class="item-details-text">
                                                <div *ngIf="contact.picture_url">
                                                    <img class="profile-image" [src]="contact.picture_url">
                                                </div>
                                                <div *ngIf="!contact.picture_url">
                                                    <!-- When name is combination of first and last name -->
                                                    <span> {{ (contact.first_name ? contact.first_name[0] : '') + (contact.last_name ? contact.last_name[0] : '') | uppercase}} </span>
                                                </div>
                                            
                                            </div>
                                        </a>
                                    </td>
                                    <td>
                                        <div class="default-column-size">
                                            <span class="name-text text-ellipsis">
                                                {{ (contact.first_name || '') + ' ' + (contact.last_name || '') }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="default-column-size">
                                            <span class="text-dark fs-16 text-ellipsis"  type="text">
                                                {{ contact.email || '' }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="default-column-size">
                                            <span *ngIf="contact.phone" class="text-dark fs-16 text-ellipsis"  type="text">
                                                {{ contact['phone_str'] || '' }}
                                            </span>
                                            <span *ngIf="!contact.phone && contact.cellphone" class="text-dark fs-16 text-ellipsis"  type="text">
                                                {{ contact['cellphone_str'] || '' }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center" >
                                            <div *ngIf="contact.organizations && contact.organizations.length > 0" class="relation-item">
                                                <img *ngIf="contact.organizations[0].picture_url" [src]="contact.organizations[0].picture_url" class="profile-image" style="height: 28px; width: 28px; border-radius: 100%;">
                                                <div *ngIf="!contact.organizations[0].picture_url" class="picture-container-small" [style.background]="bgColor">
                                                    <div>{{(contact.organizations[0].name ? contact.organizations[0].name.charAt(0).toUpperCase() : '')}}</div>
                                                </div>
            
                                                <div class="m-l-10 font-weight-bold fs-16">{{ contact.organizations[0].name || '' }}</div>
                                                
                                                <div (click)="$event.stopPropagation(); openAssociation(contact.organizations[0].id, 'organization');" style="cursor: pointer;">
                                                    <span  class="iconify m-l-10 inline" data-icon="fa:external-link-square" style="color: #8181a5;" data-width="12" data-height="12"></span>
                                                </div>
                                            </div>
                                            <div class="extra-count" *ngIf="contact.organizations && contact.organizations.length > 1">
                                                <div class="m-auto"> {{ ('+' + (contact.organizations.length - 1)) }} </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="contact-options">
                                            <ic-icon (click)="onOpenResult(contact.id, 'contact', false)" style="cursor: pointer;" [icon]="phoneIcon" width="24" height="24" [color]="bgColor"></ic-icon>
                                            <div class="separator ml-auto mr-auto"></div>
                                            <ic-icon (click)="onOpenResult(contact.id, 'contact', false)" style="cursor: pointer;" [icon]="mailIcon" width="24" height="24" [color]="bgColor"></ic-icon>
                                            <div class="separator ml-auto mr-auto"></div>
                                            <ic-icon (click)="onOpenResult(contact.id, 'contact', false)" style="cursor: pointer;" [icon]="smsIcon" width="24" height="24" [color]="bgColor"></ic-icon>
                                            <div class="separator ml-auto mr-auto"></div>
                                            <ic-icon (click)="onOpenResult(contact.id, 'contact', false)" style="cursor: pointer;" [icon]="commentIcon" width="24" height="24" [color]="bgColor"></ic-icon>
                                        </div>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                        <button *ngIf="contactData.count > 5" (click)="showAllResults('contact')" class="see-all-btn d-flex ml-auto mr-auto">
                            <span class="m-auto font-weight-bold text-primary fs-18">{{ 'Show All' | translate }}</span>
                        </button>

                        <div *ngIf="(electorData && electorData.count > 0) || (ticketData && ticketData.count > 0) || (organizationData && organizationData.count > 0) || (communicationData && communicationData.count > 0)" class="vertical-separator"></div>
                    </div>
                </div>

                <ng-container *ngIf="loadingProp.organization">
                    <ng-container *ngTemplateOutlet="loading"></ng-container>
                </ng-container>
                <div *ngIf="!loadingProp.organization && organizationData.count > 0" class="contact-container m-b-10">
                    <div [ngClass]="{'collapsed': organizationData.collapsed}" class="header-section d-flex align-items-center">
                        <div (click)="organizationData.collapsed = !organizationData.collapsed" class="d-flex" style="cursor: pointer">
                            <span>{{ 'Found' | translate }} <strong>{{ 'Organizations' | translate }}</strong></span>
                            <ic-icon [icon]="arrowDownIcon" width="24" height="24" color="#464A5F" class="section-arrow d-flex" [ngClass]="{'section-expanded': !organizationData.collapsed}"></ic-icon>
                        </div>

                        <div class="records-found-count m-l-10">
                            <span class="text-primary" style="font-weight: 900; font-size: 17px;">{{ organizationData.count }}</span> <span class="text-primary inline m-l-5 font-weight-bold">{{ 'Records found' | translate }}</span>
                        </div>
                    </div>
                    <div class="table-container" [ngClass]="{'hidden-table': organizationData.collapsed}">
                        <table class="item-table table">
                            <tbody>
                                <tr *ngFor="let organization of organizationData.data" (click)="onOpenResult(organization.id, 'organization', false)">
                                    <td>
                                        <a class="item-details d-flex position-relative">
                                            <div class="item-details-text">
                                                <div *ngIf="organization.picture_url">
                                                    <img class="profile-image" [src]="organization.picture_url">
                                                </div>
                                                <div *ngIf="!organization.picture_url">
                                                    <!-- When name is combination of first and last name -->
                                                    <span> {{ (organization.name ? organization.name[0] : '') | uppercase}} </span>
                                                </div>
                                            
                                            </div>
                                        </a>
                                    </td>
                                    <td>
                                        <div class="default-column-size">
                                            <span class="name-text text-ellipsis">
                                                {{ (organization.name || '') }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="default-column-size">
                                            <span class="text-dark fs-16 text-ellipsis"  type="text">
                                                {{ organization.email || '' }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="default-column-size">
                                            <span *ngIf="organization.phone" class="text-dark fs-16 text-ellipsis"  type="text">
                                                {{ organization['phone_str'] || '' }}
                                            </span>
                                            <span *ngIf="!organization.phone && organization.cellphone" class="text-dark fs-16 text-ellipsis"  type="text">
                                                {{ organization['cellphone_str'] || '' }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center" >
                                            <div *ngIf="organization.contacts && organization.contacts.length > 0" class="relation-item">
                                                <img *ngIf="organization.contacts[0].picture_url" [src]="organization.contacts[0].picture_url" class="profile-image" style="height: 28px; width: 28px; border-radius: 100%;">
                                                <div *ngIf="!organization.contacts[0].picture_url" class="picture-container-small" [style.background]="bgColor">
                                                    <div>{{(organization.contacts[0].first_name ? organization.contacts[0].first_name.charAt(0).toUpperCase() : '') + (organization.contacts[0].last_name ? organization.contacts[0].last_name.charAt(0).toUpperCase() : '')}}</div>
                                                </div>
            
                                                <div class="m-l-10 font-weight-bold fs-16">{{ (organization.contacts[0].first_name || '') + ' ' + (organization.contacts[0].last_name || '') }}</div>
                                                
                                                <div (click)="$event.stopPropagation(); openAssociation(organization.contacts[0].id, 'contact');" style="cursor: pointer;">
                                                    <span  class="iconify m-l-10 inline" data-icon="fa:external-link-square" style="color: #8181a5;" data-width="12" data-height="12"></span>
                                                </div>
                                            </div>
                                            <div class="extra-count" *ngIf="organization.contacts && organization.contacts.length > 1">
                                                <div class="m-auto"> {{ ('+' + (organization.contacts.length - 1)) }} </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="contact-options">
                                            <ic-icon (click)="onOpenResult(organization.id, 'organization', false)" style="cursor: pointer;" [icon]="phoneIcon" width="24" height="24" [color]="bgColor"></ic-icon>
                                            <div class="separator ml-auto mr-auto"></div>
                                            <ic-icon (click)="onOpenResult(organization.id, 'organization', false)" style="cursor: pointer;" [icon]="mailIcon" width="24" height="24" [color]="bgColor"></ic-icon>
                                            <div class="separator ml-auto mr-auto"></div>
                                            <ic-icon (click)="onOpenResult(organization.id, 'organization', false)" style="cursor: pointer;" [icon]="smsIcon" width="24" height="24" [color]="bgColor"></ic-icon>
                                            <div class="separator ml-auto mr-auto"></div>
                                            <ic-icon (click)="onOpenResult(organization.id, 'organization', false)" style="cursor: pointer;" [icon]="commentIcon" width="24" height="24" [color]="bgColor"></ic-icon>
                                        </div>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                        <button *ngIf="organizationData.count > 5" (click)="showAllResults('organization')" class="see-all-btn d-flex ml-auto mr-auto">
                            <span class="m-auto font-weight-bold text-primary fs-18">{{ 'Show All' | translate }}</span>
                        </button>

                        <div *ngIf="(electorData && electorData.count > 0) || (ticketData && ticketData.count > 0) || (communicationData && communicationData.count > 0)" class="vertical-separator"></div>
                    </div>
                </div>
        

                <ng-container *ngIf="loadingProp.elector">
                    <ng-container *ngTemplateOutlet="loading"></ng-container>
                </ng-container>
                <div *ngIf="!loadingProp.elector && electorData.count > 0" class="contact-container m-b-10">
                    <div [ngClass]="{'collapsed': electorData.collapsed}" class="header-section d-flex align-items-center">
                        <div (click)="electorData.collapsed = !electorData.collapsed" class="d-flex" style="cursor: pointer">
                            <span>{{ 'Found' | translate }} <strong>{{ 'Electors' | translate }}</strong></span>
                            <ic-icon [icon]="arrowDownIcon" width="24" height="24" color="#464A5F" class="section-arrow d-flex" [ngClass]="{'section-expanded': !electorData.collapsed}"></ic-icon>
                        </div>

                        <div class="records-found-count m-l-10">
                            <span class="text-primary" style="font-weight: 900; font-size: 17px;">{{ electorData.count }}</span> <span class="text-primary inline m-l-5 font-weight-bold">{{ 'Records found' | translate }}</span>
                        </div>
                    </div>
                    <div class="table-container" [ngClass]="{'hidden-table': electorData.collapsed}">
                        <table class="item-table table">
                            <tbody>
                                <tr *ngFor="let elector of electorData.data" (click)="onOpenResult(elector.uniqid, 'elector', false)">
                                    <td>
                                        <a class="item-details d-flex position-relative">
                                            <div class="item-details-text">
                                                <div>
                                                    <!-- When name is combination of first and last name -->
                                                    <span> {{ (elector.first_name ? elector.first_name[0] : '') + (elector.last_name ? elector.last_name[0] : '') | uppercase}} </span>
                                                </div>
                                            
                                            </div>
                                        </a>
                                    </td>
                                    <td>
                                        <div class="default-column-size">
                                            <span class="name-text text-ellipsis">
                                                {{ (elector.first_name || '') + ' ' + (elector.last_name || '') }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="default-column-size">
                                            <span class="text-dark fs-16 text-ellipsis">
                                                {{ (elector.zone_name || '') }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="default-column-size">
                                            <span class="text-dark fs-16 text-ellipsis">
                                                {{ (elector.address || '') }}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                        <button *ngIf="electorData.count > 5" (click)="showAllResults('elector')" class="see-all-btn d-flex ml-auto mr-auto">
                            <span class="m-auto font-weight-bold text-primary fs-18">{{ 'Show All' | translate }}</span>
                        </button>

                        <div *ngIf="(ticketData && ticketData.count > 0) || (communicationData && communicationData.count > 0)" class="vertical-separator"></div>
                    </div>
                </div>


                <ng-container *ngIf="loadingProp.ticket">
                    <ng-container *ngTemplateOutlet="loading"></ng-container>
                </ng-container>
                <div *ngIf="!loadingProp.ticket && ticketData.count > 0" class="contact-container">
                    <div [ngClass]="{'collapsed': ticketData.collapsed}" class="header-section d-flex align-items-center">
                        <div (click)="ticketData.collapsed = !ticketData.collapsed" class="d-flex" style="cursor: pointer">
                            <span>{{ 'Found' | translate }} <strong>{{ 'Tickets' | translate }}</strong></span>
                            <ic-icon [icon]="arrowDownIcon" width="24" height="24" color="#464A5F" class="section-arrow d-flex" [ngClass]="{'section-expanded': !ticketData.collapsed}"></ic-icon>
                        </div>

                        <div class="records-found-count m-l-10">
                            <span class="text-primary" style="font-weight: 900; font-size: 17px;">{{ ticketData.count }}</span> <span class="text-primary inline m-l-5 font-weight-bold">{{ 'Records found' | translate }}</span>
                        </div>
                    </div>
                    <div class="table-container" [ngClass]="{'hidden-table': ticketData.collapsed}">
                        <table class="item-table table">
                            <tbody>
                                <tr *ngFor="let ticket of ticketData.data" (click)="onOpenResult(ticket.id, 'ticket', false)">
                                    <td>
                                        <div class="default-column-size">
                                            <span class="name-text text-ellipsis">
                                                {{ (ticket.name || '') }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="default-column-size">
                                            <span class="text-dark fs-16 text-ellipsis">
                                                {{ ticket.pipeline_name || '' }}
                                            </span>
                                        </div>
                                    </td>

                                    <td>
                                        <div class="default-column-size">
                                            <span class="text-dark fs-16 text-ellipsis">
                                                {{ ticket.stage_name || '' }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center" style="width: max-content">
                                            <div *ngFor="let user of ticket.allow_user; let i = index">
                                                <img [src]="user.picture_url" *ngIf="i < 5 && user.picture_url" class="profile-image" placement="top" container="body" ngbTooltip="{{user.first_name + ' ' + user.last_name}}" style="height: 36px; width: 36px; border-radius: 100%;margin-right: -10px">
                                                <div *ngIf="i < 5 && !user.picture_url" style="margin-right: -10px" class="picture-container" placement="top" container="body" ngbTooltip="{{user.first_name + ' ' + user.last_name}}" [style.background]="bgColor">
                                                    <div>{{(user.first_name ? user.first_name.charAt(0).toUpperCase() : '') + (user.last_name ? user.last_name.charAt(0).toUpperCase() : '')}}</div>
                                                </div>
                                                <div *ngIf="i == 5" style="margin-right: -10px" class="picture-container" [style.background]="'#F2F4F6'">
                                                    <div class="surplus-text">{{'+' + (ticket.allow_user.length - 5).toString() }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="contact-options">
                                            <ic-icon (click)="onOpenResult(ticket.id, 'ticket', false)" style="cursor: pointer;" [icon]="phoneIcon" width="24" height="24" [color]="bgColor"></ic-icon>
                                            <div class="separator ml-auto mr-auto"></div>
                                            <ic-icon (click)="onOpenResult(ticket.id, 'ticket', false)" style="cursor: pointer;" [icon]="mailIcon" width="24" height="24" [color]="bgColor"></ic-icon>
                                            <div class="separator ml-auto mr-auto"></div>
                                            <ic-icon (click)="onOpenResult(ticket.id, 'ticket', false)" style="cursor: pointer;" [icon]="smsIcon" width="24" height="24" [color]="bgColor"></ic-icon>
                                            <div class="separator ml-auto mr-auto"></div>
                                            <ic-icon (click)="onOpenResult(ticket.id, 'ticket', false)" style="cursor: pointer;" [icon]="commentIcon" width="24" height="24" [color]="bgColor"></ic-icon>
                                        </div>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>

                        <button *ngIf="ticketData.count > 5" (click)="showAllResults('ticket')" class="see-all-btn d-flex ml-auto mr-auto">
                            <span class="m-auto font-weight-bold text-primary fs-18">{{ 'Show All' | translate }}</span>
                        </button>
                        <div *ngIf="communicationData && communicationData.count > 0" class="vertical-separator"></div>
                    </div>
                </div>


                <ng-container *ngIf="loadingProp.communication">
                    <ng-container *ngTemplateOutlet="loading"></ng-container>
                </ng-container>
                <div *ngIf="!loadingProp.communication && communicationData.count > 0" class="contact-container">
                    <div [ngClass]="{'collapsed': communicationData.collapsed}" class="header-section d-flex align-items-center">
                        <div (click)="communicationData.collapsed = !communicationData.collapsed" class="d-flex" style="cursor: pointer">
                            <span>{{ 'Found' | translate }} <strong>{{ 'Communication' | translate }}</strong></span>
                            <ic-icon [icon]="arrowDownIcon" width="24" height="24" color="#464A5F" class="section-arrow d-flex" [ngClass]="{'section-expanded': !communicationData.collapsed}"></ic-icon>
                        </div>

                        <div class="records-found-count m-l-10">
                            <span class="text-primary" style="font-weight: 900; font-size: 17px;">{{ communicationData.count }}</span> <span class="text-primary inline m-l-5 font-weight-bold">{{ 'Records found' | translate }}</span>
                        </div>
                    </div>
                    <div class="table-container" [ngClass]="{'hidden-table': communicationData.collapsed}">
                        <table class="item-table table">
                            <tbody>
                                <tr *ngFor="let communication of communicationData.data; let rowIndex = index">
                                    <td>
                                        <div class="default-column-size">
                                            <span class="comm-message-type text-ellipsis" [ngClass]="{
                                                                'note-btn': communication.type == 'note',
                                                                'mail-btn': communication.type == 'email',
                                                            'sms-btn': communication.type == 'sms',
                                                            'chat-btn': communication.type == 'chat'}">
                                                
                                                <span *ngIf="communication.type == 'note'" class="message-type-icon">
                                                <span class="iconify" data-icon="material-symbols:description-rounded"></span>
                                                </span>
                                                <span *ngIf="communication.type == 'chat'" class="message-type-icon">
                                                    <span class="iconify" data-icon="ion:chatbubbles-sharp"></span>
                                                </span>
                                                <span *ngIf="communication.type == 'sms'" class="message-type-icon">
                                                    <span class="iconify" data-icon="eva:message-square-fill"></span>
                                                </span>
                                                <span *ngIf="communication.type == 'email'" class="message-type-icon">
                                                    <span class="iconify" data-icon="fluent:mail-20-filled"></span>
                                                </span>
                                                <span class="content-preview-text" [innerHTML]="'[' + (communication.type | titlecase) + ']: ' + (communication.text_content | titlecase | boldTextMatch:search) | safeHtml"> </span>
                                            </span>
                                        </div>
                                                
                                    </td>
                                    <td>
                                        <div class="d-flex flex-wrap" *ngIf="communication?.thread_associations"  style="gap: 10px; width: fit-content;">
                                            <div *ngFor="let association of communication?.thread_associations" class="d-flex align-items-center default-column-size" style="width: fit-content; min-width: 300px">
                                                <div *ngIf="association.type == 'ticket'" class="w-100">
                                                    <div class="m-t-5 bg-white">
                                                        <app-ticket-item [ticket]="association"></app-ticket-item>
                                                    </div>
                                                </div>
                                                
                                                <div *ngIf="association.type == 'contact' || association.type == 'organization'" class="w-100 bg-white">
                                                    <div class="contact-btn">
                                                        <div *ngIf="!association.picture_url" class="initials-container d-flex" [style.background]="bgColor">
                                                        <span class="m-auto inline text-white fs-16 font-weight-bold">{{ association.type == 'contact' ? (association.first_name ? association.first_name.charAt(0).toUpperCase() : '') + (association.last_name ? association.last_name.charAt(0).toUpperCase() : '') : (association.name ? association.name.charAt(0).toUpperCase() : '')}}</span>
                                                        </div>
                                                        <div *ngIf="association.picture_url">
                                                        <img style="width:32px;height:32px; border-radius: 100%;" class="profile-image" src="{{association.picture_url}}" alt="ORG IMAGE">
                                                        </div>
                                                        <div class="contact-info-container m-l-5">
                                                            <div class="m-l-5 m-r-5">
                                                                <span class="fs-14 font-weight-bold text-dark text-ellipsis">{{ association.type == 'contact' ? (association.first_name ? association.first_name : '') + ' ' + (association.last_name ? association.last_name : '') : (association.name ? association.name : '') }}</span>
                                                            </div>
                                                        </div>
                                                        <a class="ml-auto" (click)="$event.stopPropagation(); openAssociation(association.id, association.type)">
                                                            <span class="iconify inline text-gray" data-icon="fa:external-link-square" data-width="12" data-height="12"></span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    
                                </tr>
                                
                            </tbody>
                        </table>
                    
                    </div>
                </div>

                
            </div>
        </div> 
    </div>
</div>

<ng-template #loading>
    <div class="mb-3">
        <app-skeleton-loader [Cwidth]="300" [Cheight]="36"></app-skeleton-loader>
    </div>
    <div class="m-b-30">
    <app-skeleton-loader [center]="true" [Cwidth]="1350" [Cheight]="200"></app-skeleton-loader>
    </div>
</ng-template>