import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";
import {UtilsService} from "../utils.service";
import { Injectable } from '@angular/core';

@Injectable()
export class ApiTicketService {
    api_name = "crm/ticket";
    url = "3.96.35.194";
    constructor( 
      private httpClient: HttpClient,
      private utils:UtilsService
    ) {}
  
    get(id: number): Observable<any>{ 
      return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/${id}`, this.utils.getAuthHeader())
    }

    getOptions(ticket_pipeline_id: number): Observable<any>{
      return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/options/:${ticket_pipeline_id}`, this.utils.getAuthHeader())
    }

    updateOptions(ticket_pipeline_id: number, body: any):Observable<any>{
      return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/options/:${ticket_pipeline_id}`, body,this.utils.getAuthHeader())
    }

    stats(date_from: string, date_to: string,from_task=false):Observable<any>{
      return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/stats?date_from=${date_from}&date_to=${date_to}&from_task=${from_task}`, this.utils.getAuthHeader())
    }

    filter(filter_obj): Observable<any>{
      return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`, filter_obj,this.utils.getAuthHeader())
    }

    getPipelineList(): Observable<any>{
        return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/pipeline`, this.utils.getAuthHeader())
    }
    
    getStageCategories(): Observable<any>{
      return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/stage-categories`, this.utils.getAuthHeader())
    }
    getLabelList(): Observable<any>{
      return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/label`, this.utils.getAuthHeader())
    }
    updateLabel(id: number, data: any): Observable<any>{
      return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/label/:${id}`, data, this.utils.getAuthHeader())
    }
    addLabel(data: any): Observable<any>{
      return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/label`, data, this.utils.getAuthHeader())
    }
    deleteLabel(id: number): Observable<any>{
      return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/label/:${id}`, this.utils.getAuthHeader())
    }
    edit(id: number, data: any): Observable<any>{
      return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/:${id}`, data, this.utils.getAuthHeader())
    }
    updateStage(id: number, data: any): Observable<any>{
      return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/stage/:${id}`, data, this.utils.getAuthHeader())
    }
    updatePipeline(id: number, data: any): Observable<any>{
      return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/pipeline/:${id}`, data, this.utils.getAuthHeader())
    }
    moveTicket(data: any): Observable<any>{
      return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/move`, data, this.utils.getAuthHeader())
    }
    moveStage(data: any): Observable<any>{
      return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/stage/move`, data, this.utils.getAuthHeader())
    }
    getBoard(data: any): Observable<any>{
      return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/pipeline/filter`, data, this.utils.getAuthHeader())
    }
    addBoard(data: any): Observable<any>{
      return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/pipeline`, data, this.utils.getAuthHeader())
    }
    addStage(data: any): Observable<any>{
      return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/stage`, data, this.utils.getAuthHeader())
    }
    deleteStage(id: number): Observable<any>{
      return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/stage/:${id}`, this.utils.getAuthHeader())
    }
    archiveAllTicketsInStage(id: number): Observable<any>{
      return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/stage/archivestage/:${id}`, this.utils.getAuthHeader())
    }
    add(data: any): Observable<any>{
      return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}`, data, this.utils.getAuthHeader())
    }
    delete(id: number): Observable<any>{
      return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/:${id}`, this.utils.getAuthHeader())
    }
    getActivityLog(id: number, size: number, offset: number, sort: string): Observable<any>{
      return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/activity/:${id}/?size=${size}&offset=${offset}&sort=${sort}`, this.utils.getAuthHeader())
    }

    getPipelineMap(pipeline_id: number): Observable<any>{
      return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/pipeline/map/:${pipeline_id}`, this.utils.getAuthHeader())
    }

    searchByName(search, limit=50): Observable<any>{
      const filter_obj = {
        filter: [
          {
            key: 'name',
            value: search,
            op: 'like'
          }
        ],
        fields: [
          {field:'id'}, {field:'name'}, {field: 'labels'},{field:'attachments'}, {field:'allow_user'}, {field: 'due_date'}
        ],
        ticket_pipeline_id: 0,
        sort: 'id asc',
        start: 0,
        limit: limit
      }
      return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`, filter_obj, this.utils.getAuthHeader());
    }


    addReminder(ticket_id: number, data: any): Observable<any>{
      return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/reminder`, {...data, ticket_id}, this.utils.getAuthHeader())
    }


    updateReminder(id: number, data: any): Observable<any>{
      return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/reminder/${id}`, data, this.utils.getAuthHeader())
    }

    deleteReminder(id: number): Observable<any>{
      return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/reminder/${id}`, this.utils.getAuthHeader())
    }

    updateBookmarked(pipeline_id,bookmarked): Observable<any>{
      return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/bookmark`,{pipeline_id,bookmarked}, this.utils.getAuthHeader())
    }

    cloneTicket(id: number, data: any): Observable<any>{
      return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/clone/${id}`, data, this.utils.getAuthHeader())
    }


  }