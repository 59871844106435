import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiElectorsService {
  api_name = "crm/elector";

  constructor( 
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {}

  filter(filter = {}): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`, JSON.stringify(filter), this.utils.getAuthHeader());
  }

  getCounts(filter = {}): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/count`, JSON.stringify(filter), this.utils.getAuthHeader());
  }
  streets = ((query, limit=1000): Observable<any> => {
    return this.httpClient.get<any>(`${environment.api_url}/api/crm/elector/street/searchasyoutype/?search=${encodeURI(query)}&limit=${limit}`, this.utils.getAuthHeader());
  })

  zones(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/zonelist`, this.utils.getAuthHeader());
  }

  getOptions(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/crm/elector/options`, this.utils.getAuthHeader());
  }
  getFilterOptions(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/crm/elector/filter-options`, this.utils.getAuthHeader());
  }

  updateOptions(data): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/crm/elector/options`, data,this.utils.getAuthHeader());
  }

  getImport(): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/crm/elector/import`, null,this.utils.getAuthHeader());
  }

  fixAddress(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/crm/elector/fix-address`,this.utils.getAuthHeader());
  }

  getItem(id): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/crm/elector/${id}`, this.utils.getAuthHeader());
  }

  edit(id, data): Observable<any> {
    return this.httpClient.patch<any>(`${environment.api_url}/api/crm/elector/${id}`, data, this.utils.getAuthHeader());
  }

  getActivityLog(id: number, size: number, offset: number, sort: string): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/activity/${id}/?size=${size}&offset=${offset}&sort=${sort}`, this.utils.getAuthHeader())
  }

  saveExportElector(data): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/crm/elector/save-export`, data,this.utils.getAuthHeader());
  }
  sendExportRequest(id): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/crm/elector/send-export/${id}`,this.utils.getAuthHeader());
  }

  exportDownload(id,responseType): Observable<any>{
    const options = {headers: this.utils.getAuthHeader().headers, responseType: responseType}
    // return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/export`, data, options);
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/download-export/${id}`, options);
  }
}