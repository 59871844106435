<div class="r-menu-header">
    <div>
        <div class="icon">
            <i *ngIf="title.toLowerCase() == 'notifications'" class="fas fa-bell"></i>
            <span *ngIf="title.toLowerCase() == 'tasks'">
                <ic-icon [ngClass]="{'icon-disabled': false}" [color]="bgColor" width="25px" height="25px" style="cursor: pointer;" [icon]="checkCircleIcon"></ic-icon>
            </span>
        </div>
        <div class="title">
            {{title|translate}}
        </div>
    </div>
    <div *ngIf="title.toLowerCase() == 'notifications'">
        <button mat-button class="read-all" (click)="readAll()" >
            <i class="fas fa-check"></i>
            <span style="margin-left: 8px;">{{"Read All"|translate}}</span>
        </button>
    </div>
</div>