import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import * as CloudFront from 'aws-sdk/clients/cloudfront';
import {UtilsService} from "./utils.service";
import * as moment from 'moment';
import {SessionStorageService} from "./session.storage.service";
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AwsService {
  constructor(private utilsService:UtilsService,
            private http: HttpClient,
              private sessionStorageService:SessionStorageService) {
  }
  fileUpload(file,cb) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: 'AKIAUYAI2DKBOY553CFT',
        secretAccessKey: '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',
        region: 'ca-central-1',
      }
    );
    const logged = this.sessionStorageService.getLoggedUser()
    const fileNameArr = file.name.split('.')
    const ext = fileNameArr[fileNameArr.length-1]
    const filename = this.utilsService.getRandomString(10)+"."+ext
    // console.log(logged)
    const params = {
      Bucket: 'crmmoneluupload',
      Key: logged.organization.id+'/'+filename,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    bucket.upload(params, function (err, data) {
      if (err) {
        // console.log('EROOR: ',JSON.stringify( err));
        return false;
      }
      // console.log('File Uploaded.', data);
      cb(data)
      return true;
    });
  }
  uploadfileAWSS3(fileuploadurl, contenttype, file) { 
 
    const headers = new HttpHeaders({ 'Content-Type': contenttype });
    const req = new HttpRequest(
      'PUT',
      fileuploadurl,
      file,
      {
        headers: headers, 
      });
    return this.http.request(req);
  }

  
  async getFileUploadCrm() {
    return new Promise((resolve, reject) => {
      const bucket = new S3(
        {
          accessKeyId: 'AKIAUYAI2DKBNHNPFIEE',
          secretAccessKey: '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',
          region: 'ca-central-1',
        }
      );
      // console.log(logged)
      const params = {
        Bucket: 'crmupload',
        Key: '23/CSpCXfy0Ao.png',
        Expires: 60
      };

      var url = bucket.getSignedUrl('getObject', params);
      // console.log('The URL is', url);

      return resolve(url)
      
    })
    
  }

  async fileUploadCrm(file) {
    return new Promise((resolve, reject) => {
      const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: 'AKIAUYAI2DKBOY553CFT',
        secretAccessKey: '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',
        region: 'ca-central-1',
      }
    );
      const logged = this.sessionStorageService.getLoggedUser()
      const fileNameArr = file.name.split('.')
      const ext = fileNameArr[fileNameArr.length-1]
      const filename = this.utilsService.getRandomString(10)+"."+ext
      // console.log(logged)
      const params = {
        Bucket: 'crmmoneluupload',
        Key: logged.organization.id+'/'+filename,
        Body: file.is_json?JSON.stringify(file.body):file,
        // ACL: 'public-read',
        ContentType: contentType
      };
      bucket.upload(params, function (err, data) {
        if (err) {
          // console.log('EROOR: ',JSON.stringify( err));
          reject(err);
        }
        console.log('File Uploaded.', data);
        resolve(data)
      });
    })
   
  }

  getFile(key: string, cb){
    const bucket = new S3(
      {
        accessKeyId: 'AKIAUYAI2DKBOY553CFT',
        secretAccessKey: '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',
        region: 'ca-central-1',
      }
    );

    const params = {
      Bucket: 'crmmoneluupload',
      Key: key
    };

    bucket.getObject(params, cb)
  }


  uploadTranslateFile(language,data,cb) {
    const s3 = new S3({
        accessKeyId: 'AKIAUYAI2DKBM4MOXKHH',
        secretAccessKey: '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',
        region: 'ca-central-1',
    });

    const cloudfront = new CloudFront({
      accessKeyId: 'AKIAUYAI2DKBM4MOXKHH',
      secretAccessKey: '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',
        region: 'ca-central-1',
    });


  const file_name = `assets/i18n/${language}.json`

  s3.putObject({
      Bucket: 'crmmonelu',
      Key: file_name,
      Body: JSON.stringify(data),
      ACL: 'bucket-owner-full-control',
      ContentType: 'application/json'
  }, function(err, data) {
    if (err) {
      // console.log(err)
      cb('error',err)
    }else{
        const param= {
          DistributionId:'E1FW3LOT1DIPKT',
          InvalidationBatch:{
              CallerReference:moment().unix()+'',
              Paths:{
                  Quantity:1,
                  Items:[
                      `/${file_name}`
                  ]
              }
          }
      }
      cloudfront.createInvalidation(param, function(err, data) {
          if (err){
            // console.log(err)
            cb('error',err)
          }else{
            cb('success')
          }
        });
    }
    
  });


  }


  fileUploadCrop(file,cb) {

    const contentType = "image/png";

    const bucket = new S3(
      {
        accessKeyId: 'AKIAUYAI2DKBOY553CFT',
        secretAccessKey: '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',
        region: 'ca-central-1'
      }
    );

    const logged = this.sessionStorageService.getLoggedUser()

    const ext = "png"
    const filename = this.utilsService.getRandomString(10)+"."+ext

    const params = {
      Bucket: 'crmmoneluupload',
      Key: logged.organization.id+'/'+filename,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    bucket.upload(params, function (err, data) {
      if (err) {
        // console.log('EROOR: ',JSON.stringify( err));
        return false;
      }
      // console.log('File Uploaded.', data);
      cb(data)
      return true;
    });
  }
}
