<div [ngClass]="{'no-border': !border}" class="ticket-container">
    <div style="padding: 7px;" class="d-flex align-items-center" >
        <div style="width: 10%;" style="flex-basis: 23px; margin-right: 7px" class="d-flex flex-wrap align-items-center">
            <div [ngStyle]="{ 'background-color' : label.color }" class="label-circle" *ngFor="let label of ticket.labels"></div>                  
        </div>

        <div style="width: 42%; flex: 1;" class="fs-14 m-r-5 font-weight-bold text-dark text-ellipsis" ngbTooltip="{{ticket.name}}" >
            {{ ticket.pipeline_name }} | {{ ticket.name }}
        </div>

        <div class="d-flex">
            <div *ngIf="ticket.organizations" class="d-flex">
                <div *ngFor="let organization of ticket.organizations; let i = index" class="contact-item">
                     <div style="min-width: 22px;" class="m-r-5" *ngIf="(ticket.contacts && ticket.organizations.length > maxOrganizationCount && ticket.contacts.length >= maxContactCount && i < maxOrganizationCount) || (ticket.contacts && ticket.organizations.length > maxOrganizationCount && ticket.contacts.length < maxContactCount && i < maxContactCount + maxOrganizationCount - ticket.contacts.length) || (!ticket.contacts && i < maxContactCount + maxOrganizationCount) || ticket.organizations.length <= maxOrganizationCount">
                        <div 
                        [autoClose]="'outside'" 
                        container="body" 
                        style="cursor: pointer;"
                        tooltipClass="info-tooltip" 
                        [ngbTooltip]="(organization.name || '') | translate"
                        placement="auto"
                        *ngIf="!organization.picture_url" 
                        class="contact initials-container text-primary d-flex">
                            <span class="m-auto" style="text-align: center;font-weight: 900; font-size: 14px;" >{{ organization.name ? organization.name.charAt(0) : '' | uppercase }}</span>
                        </div>
                        <div 
                        [autoClose]="'outside'" 
                        container="body" 
                        style="cursor: pointer;"
                        tooltipClass="info-tooltip" 
                        [ngbTooltip]="(organization.name || '') | translate"
                        placement="auto"
                        style="margin-right: 0px;" 
                        *ngIf="organization.picture_url" 
                        class="contact picture-container d-flex">
                            <img class="profile-image m-auto" style="width: 22px; height: 22px;" src="{{ organization.picture_url }}" alt="USER IMAGE">
                        </div>
                    </div>
                    <div class="extra-container contact m-r-5 d-flex" *ngIf="((ticket.contacts && ticket.organizations.length > maxOrganizationCount && ticket.contacts.length >= maxContactCount) || (ticket.contacts && ticket.organizations.length > maxOrganizationCount && ticket.contacts.length < maxContactCount && ticket.contacts.length + ticket.organizations.length > maxOrganizationCount + maxContactCount) || (!ticket.contacts && ticket.organizations.length > maxOrganizationCount + maxContactCount)) && i == ticket.organizations.length - 1">
                        <span class="m-auto">{{ '+' + getContactSurplusCount(ticket.organizations, ticket.contacts) }}</span>
                    </div>
                </div>
            </div>
           
            <div *ngIf="ticket.organizations && ticket.organizations.length > 0 && ticket.contacts && ticket.contacts.length > 0" class="vertical-separator"></div>
                
            <div *ngIf="ticket.contacts" class="d-flex contacts">
                <div *ngFor="let contact of ticket.contacts; let i = index" class="contact-item">
                    <div class="m-r-5" style="min-width: 22px;" *ngIf="(ticket.organizations && ticket.contacts.length > maxContactCount && ticket.organizations.length >= maxOrganizationCount && i < maxContactCount) || (ticket.organizations && ticket.contacts.length > maxContactCount && ticket.organizations.length < maxOrganizationCount && i < maxOrganizationCount + maxContactCount - ticket.organizations.length) || (!ticket.organizations && i < maxOrganizationCount + maxContactCount) || ticket.contacts.length <= maxContactCount">
                            <div 
                            [autoClose]="'outside'" 
                            container="body" 
                            style="cursor: pointer;"
                            tooltipClass="info-tooltip" 
                            [ngbTooltip]="((contact.first_name || '') + ' ' + (contact.last_name || '')) | translate"
                            placement="auto"
                            *ngIf="!contact.picture_url" 
                            class="contact initials-container d-flex text-primary">
                            <span class="m-auto" style="text-align: center;font-weight: 900; font-size: 14px;" >{{ (contact.first_name ? contact.first_name.charAt(0) : (contact.last_name ? contact.last_name.charAt(0) : '')) | uppercase }}</span>
                        </div>
                        <div 
                        [autoClose]="'outside'" 
                        container="body" 
                        style="cursor: pointer;"
                        tooltipClass="info-tooltip" 
                        [ngbTooltip]="((contact.first_name || '') + ' ' + (contact.last_name || '')) | translate"
                        placement="auto"
                        style="margin-right: 0px;" 
                        *ngIf="contact.picture_url" 
                        class="contact picture-container d-flex">
                            <img  class="profile-image m-auto" style="width: 22px; height: 22px;" src="{{ contact.picture_url }}" alt="USER IMAGE">
                        </div>
                    </div>
                    <div class="extra-container contact d-flex" *ngIf="((ticket.organizations && ticket.contacts.length > maxContactCount && ticket.organizations.length >= maxOrganizationCount) || (ticket.organizations && ticket.contacts.length > maxContactCount && ticket.organizations.length < maxOrganizationCount && ticket.contacts.length + ticket.organizations.length > maxContactCount + maxOrganizationCount) || (!ticket.organizations && ticket.contacts.length > maxContactCount + maxOrganizationCount)) && i == ticket.contacts.length - 1">
                        <span class="m-auto">{{ '+' + getContactSurplusCount(ticket.contacts, ticket.organizations) }}</span>
                    </div>
                </div>
                    
            </div>
        </div>

        <div (click)="$event.stopPropagation(); openAssociation(ticket.id);" class="d-flex ml-auto" style="cursor: pointer;">
            <span class="iconify m-l-10 inline" data-icon="fa:external-link-square" [ngStyle]="{'color': ticket.user_allowed ? '#8181a5' : '#8083A3'}" data-width="12" data-height="12"></span>
        </div>
    </div>  
    <div class="mt-auto">
        <ngb-progressbar class="w-100" height="4px"  [type]="ticket.task_length == ticket.completed_task_counter ? 'success' : 'warning'" value="{{ticket.task_length > 0 ? ticket.completed_task_counter / ticket.task_length * 100: 0}}"></ngb-progressbar>
    </div>
</div>
