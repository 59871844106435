<a [class]="!isVertical ? 'btn btn-icon dropdown-btn' : 'large-size dropdown-btn'"
[popoverClass]="size == 'small' ? 'popover-container-details-small big-drx' : 'popover-container-details-reg big-drx'"
[ngbPopover]="popover"
[placement]="placement"
(click)="$event.stopPropagation(); $event.preventDefault(); clicked.emit()"
(shown)="popoverOpen(p)"
(hidden)="popoverClose(p)"
[container]="container == 'body' ? container : null"
[autoClose]="autoClose ? 'outside' : true"
#p="ngbPopover"
>
    <i *ngIf="!isVertical && !fromCOntentBox" class="fa fa-ellipsis-h dropdown-btn"></i>
    <i *ngIf="isVertical && !fromCOntentBox" style="color: black;" class="fa fa-ellipsis-v dropdown-btn"></i>
    <img *ngIf="fromCOntentBox" src="/assets/img/code.svg" alt="" srcset="">
</a>

<ng-template #popover>
    <div id="popover-ref" *ngIf="!size || size == 'regular'" [ngStyle]="{'width': width ? width : '280px'}" class="popover-container-dd">
        <div *ngIf="!isVertical" class="popover-title m-b-15">
            <span>{{ title | translate }}</span>
            <i *ngIf="!fromCOntentBox" (click)="p.close()" class="fas fa-times x-btn float-right" style="color: grey;"></i>
        </div>
        <div (click)="$event.stopPropagation();optionSelected(i)" [ngClass]="{'confirm' : option.name == 'Delete' || option.name == 'delete'}" class="option m-b-10" [ngStyle]="{'background-color': option.bgColor ? option.bgColor : '#FAFAFA' }" *ngFor="let option of options; let i = index">
            <ic-icon class="m-r-5" *ngIf="option.icon" [icon]="option.icon" [ngStyle]="{'color': option.color ? option.color : 'rgba(70, 74, 95, 1)' }"></ic-icon>
            <span class="option-text" [ngStyle]="{'color': option.color ? option.color : 'rgba(70, 74, 95, 1)' }" >{{ option.name | translate }}</span>
        </div>
    </div>
    <div id="popover-ref" *ngIf="size == 'small'" [ngStyle]="{'width': width ? width : '130px'}" class="small-dd-container">
        <div  [ngClass]="{'confirm' : option.name == 'Delete' || option.name == 'delete'}" (click)="$event.stopPropagation();optionSelected(i)" class="small-option" [ngClass]="i != options.length - 1 ? 'm-b-10' : ''" [ngStyle]="{'background-color': option.bgColor ? option.bgColor : '#FAFAFA' }" *ngFor="let option of options; let i = index">
            <ic-icon class="m-r-5" *ngIf="option.icon" [icon]="option.icon" [ngStyle]="{'color': option.color ? option.color : 'rgba(70, 74, 95, 1)' }"></ic-icon>
            <span class="option-text" [ngStyle]="{'color': option.color ? option.color : 'rgba(70, 74, 95, 1)' }" >{{ option.name | translate }}</span>
        </div>
    </div>
</ng-template>

